import { FanApiResponse } from '@fca-app/api/fca/users/interfaces/response/fan-api.response';
import { FanModelData } from '@fca-app/models/users/fan/fan.model';
import { UserMapper } from '@fca-app/models/users/user/user.mapper';

export class FanMapper {
    mapData(data: FanApiResponse): FanModelData {
        return {
            ...new UserMapper().mapData(data),
            fullname: data.fullname,
            nickname: data.nickname,
            firstName: data.firstName,
            lastName: data.lastName,
            created: new Date(data.createDate),
            lastVisit: new Date(data.lastActivity),
        };
    }
}
