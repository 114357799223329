<div style="padding-left: 10px; padding-right: 10px; background-color: #e6f7ff00;">
    <div style="display: flex; padding-top: 10px;">
        <h4>Analytics</h4>
        <div class="tag-table">Data about views, users, comments and likes</div>
    </div>
    <nz-row [nzGutter]="12">
        <nz-col
            [nzXs]="{ span: 12, offset: 0 }"
            [nzSm]="{ span: 12, offset: 0 }"
            [nzMd]="{ span: 12, offset: 0 }"
            [nzLg]="{ span: 4, offset: 0 }"
            [nzXl]="{ span: 4, offset: 0 }"
        >
            <nz-card>
                <nz-statistic [nzValue]="(data$ | async)?.videos?.total" [nzTitle]="'Total videos'"></nz-statistic>
            </nz-card>
        </nz-col>
        <nz-col
            [nzXs]="{ span: 12, offset: 0 }"
            [nzSm]="{ span: 12, offset: 0 }"
            [nzMd]="{ span: 12, offset: 0 }"
            [nzLg]="{ span: 4, offset: 0 }"
            [nzXl]="{ span: 4, offset: 0 }"
        >
            <nz-card>
                <nz-statistic [nzValue]="(data$ | async)?.views?.total" [nzTitle]="'Views'"></nz-statistic>
            </nz-card>
        </nz-col>
        <nz-col
            [nzXs]="{ span: 12, offset: 0 }"
            [nzSm]="{ span: 12, offset: 0 }"
            [nzMd]="{ span: 12, offset: 0 }"
            [nzLg]="{ span: 4, offset: 0 }"
            [nzXl]="{ span: 4, offset: 0 }"
        >
            <nz-card>
                <nz-statistic [nzValue]="(data$ | async)?.unique?.total" [nzTitle]="'Unique users'"></nz-statistic>
            </nz-card>
        </nz-col>
        <nz-col
            [nzXs]="{ span: 12, offset: 0 }"
            [nzSm]="{ span: 12, offset: 0 }"
            [nzMd]="{ span: 12, offset: 0 }"
            [nzLg]="{ span: 4, offset: 0 }"
            [nzXl]="{ span: 4, offset: 0 }"
        >
            <nz-card>
                <nz-statistic [nzValue]="(data$ | async)?.sessions?.total" [nzTitle]="'Sessions'"></nz-statistic>
            </nz-card>
        </nz-col>
        <nz-col
            [nzXs]="{ span: 12, offset: 0 }"
            [nzSm]="{ span: 12, offset: 0 }"
            [nzMd]="{ span: 12, offset: 0 }"
            [nzLg]="{ span: 4, offset: 0 }"
            [nzXl]="{ span: 4, offset: 0 }"
        >
            <nz-card>
                <nz-statistic [nzValue]="(data$ | async)?.requests?.total" [nzTitle]="'Opened'"></nz-statistic>
            </nz-card>
        </nz-col>
        <!--    <nz-col-->
        <!--      [nzXs]="{ span: 12, offset: 0 }"-->
        <!--      [nzSm]="{ span: 12, offset: 0 }"-->
        <!--      [nzMd]="{ span: 12, offset: 0 }"-->
        <!--      [nzLg]="{ span: 4, offset: 0 }"-->
        <!--      [nzXl]="{ span: 4, offset: 0 }"-->
        <!--    >-->
        <!--      <nz-card>-->
        <!--        <nz-statistic-->
        <!--          [nzValue]="(data$ | async)?.likes?.video?.total"-->
        <!--          [nzTitle]="'Liked videos'"-->
        <!--        ></nz-statistic>-->
        <!--      </nz-card>-->
        <!--    </nz-col>-->
        <nz-col
            [nzXs]="{ span: 12, offset: 0 }"
            [nzSm]="{ span: 12, offset: 0 }"
            [nzMd]="{ span: 12, offset: 0 }"
            [nzLg]="{ span: 4, offset: 0 }"
            [nzXl]="{ span: 4, offset: 0 }"
        >
            <nz-card>
                <nz-statistic
                    [nzValue]="formatBytes((data$ | async)!.downloaded!.total)"
                    [nzTitle]="'Downloaded'"
                ></nz-statistic>
            </nz-card>
        </nz-col>
        <!--    <nz-col-->
        <!--      [nzXs]="{ span: 12, offset: 0 }"-->
        <!--      [nzSm]="{ span: 12, offset: 0 }"-->
        <!--      [nzMd]="{ span: 12, offset: 0 }"-->
        <!--      [nzLg]="{ span: 4, offset: 0 }"-->
        <!--      [nzXl]="{ span: 4, offset: 0 }"-->
        <!--    >-->
        <!--      <nz-card>-->
        <!--        <div class="ant-statistic-title">Total videos</div>-->
        <!--        <span class="ant-statistic-content-value-int ng-star-inserted stats-value">{{(data$ | async)?.users?.total}}</span>-->
        <!--      </nz-card>-->
        <!--    </nz-col>-->
    </nz-row>
    <div style="display: flex; padding-top: 10px;">
        <h4>Comments</h4>
        <div class="tag-table">Analytics about comments (week to week percents)</div>
    </div>
    <nz-row [nzGutter]="12">
        <nz-col
            [nzXs]="{ span: 12, offset: 0 }"
            [nzSm]="{ span: 12, offset: 0 }"
            [nzMd]="{ span: 12, offset: 0 }"
            [nzLg]="{ span: 8, offset: 0 }"
            [nzXl]="{ span: 8, offset: 0 }"
        >
            <nz-card>
                <div class="ant-statistic-title">Total comments</div>
                <span
                    *ngIf="
                        !(data$ | async)!.comments!.percent.includes('-') &&
                        !(data$ | async)!.comments!.percent.includes('0.00')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted up stats-value"
                >
                    <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">
                        <svg
                            class="chevron-icon"
                            viewBox="64 64 896 896"
                            focusable="false"
                            fill="currentColor"
                            width="1em"
                            height="1em"
                            data-icon="arrow-up"
                            aria-hidden="true"
                        >
                            <path
                                d="M868 545.5L536.1 163a31.96 31.96 0 00-48.3 0L156 545.5a7.97 7.97 0 006 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"
                            ></path>
                        </svg>
                    </span>
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)?.comments?.total
                    }}</span>
                </span>
                <span
                    *ngIf="(data$ | async)!.comments!.percent.includes('-')"
                    class="ant-statistic-content-prefix ng-star-inserted down stats-value"
                >
                    <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">
                        <svg
                            class="chevron-icon"
                            viewBox="64 64 896 896"
                            focusable="false"
                            fill="currentColor"
                            width="1em"
                            height="1em"
                            data-icon="arrow-down"
                            aria-hidden="true"
                        >
                            <path
                                d="M862 465.3h-81c-4.6 0-9 2-12.1 5.5L550 723.1V160c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v563.1L255.1 470.8c-3-3.5-7.4-5.5-12.1-5.5h-81c-6.8 0-10.5 8.1-6 13.2L487.9 861a31.96 31.96 0 0048.3 0L868 478.5c4.5-5.2.8-13.2-6-13.2z"
                            ></path>
                        </svg>
                    </span>
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)?.comments?.total
                    }}</span>
                </span>
                <span
                    *ngIf="
                        (data$ | async)!.comments!.percent.includes('0.00') &&
                        !(data$ | async)!.comments!.percent.includes('-')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted middle stats-value"
                >
                    <svg
                        class="chevron-icon"
                        viewBox="64 64 896 896"
                        focusable="false"
                        fill="currentColor"
                        width="1em"
                        height="1em"
                        data-icon="minus"
                        aria-hidden="true"
                    >
                        <path
                            d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"
                        ></path>
                    </svg>
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)?.comments?.total
                    }}</span>
                </span>
                <span class="ant-statistic-content-value-int ng-star-inserted stats-mini-value"
                    >{{ (data$ | async)?.comments?.percent }}%</span
                >
            </nz-card>
        </nz-col>
        <nz-col
            [nzXs]="{ span: 12, offset: 0 }"
            [nzSm]="{ span: 12, offset: 0 }"
            [nzMd]="{ span: 12, offset: 0 }"
            [nzLg]="{ span: 8, offset: 0 }"
            [nzXl]="{ span: 8, offset: 0 }"
        >
            <nz-card>
                <div class="ant-statistic-title">Videos with comments</div>
                <span
                    *ngIf="
                        !(data$ | async)!.comments!.videos!.percent.includes('-') &&
                        !(data$ | async)!.comments!.videos!.percent.includes('0.00')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted up stats-value"
                >
                    <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">
                        <svg
                            class="chevron-icon"
                            viewBox="64 64 896 896"
                            focusable="false"
                            fill="currentColor"
                            width="1em"
                            height="1em"
                            data-icon="arrow-up"
                            aria-hidden="true"
                        >
                            <path
                                d="M868 545.5L536.1 163a31.96 31.96 0 00-48.3 0L156 545.5a7.97 7.97 0 006 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"
                            ></path>
                        </svg>
                    </span>
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)?.comments?.videos?.total
                    }}</span>
                </span>
                <span
                    *ngIf="(data$ | async)!.comments!.videos!.percent.includes('-')"
                    class="ant-statistic-content-prefix ng-star-inserted down stats-value"
                >
                    <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">
                        <svg
                            class="chevron-icon"
                            viewBox="64 64 896 896"
                            focusable="false"
                            fill="currentColor"
                            width="1em"
                            height="1em"
                            data-icon="arrow-down"
                            aria-hidden="true"
                        >
                            <path
                                d="M862 465.3h-81c-4.6 0-9 2-12.1 5.5L550 723.1V160c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v563.1L255.1 470.8c-3-3.5-7.4-5.5-12.1-5.5h-81c-6.8 0-10.5 8.1-6 13.2L487.9 861a31.96 31.96 0 0048.3 0L868 478.5c4.5-5.2.8-13.2-6-13.2z"
                            ></path>
                        </svg>
                    </span>
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)?.comments?.videos?.total
                    }}</span>
                </span>
                <span
                    *ngIf="
                        (data$ | async)!.comments!.videos!.percent.includes('0.00') &&
                        !(data$ | async)!.comments!.videos!.percent.includes('-')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted middle stats-value"
                >
                    <svg
                        class="chevron-icon"
                        viewBox="64 64 896 896"
                        focusable="false"
                        fill="currentColor"
                        width="1em"
                        height="1em"
                        data-icon="minus"
                        aria-hidden="true"
                    >
                        <path
                            d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"
                        ></path>
                    </svg>
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)?.comments?.videos?.total
                    }}</span>
                </span>
                <span class="ant-statistic-content-value-int ng-star-inserted stats-mini-value"
                    >{{ (data$ | async)?.comments?.videos!.percent }}%</span
                >
            </nz-card>
        </nz-col>
        <nz-col
            [nzXs]="{ span: 12, offset: 0 }"
            [nzSm]="{ span: 12, offset: 0 }"
            [nzMd]="{ span: 12, offset: 0 }"
            [nzLg]="{ span: 8, offset: 0 }"
            [nzXl]="{ span: 8, offset: 0 }"
        >
            <nz-card>
                <div class="ant-statistic-title">Users who write comments</div>
                <span
                    *ngIf="
                        !(data$ | async)!.comments!.users!.percent.includes('-') &&
                        !(data$ | async)!.comments!.users!.percent.includes('0.00')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted up stats-value"
                >
                    <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">
                        <svg
                            class="chevron-icon"
                            viewBox="64 64 896 896"
                            focusable="false"
                            fill="currentColor"
                            width="1em"
                            height="1em"
                            data-icon="arrow-up"
                            aria-hidden="true"
                        >
                            <path
                                d="M868 545.5L536.1 163a31.96 31.96 0 00-48.3 0L156 545.5a7.97 7.97 0 006 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"
                            ></path>
                        </svg>
                    </span>
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)?.comments?.users?.total
                    }}</span>
                </span>
                <span
                    *ngIf="(data$ | async)!.comments!.users!.percent.includes('-')"
                    class="ant-statistic-content-prefix ng-star-inserted down stats-value"
                >
                    <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">
                        <svg
                            class="chevron-icon"
                            viewBox="64 64 896 896"
                            focusable="false"
                            fill="currentColor"
                            width="1em"
                            height="1em"
                            data-icon="arrow-down"
                            aria-hidden="true"
                        >
                            <path
                                d="M862 465.3h-81c-4.6 0-9 2-12.1 5.5L550 723.1V160c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v563.1L255.1 470.8c-3-3.5-7.4-5.5-12.1-5.5h-81c-6.8 0-10.5 8.1-6 13.2L487.9 861a31.96 31.96 0 0048.3 0L868 478.5c4.5-5.2.8-13.2-6-13.2z"
                            ></path>
                        </svg>
                    </span>
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)?.comments?.users?.total
                    }}</span>
                </span>
                <span
                    *ngIf="
                        (data$ | async)!.comments!.users!.percent.includes('0.00') &&
                        !(data$ | async)!.comments!.users!.percent.includes('-')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted middle stats-value"
                >
                    <svg
                        class="chevron-icon"
                        viewBox="64 64 896 896"
                        focusable="false"
                        fill="currentColor"
                        width="1em"
                        height="1em"
                        data-icon="minus"
                        aria-hidden="true"
                    >
                        <path
                            d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"
                        ></path>
                    </svg>
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)?.comments?.users?.total
                    }}</span>
                </span>
                <span class="ant-statistic-content-value-int ng-star-inserted stats-mini-value"
                    >{{ (data$ | async)?.comments?.users!.percent }}%</span
                >
            </nz-card>
        </nz-col>

        <nz-col
            [nzXs]="{ span: 12, offset: 0 }"
            [nzSm]="{ span: 12, offset: 0 }"
            [nzMd]="{ span: 12, offset: 0 }"
            [nzLg]="{ span: 4, offset: 0 }"
            [nzXl]="{ span: 4, offset: 0 }"
        >
            <nz-card>
                <div class="ant-statistic-title">Views to comment</div>
                <span
                    *ngIf="
                        !(data$ | async)!.comments!.commentsToViews!.percent.includes('-') &&
                        !(data$ | async)!.comments!.commentsToViews!.percent.includes('0.00')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted up stats-value"
                >
                    <!--          <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">-->
                    <!--            <svg class="chevron-icon" viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="arrow-up" aria-hidden="true"><path d="M868 545.5L536.1 163a31.96 31.96 0 00-48.3 0L156 545.5a7.97 7.97 0 006 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path></svg>-->
                    <!--          </span>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.comments!.commentsToViews!.value
                    }}</span>
                </span>
                <span
                    *ngIf="(data$ | async)!.comments!.commentsToViews!.percent.includes('-')"
                    class="ant-statistic-content-prefix ng-star-inserted down stats-value"
                >
                    <!--          <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">-->
                    <!--            <svg class="chevron-icon"  viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="arrow-down" aria-hidden="true"><path d="M862 465.3h-81c-4.6 0-9 2-12.1 5.5L550 723.1V160c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v563.1L255.1 470.8c-3-3.5-7.4-5.5-12.1-5.5h-81c-6.8 0-10.5 8.1-6 13.2L487.9 861a31.96 31.96 0 0048.3 0L868 478.5c4.5-5.2.8-13.2-6-13.2z"></path></svg>-->
                    <!--          </span>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.comments!.commentsToViews!.value
                    }}</span>
                </span>
                <span
                    *ngIf="
                        (data$ | async)!.comments!.commentsToViews!.percent.includes('0.00') &&
                        !(data$ | async)!.comments!.commentsToViews!.percent.includes('-')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted middle stats-value"
                >
                    <!--          <svg class="chevron-icon"  viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="minus" aria-hidden="true">-->
                    <!--            <path d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"></path>-->
                    <!--          </svg>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.comments!.commentsToViews!.value
                    }}</span>
                </span>
                <!--        <span class="ant-statistic-content-value-int ng-star-inserted stats-mini-value">{{(data$ | async)!.comments!.commentsToViews!.percent}}%</span>-->
            </nz-card>
        </nz-col>
        <nz-col
            [nzXs]="{ span: 12, offset: 0 }"
            [nzSm]="{ span: 12, offset: 0 }"
            [nzMd]="{ span: 12, offset: 0 }"
            [nzLg]="{ span: 4, offset: 0 }"
            [nzXl]="{ span: 4, offset: 0 }"
        >
            <nz-card>
                <div class="ant-statistic-title">Viewed users to comment</div>
                <span
                    *ngIf="
                        !(data$ | async)!.comments!.commentsToViewedUsers!.percent.includes('-') &&
                        !(data$ | async)!.comments!.commentsToViewedUsers!.percent.includes('0.00')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted up stats-value"
                >
                    <!--          <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">-->
                    <!--            <svg class="chevron-icon" viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="arrow-up" aria-hidden="true"><path d="M868 545.5L536.1 163a31.96 31.96 0 00-48.3 0L156 545.5a7.97 7.97 0 006 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path></svg>-->
                    <!--          </span>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.comments!.commentsToViewedUsers!.value
                    }}</span>
                </span>
                <span
                    *ngIf="(data$ | async)!.comments!.commentsToViewedUsers!.percent.includes('-')"
                    class="ant-statistic-content-prefix ng-star-inserted down stats-value"
                >
                    <!--          <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">-->
                    <!--            <svg class="chevron-icon"  viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="arrow-down" aria-hidden="true"><path d="M862 465.3h-81c-4.6 0-9 2-12.1 5.5L550 723.1V160c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v563.1L255.1 470.8c-3-3.5-7.4-5.5-12.1-5.5h-81c-6.8 0-10.5 8.1-6 13.2L487.9 861a31.96 31.96 0 0048.3 0L868 478.5c4.5-5.2.8-13.2-6-13.2z"></path></svg>-->
                    <!--          </span>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.comments!.commentsToViewedUsers!.value
                    }}</span>
                </span>
                <span
                    *ngIf="
                        (data$ | async)!.comments!.commentsToViewedUsers!.percent.includes('0.00') &&
                        !(data$ | async)!.comments!.commentsToViewedUsers!.percent.includes('-')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted middle stats-value"
                >
                    <!--          <svg class="chevron-icon"  viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="minus" aria-hidden="true">-->
                    <!--            <path d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"></path>-->
                    <!--          </svg>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.comments!.commentsToViewedUsers!.value
                    }}</span>
                </span>
                <!--        <span class="ant-statistic-content-value-int ng-star-inserted stats-mini-value">{{(data$ | async)!.comments!.commentsToViewedUsers!.percent}}%</span>-->
            </nz-card>
        </nz-col>
        <nz-col
            [nzXs]="{ span: 12, offset: 0 }"
            [nzSm]="{ span: 12, offset: 0 }"
            [nzMd]="{ span: 12, offset: 0 }"
            [nzLg]="{ span: 4, offset: 0 }"
            [nzXl]="{ span: 4, offset: 0 }"
        >
            <nz-card>
                <div class="ant-statistic-title">Videos to comments</div>
                <span
                    *ngIf="
                        !(data$ | async)!.comments!.commentsToVideos!.percent.includes('-') &&
                        !(data$ | async)!.comments!.commentsToVideos!.percent.includes('0.00')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted up stats-value"
                >
                    <!--          <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">-->
                    <!--            <svg class="chevron-icon" viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="arrow-up" aria-hidden="true"><path d="M868 545.5L536.1 163a31.96 31.96 0 00-48.3 0L156 545.5a7.97 7.97 0 006 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path></svg>-->
                    <!--          </span>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.comments!.commentsToVideos!.value
                    }}</span>
                </span>
                <span
                    *ngIf="(data$ | async)!.comments!.commentsToVideos!.percent.includes('-')"
                    class="ant-statistic-content-prefix ng-star-inserted down stats-value"
                >
                    <!--          <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">-->
                    <!--            <svg class="chevron-icon"  viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="arrow-down" aria-hidden="true"><path d="M862 465.3h-81c-4.6 0-9 2-12.1 5.5L550 723.1V160c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v563.1L255.1 470.8c-3-3.5-7.4-5.5-12.1-5.5h-81c-6.8 0-10.5 8.1-6 13.2L487.9 861a31.96 31.96 0 0048.3 0L868 478.5c4.5-5.2.8-13.2-6-13.2z"></path></svg>-->
                    <!--          </span>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.comments!.commentsToVideos!.value
                    }}</span>
                </span>
                <span
                    *ngIf="
                        (data$ | async)!.comments!.commentsToVideos!.percent.includes('0.00') &&
                        !(data$ | async)!.comments!.commentsToVideos!.percent.includes('-')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted middle stats-value"
                >
                    <!--          <svg class="chevron-icon"  viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="minus" aria-hidden="true">-->
                    <!--            <path d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"></path>-->
                    <!--          </svg>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.comments!.commentsToVideos!.value
                    }}</span>
                </span>
                <!--        <span class="ant-statistic-content-value-int ng-star-inserted stats-mini-value">{{(data$ | async)!.comments!.commentsToVideos!.percent}}%</span>-->
            </nz-card>
        </nz-col>
        <nz-col
            [nzXs]="{ span: 12, offset: 0 }"
            [nzSm]="{ span: 12, offset: 0 }"
            [nzMd]="{ span: 12, offset: 0 }"
            [nzLg]="{ span: 4, offset: 0 }"
            [nzXl]="{ span: 4, offset: 0 }"
        >
            <nz-card>
                <div class="ant-statistic-title">Likes to comment</div>
                <span
                    *ngIf="
                        !(data$ | async)!.comments!.commentsToLikes!.percent.includes('-') &&
                        !(data$ | async)!.comments!.commentsToLikes!.percent.includes('0.00')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted up stats-value"
                >
                    <!--          <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">-->
                    <!--            <svg class="chevron-icon" viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="arrow-up" aria-hidden="true"><path d="M868 545.5L536.1 163a31.96 31.96 0 00-48.3 0L156 545.5a7.97 7.97 0 006 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path></svg>-->
                    <!--          </span>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.comments!.commentsToLikes!.value
                    }}</span>
                </span>
                <span
                    *ngIf="(data$ | async)!.comments!.commentsToLikes!.percent.includes('-')"
                    class="ant-statistic-content-prefix ng-star-inserted down stats-value"
                >
                    <!--          <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">-->
                    <!--            <svg class="chevron-icon"  viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="arrow-down" aria-hidden="true"><path d="M862 465.3h-81c-4.6 0-9 2-12.1 5.5L550 723.1V160c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v563.1L255.1 470.8c-3-3.5-7.4-5.5-12.1-5.5h-81c-6.8 0-10.5 8.1-6 13.2L487.9 861a31.96 31.96 0 0048.3 0L868 478.5c4.5-5.2.8-13.2-6-13.2z"></path></svg>-->
                    <!--          </span>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.comments!.commentsToLikes!.value
                    }}</span>
                </span>
                <span
                    *ngIf="
                        (data$ | async)!.comments!.commentsToLikes!.percent.includes('0.00') &&
                        !(data$ | async)!.comments!.commentsToLikes!.percent.includes('-')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted middle stats-value"
                >
                    <!--          <svg class="chevron-icon"  viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="minus" aria-hidden="true">-->
                    <!--            <path d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"></path>-->
                    <!--          </svg>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.comments!.commentsToLikes!.value
                    }}</span>
                </span>
                <!--        <span class="ant-statistic-content-value-int ng-star-inserted stats-mini-value">{{(data$ | async)!.comments!.commentsToLikes!.percent}}%</span>-->
            </nz-card>
        </nz-col>
        <nz-col
            [nzXs]="{ span: 12, offset: 0 }"
            [nzSm]="{ span: 12, offset: 0 }"
            [nzMd]="{ span: 12, offset: 0 }"
            [nzLg]="{ span: 4, offset: 0 }"
            [nzXl]="{ span: 4, offset: 0 }"
        >
            <nz-card>
                <div class="ant-statistic-title">Sessions to comment</div>
                <span
                    *ngIf="
                        !(data$ | async)!.comments!.commentsToSessions!.percent.includes('-') &&
                        !(data$ | async)!.comments!.commentsToSessions!.percent.includes('0.00')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted up stats-value"
                >
                    <!--          <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">-->
                    <!--            <svg class="chevron-icon" viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="arrow-up" aria-hidden="true"><path d="M868 545.5L536.1 163a31.96 31.96 0 00-48.3 0L156 545.5a7.97 7.97 0 006 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path></svg>-->
                    <!--          </span>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.comments!.commentsToSessions!.value
                    }}</span>
                </span>
                <span
                    *ngIf="(data$ | async)!.comments!.commentsToSessions!.percent.includes('-')"
                    class="ant-statistic-content-prefix ng-star-inserted down stats-value"
                >
                    <!--          <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">-->
                    <!--            <svg class="chevron-icon"  viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="arrow-down" aria-hidden="true"><path d="M862 465.3h-81c-4.6 0-9 2-12.1 5.5L550 723.1V160c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v563.1L255.1 470.8c-3-3.5-7.4-5.5-12.1-5.5h-81c-6.8 0-10.5 8.1-6 13.2L487.9 861a31.96 31.96 0 0048.3 0L868 478.5c4.5-5.2.8-13.2-6-13.2z"></path></svg>-->
                    <!--          </span>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.comments!.commentsToSessions!.value
                    }}</span>
                </span>
                <span
                    *ngIf="
                        (data$ | async)!.comments!.commentsToSessions!.percent.includes('0.00') &&
                        !(data$ | async)!.comments!.commentsToSessions!.percent.includes('-')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted middle stats-value"
                >
                    <!--          <svg class="chevron-icon"  viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="minus" aria-hidden="true">-->
                    <!--            <path d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"></path>-->
                    <!--          </svg>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.comments!.commentsToSessions!.value
                    }}</span>
                </span>
                <!--        <span class="ant-statistic-content-value-int ng-star-inserted stats-mini-value">{{(data$ | async)!.comments!.commentsToSessions!.percent}}%</span>-->
            </nz-card>
        </nz-col>
        <nz-col
            [nzXs]="{ span: 12, offset: 0 }"
            [nzSm]="{ span: 12, offset: 0 }"
            [nzMd]="{ span: 12, offset: 0 }"
            [nzLg]="{ span: 4, offset: 0 }"
            [nzXl]="{ span: 4, offset: 0 }"
        >
            <nz-card>
                <div class="ant-statistic-title">Liked videos to comment</div>
                <span
                    *ngIf="
                        !(data$ | async)!.comments!.commentsToLikedVideos!.percent.includes('-') &&
                        !(data$ | async)!.comments!.commentsToLikedVideos!.percent.includes('0.00')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted up stats-value"
                >
                    <!--          <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">-->
                    <!--            <svg class="chevron-icon" viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="arrow-up" aria-hidden="true"><path d="M868 545.5L536.1 163a31.96 31.96 0 00-48.3 0L156 545.5a7.97 7.97 0 006 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path></svg>-->
                    <!--          </span>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.comments!.commentsToLikedVideos!.value
                    }}</span>
                </span>
                <span
                    *ngIf="(data$ | async)!.comments!.commentsToLikedVideos!.percent.includes('-')"
                    class="ant-statistic-content-prefix ng-star-inserted down stats-value"
                >
                    <!--          <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">-->
                    <!--            <svg class="chevron-icon"  viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="arrow-down" aria-hidden="true"><path d="M862 465.3h-81c-4.6 0-9 2-12.1 5.5L550 723.1V160c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v563.1L255.1 470.8c-3-3.5-7.4-5.5-12.1-5.5h-81c-6.8 0-10.5 8.1-6 13.2L487.9 861a31.96 31.96 0 0048.3 0L868 478.5c4.5-5.2.8-13.2-6-13.2z"></path></svg>-->
                    <!--          </span>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.comments!.commentsToLikedVideos!.value
                    }}</span>
                </span>
                <span
                    *ngIf="
                        (data$ | async)!.comments!.commentsToLikedVideos!.percent.includes('0.00') &&
                        !(data$ | async)!.comments!.commentsToLikedVideos!.percent.includes('-')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted middle stats-value"
                >
                    <!--          <svg class="chevron-icon"  viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="minus" aria-hidden="true">-->
                    <!--            <path d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"></path>-->
                    <!--          </svg>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.comments!.commentsToLikedVideos!.value
                    }}</span>
                </span>
                <!--        <span class="ant-statistic-content-value-int ng-star-inserted stats-mini-value">{{(data$ | async)!.comments!.commentsToLikedVideos!.percent}}%</span>-->
            </nz-card>
        </nz-col>
    </nz-row>

    <div style="display: flex; padding-top: 10px;">
        <h4>Likes</h4>
        <div class="tag-table">Analytics about likes (week to week percents)</div>
    </div>
    <nz-row [nzGutter]="12">
        <nz-col
            [nzXs]="{ span: 12, offset: 0 }"
            [nzSm]="{ span: 12, offset: 0 }"
            [nzMd]="{ span: 12, offset: 0 }"
            [nzLg]="{ span: 8, offset: 0 }"
            [nzXl]="{ span: 8, offset: 0 }"
        >
            <nz-card>
                <div class="ant-statistic-title">Total likes</div>
                <span
                    *ngIf="
                        !(data$ | async)!.likes!.percent.includes('-') &&
                        !(data$ | async)!.likes!.percent.includes('0.00')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted up stats-value"
                >
                    <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">
                        <svg
                            class="chevron-icon"
                            viewBox="64 64 896 896"
                            focusable="false"
                            fill="currentColor"
                            width="1em"
                            height="1em"
                            data-icon="arrow-up"
                            aria-hidden="true"
                        >
                            <path
                                d="M868 545.5L536.1 163a31.96 31.96 0 00-48.3 0L156 545.5a7.97 7.97 0 006 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"
                            ></path>
                        </svg>
                    </span>
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)?.likes?.total
                    }}</span>
                </span>
                <span
                    *ngIf="(data$ | async)!.likes!.percent.includes('-')"
                    class="ant-statistic-content-prefix ng-star-inserted down stats-value"
                >
                    <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">
                        <svg
                            class="chevron-icon"
                            viewBox="64 64 896 896"
                            focusable="false"
                            fill="currentColor"
                            width="1em"
                            height="1em"
                            data-icon="arrow-down"
                            aria-hidden="true"
                        >
                            <path
                                d="M862 465.3h-81c-4.6 0-9 2-12.1 5.5L550 723.1V160c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v563.1L255.1 470.8c-3-3.5-7.4-5.5-12.1-5.5h-81c-6.8 0-10.5 8.1-6 13.2L487.9 861a31.96 31.96 0 0048.3 0L868 478.5c4.5-5.2.8-13.2-6-13.2z"
                            ></path>
                        </svg>
                    </span>
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)?.likes?.total
                    }}</span>
                </span>
                <span
                    *ngIf="
                        (data$ | async)!.likes!.percent.includes('0.00') &&
                        !(data$ | async)!.likes!.percent.includes('-')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted middle stats-value"
                >
                    <svg
                        class="chevron-icon"
                        viewBox="64 64 896 896"
                        focusable="false"
                        fill="currentColor"
                        width="1em"
                        height="1em"
                        data-icon="minus"
                        aria-hidden="true"
                    >
                        <path
                            d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"
                        ></path>
                    </svg>
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)?.likes?.total
                    }}</span>
                </span>
                <span class="ant-statistic-content-value-int ng-star-inserted stats-mini-value"
                    >{{ (data$ | async)?.likes?.percent }}%</span
                >
            </nz-card>
        </nz-col>
        <nz-col
            [nzXs]="{ span: 12, offset: 0 }"
            [nzSm]="{ span: 12, offset: 0 }"
            [nzMd]="{ span: 12, offset: 0 }"
            [nzLg]="{ span: 8, offset: 0 }"
            [nzXl]="{ span: 8, offset: 0 }"
        >
            <nz-card>
                <div class="ant-statistic-title">Videos with likes</div>
                <span
                    *ngIf="
                        !(data$ | async)!.likes!.video!.percent.includes('-') &&
                        !(data$ | async)!.likes!.video!.percent.includes('0.00')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted up stats-value"
                >
                    <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">
                        <svg
                            class="chevron-icon"
                            viewBox="64 64 896 896"
                            focusable="false"
                            fill="currentColor"
                            width="1em"
                            height="1em"
                            data-icon="arrow-up"
                            aria-hidden="true"
                        >
                            <path
                                d="M868 545.5L536.1 163a31.96 31.96 0 00-48.3 0L156 545.5a7.97 7.97 0 006 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"
                            ></path>
                        </svg>
                    </span>
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)?.likes?.video?.total
                    }}</span>
                </span>
                <span
                    *ngIf="(data$ | async)!.likes!.video!.percent.includes('-')"
                    class="ant-statistic-content-prefix ng-star-inserted down stats-value"
                >
                    <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">
                        <svg
                            class="chevron-icon"
                            viewBox="64 64 896 896"
                            focusable="false"
                            fill="currentColor"
                            width="1em"
                            height="1em"
                            data-icon="arrow-down"
                            aria-hidden="true"
                        >
                            <path
                                d="M862 465.3h-81c-4.6 0-9 2-12.1 5.5L550 723.1V160c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v563.1L255.1 470.8c-3-3.5-7.4-5.5-12.1-5.5h-81c-6.8 0-10.5 8.1-6 13.2L487.9 861a31.96 31.96 0 0048.3 0L868 478.5c4.5-5.2.8-13.2-6-13.2z"
                            ></path>
                        </svg>
                    </span>
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)?.likes?.video?.total
                    }}</span>
                </span>
                <span
                    *ngIf="
                        (data$ | async)!.likes!.video!.percent.includes('0.00') &&
                        !(data$ | async)!.likes!.video!.percent.includes('-')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted middle stats-value"
                >
                    <svg
                        class="chevron-icon"
                        viewBox="64 64 896 896"
                        focusable="false"
                        fill="currentColor"
                        width="1em"
                        height="1em"
                        data-icon="minus"
                        aria-hidden="true"
                    >
                        <path
                            d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"
                        ></path>
                    </svg>
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)?.likes?.video?.total
                    }}</span>
                </span>
                <span class="ant-statistic-content-value-int ng-star-inserted stats-mini-value"
                    >{{ (data$ | async)?.likes?.video!.percent }}%</span
                >
            </nz-card>
        </nz-col>
        <nz-col
            [nzXs]="{ span: 12, offset: 0 }"
            [nzSm]="{ span: 12, offset: 0 }"
            [nzMd]="{ span: 12, offset: 0 }"
            [nzLg]="{ span: 8, offset: 0 }"
            [nzXl]="{ span: 8, offset: 0 }"
        >
            <nz-card>
                <div class="ant-statistic-title">Users who set likes</div>
                <span
                    *ngIf="
                        !(data$ | async)!.likes!.users!.percent.includes('-') &&
                        !(data$ | async)!.likes!.users!.percent.includes('0.00')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted up stats-value"
                >
                    <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">
                        <svg
                            class="chevron-icon"
                            viewBox="64 64 896 896"
                            focusable="false"
                            fill="currentColor"
                            width="1em"
                            height="1em"
                            data-icon="arrow-up"
                            aria-hidden="true"
                        >
                            <path
                                d="M868 545.5L536.1 163a31.96 31.96 0 00-48.3 0L156 545.5a7.97 7.97 0 006 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"
                            ></path>
                        </svg>
                    </span>
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)?.likes?.users?.total
                    }}</span>
                </span>
                <span
                    *ngIf="(data$ | async)!.likes!.users!.percent.includes('-')"
                    class="ant-statistic-content-prefix ng-star-inserted down stats-value"
                >
                    <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">
                        <svg
                            class="chevron-icon"
                            viewBox="64 64 896 896"
                            focusable="false"
                            fill="currentColor"
                            width="1em"
                            height="1em"
                            data-icon="arrow-down"
                            aria-hidden="true"
                        >
                            <path
                                d="M862 465.3h-81c-4.6 0-9 2-12.1 5.5L550 723.1V160c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v563.1L255.1 470.8c-3-3.5-7.4-5.5-12.1-5.5h-81c-6.8 0-10.5 8.1-6 13.2L487.9 861a31.96 31.96 0 0048.3 0L868 478.5c4.5-5.2.8-13.2-6-13.2z"
                            ></path>
                        </svg>
                    </span>
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)?.likes?.users?.total
                    }}</span>
                </span>
                <span
                    *ngIf="
                        (data$ | async)!.likes!.users!.percent.includes('0.00') &&
                        !(data$ | async)!.likes!.users!.percent.includes('-')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted middle stats-value"
                >
                    <svg
                        class="chevron-icon"
                        viewBox="64 64 896 896"
                        focusable="false"
                        fill="currentColor"
                        width="1em"
                        height="1em"
                        data-icon="minus"
                        aria-hidden="true"
                    >
                        <path
                            d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"
                        ></path>
                    </svg>
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)?.likes?.users?.total
                    }}</span>
                </span>
                <span class="ant-statistic-content-value-int ng-star-inserted stats-mini-value"
                    >{{ (data$ | async)?.likes?.users!.percent }}%</span
                >
            </nz-card>
        </nz-col>

        <nz-col
            [nzXs]="{ span: 12, offset: 0 }"
            [nzSm]="{ span: 12, offset: 0 }"
            [nzMd]="{ span: 12, offset: 0 }"
            [nzLg]="{ span: 4, offset: 0 }"
            [nzXl]="{ span: 4, offset: 0 }"
        >
            <nz-card>
                <div class="ant-statistic-title">Views to like</div>
                <span
                    *ngIf="
                        !(data$ | async)!.likes!.likesToViews!.percent.includes('-') &&
                        !(data$ | async)!.likes!.likesToViews!.percent.includes('0.00')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted up stats-value"
                >
                    <!--          <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">-->
                    <!--            <svg class="chevron-icon" viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="arrow-up" aria-hidden="true"><path d="M868 545.5L536.1 163a31.96 31.96 0 00-48.3 0L156 545.5a7.97 7.97 0 006 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path></svg>-->
                    <!--          </span>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.likes!.likesToViews!.value
                    }}</span>
                </span>
                <span
                    *ngIf="(data$ | async)!.likes!.likesToViews!.percent.includes('-')"
                    class="ant-statistic-content-prefix ng-star-inserted down stats-value"
                >
                    <!--          <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">-->
                    <!--            <svg class="chevron-icon"  viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="arrow-down" aria-hidden="true"><path d="M862 465.3h-81c-4.6 0-9 2-12.1 5.5L550 723.1V160c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v563.1L255.1 470.8c-3-3.5-7.4-5.5-12.1-5.5h-81c-6.8 0-10.5 8.1-6 13.2L487.9 861a31.96 31.96 0 0048.3 0L868 478.5c4.5-5.2.8-13.2-6-13.2z"></path></svg>-->
                    <!--          </span>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.likes!.likesToViews!.value
                    }}</span>
                </span>
                <span
                    *ngIf="
                        (data$ | async)!.likes!.likesToViews!.percent.includes('0.00') &&
                        !(data$ | async)!.likes!.likesToViews!.percent.includes('-')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted middle stats-value"
                >
                    <!--          <svg class="chevron-icon"  viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="minus" aria-hidden="true">-->
                    <!--            <path d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"></path>-->
                    <!--          </svg>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.likes!.likesToViews!.value
                    }}</span>
                </span>
                <!--        <span class="ant-statistic-content-value-int ng-star-inserted stats-mini-value">{{(data$ | async)!.likes!.likesToViews!.percent}}%</span>-->
            </nz-card>
        </nz-col>
        <nz-col
            [nzXs]="{ span: 12, offset: 0 }"
            [nzSm]="{ span: 12, offset: 0 }"
            [nzMd]="{ span: 12, offset: 0 }"
            [nzLg]="{ span: 4, offset: 0 }"
            [nzXl]="{ span: 4, offset: 0 }"
        >
            <nz-card>
                <div class="ant-statistic-title">Users who viewed videos to like</div>
                <span
                    *ngIf="
                        !(data$ | async)!.likes!.likesToViewedUsers!.percent.includes('-') &&
                        !(data$ | async)!.likes!.likesToViewedUsers!.percent.includes('0.00')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted up stats-value"
                >
                    <!--          <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">-->
                    <!--            <svg class="chevron-icon" viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="arrow-up" aria-hidden="true"><path d="M868 545.5L536.1 163a31.96 31.96 0 00-48.3 0L156 545.5a7.97 7.97 0 006 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path></svg>-->
                    <!--          </span>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.likes!.likesToViewedUsers!.value
                    }}</span>
                </span>
                <span
                    *ngIf="(data$ | async)!.likes!.likesToViewedUsers!.percent.includes('-')"
                    class="ant-statistic-content-prefix ng-star-inserted down stats-value"
                >
                    <!--          <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">-->
                    <!--            <svg class="chevron-icon"  viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="arrow-down" aria-hidden="true"><path d="M862 465.3h-81c-4.6 0-9 2-12.1 5.5L550 723.1V160c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v563.1L255.1 470.8c-3-3.5-7.4-5.5-12.1-5.5h-81c-6.8 0-10.5 8.1-6 13.2L487.9 861a31.96 31.96 0 0048.3 0L868 478.5c4.5-5.2.8-13.2-6-13.2z"></path></svg>-->
                    <!--          </span>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.likes!.likesToViewedUsers!.value
                    }}</span>
                </span>
                <span
                    *ngIf="
                        (data$ | async)!.likes!.likesToViewedUsers!.percent.includes('0.00') &&
                        !(data$ | async)!.likes!.likesToViewedUsers!.percent.includes('-')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted middle stats-value"
                >
                    <!--          <svg class="chevron-icon"  viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="minus" aria-hidden="true">-->
                    <!--            <path d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"></path>-->
                    <!--          </svg>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.likes!.likesToViewedUsers!.value
                    }}</span>
                </span>
                <!--        <span class="ant-statistic-content-value-int ng-star-inserted stats-mini-value">{{(data$ | async)!.likes!.likesToViewedUsers!.percent}}%</span>-->
            </nz-card>
        </nz-col>
        <nz-col
            [nzXs]="{ span: 12, offset: 0 }"
            [nzSm]="{ span: 12, offset: 0 }"
            [nzMd]="{ span: 12, offset: 0 }"
            [nzLg]="{ span: 4, offset: 0 }"
            [nzXl]="{ span: 4, offset: 0 }"
        >
            <nz-card>
                <div class="ant-statistic-title">Videos to like</div>
                <span
                    *ngIf="
                        !(data$ | async)!.likes!.likesToVideos!.percent.includes('-') &&
                        !(data$ | async)!.likes!.likesToVideos!.percent.includes('0.00')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted up stats-value"
                >
                    <!--          <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">-->
                    <!--            <svg class="chevron-icon" viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="arrow-up" aria-hidden="true"><path d="M868 545.5L536.1 163a31.96 31.96 0 00-48.3 0L156 545.5a7.97 7.97 0 006 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path></svg>-->
                    <!--          </span>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.likes!.likesToVideos!.value
                    }}</span>
                </span>
                <span
                    *ngIf="(data$ | async)!.likes!.likesToVideos!.percent.includes('-')"
                    class="ant-statistic-content-prefix ng-star-inserted down stats-value"
                >
                    <!--          <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">-->
                    <!--            <svg class="chevron-icon"  viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="arrow-down" aria-hidden="true"><path d="M862 465.3h-81c-4.6 0-9 2-12.1 5.5L550 723.1V160c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v563.1L255.1 470.8c-3-3.5-7.4-5.5-12.1-5.5h-81c-6.8 0-10.5 8.1-6 13.2L487.9 861a31.96 31.96 0 0048.3 0L868 478.5c4.5-5.2.8-13.2-6-13.2z"></path></svg>-->
                    <!--          </span>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.likes!.likesToVideos!.value
                    }}</span>
                </span>
                <span
                    *ngIf="
                        (data$ | async)!.likes!.likesToVideos!.percent.includes('0.00') &&
                        !(data$ | async)!.likes!.likesToVideos!.percent.includes('-')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted middle stats-value"
                >
                    <!--          <svg class="chevron-icon"  viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="minus" aria-hidden="true">-->
                    <!--            <path d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"></path>-->
                    <!--          </svg>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.likes!.likesToVideos!.value
                    }}</span>
                </span>
                <!--        <span class="ant-statistic-content-value-int ng-star-inserted stats-mini-value">{{(data$ | async)!.likes!.likesToVideos!.percent}}%</span>-->
            </nz-card>
        </nz-col>
        <nz-col
            [nzXs]="{ span: 12, offset: 0 }"
            [nzSm]="{ span: 12, offset: 0 }"
            [nzMd]="{ span: 12, offset: 0 }"
            [nzLg]="{ span: 4, offset: 0 }"
            [nzXl]="{ span: 4, offset: 0 }"
        >
            <nz-card>
                <div class="ant-statistic-title">Comments to like</div>
                <span
                    *ngIf="
                        !(data$ | async)!.likes!.likesToComments!.percent.includes('-') &&
                        !(data$ | async)!.likes!.likesToComments!.percent.includes('0.00')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted up stats-value"
                >
                    <!--          <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">-->
                    <!--            <svg class="chevron-icon" viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="arrow-up" aria-hidden="true"><path d="M868 545.5L536.1 163a31.96 31.96 0 00-48.3 0L156 545.5a7.97 7.97 0 006 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path></svg>-->
                    <!--          </span>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.likes!.likesToComments!.value
                    }}</span>
                </span>
                <span
                    *ngIf="(data$ | async)!.likes!.likesToComments!.percent.includes('-')"
                    class="ant-statistic-content-prefix ng-star-inserted down stats-value"
                >
                    <!--          <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">-->
                    <!--            <svg class="chevron-icon"  viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="arrow-down" aria-hidden="true"><path d="M862 465.3h-81c-4.6 0-9 2-12.1 5.5L550 723.1V160c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v563.1L255.1 470.8c-3-3.5-7.4-5.5-12.1-5.5h-81c-6.8 0-10.5 8.1-6 13.2L487.9 861a31.96 31.96 0 0048.3 0L868 478.5c4.5-5.2.8-13.2-6-13.2z"></path></svg>-->
                    <!--          </span>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.likes!.likesToComments!.value
                    }}</span>
                </span>
                <span
                    *ngIf="
                        (data$ | async)!.likes!.likesToComments!.percent.includes('0.00') &&
                        !(data$ | async)!.likes!.likesToComments!.percent.includes('-')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted middle stats-value"
                >
                    <!--          <svg class="chevron-icon"  viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="minus" aria-hidden="true">-->
                    <!--            <path d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"></path>-->
                    <!--          </svg>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.likes!.likesToComments!.value
                    }}</span>
                </span>
                <!--        <span class="ant-statistic-content-value-int ng-star-inserted stats-mini-value">{{(data$ | async)!.likes!.likesToComments!.percent}}%</span>-->
            </nz-card>
        </nz-col>
        <nz-col
            [nzXs]="{ span: 12, offset: 0 }"
            [nzSm]="{ span: 12, offset: 0 }"
            [nzMd]="{ span: 12, offset: 0 }"
            [nzLg]="{ span: 4, offset: 0 }"
            [nzXl]="{ span: 4, offset: 0 }"
        >
            <nz-card>
                <div class="ant-statistic-title">Sessions to like</div>
                <span
                    *ngIf="
                        !(data$ | async)!.likes!.likesToSessions!.percent.includes('-') &&
                        !(data$ | async)!.likes!.likesToSessions!.percent.includes('0.00')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted up stats-value"
                >
                    <!--          <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">-->
                    <!--            <svg class="chevron-icon" viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="arrow-up" aria-hidden="true"><path d="M868 545.5L536.1 163a31.96 31.96 0 00-48.3 0L156 545.5a7.97 7.97 0 006 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path></svg>-->
                    <!--          </span>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.likes!.likesToSessions!.value
                    }}</span>
                </span>
                <span
                    *ngIf="(data$ | async)!.likes!.likesToSessions!.percent.includes('-')"
                    class="ant-statistic-content-prefix ng-star-inserted down stats-value"
                >
                    <!--          <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">-->
                    <!--            <svg class="chevron-icon"  viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="arrow-down" aria-hidden="true"><path d="M862 465.3h-81c-4.6 0-9 2-12.1 5.5L550 723.1V160c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v563.1L255.1 470.8c-3-3.5-7.4-5.5-12.1-5.5h-81c-6.8 0-10.5 8.1-6 13.2L487.9 861a31.96 31.96 0 0048.3 0L868 478.5c4.5-5.2.8-13.2-6-13.2z"></path></svg>-->
                    <!--          </span>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.likes!.likesToSessions!.value
                    }}</span>
                </span>
                <span
                    *ngIf="
                        (data$ | async)!.likes!.likesToSessions!.percent.includes('0.00') &&
                        !(data$ | async)!.likes!.likesToSessions!.percent.includes('-')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted middle stats-value"
                >
                    <!--          <svg class="chevron-icon"  viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="minus" aria-hidden="true">-->
                    <!--            <path d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"></path>-->
                    <!--          </svg>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.likes!.likesToSessions!.value
                    }}</span>
                </span>
                <!--        <span class="ant-statistic-content-value-int ng-star-inserted stats-mini-value">{{(data$ | async)!.likes!.likesToSessions!.percent}}%</span>-->
            </nz-card>
        </nz-col>
        <nz-col
            [nzXs]="{ span: 12, offset: 0 }"
            [nzSm]="{ span: 12, offset: 0 }"
            [nzMd]="{ span: 12, offset: 0 }"
            [nzLg]="{ span: 4, offset: 0 }"
            [nzXl]="{ span: 4, offset: 0 }"
        >
            <nz-card>
                <div class="ant-statistic-title">Commented videos to like</div>
                <span
                    *ngIf="
                        !(data$ | async)!.likes!.likesToCommentedVideos!.percent.includes('-') &&
                        !(data$ | async)!.likes!.likesToCommentedVideos!.percent.includes('0.00')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted up stats-value"
                >
                    <!--          <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">-->
                    <!--            <svg class="chevron-icon" viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="arrow-up" aria-hidden="true"><path d="M868 545.5L536.1 163a31.96 31.96 0 00-48.3 0L156 545.5a7.97 7.97 0 006 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path></svg>-->
                    <!--          </span>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.likes!.likesToCommentedVideos!.value
                    }}</span>
                </span>
                <span
                    *ngIf="(data$ | async)!.likes!.likesToCommentedVideos!.percent.includes('-')"
                    class="ant-statistic-content-prefix ng-star-inserted down stats-value"
                >
                    <!--          <span nz-icon="" nztype="arrow-up" class="anticon anticon-arrow-up ng-star-inserted">-->
                    <!--            <svg class="chevron-icon"  viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="arrow-down" aria-hidden="true"><path d="M862 465.3h-81c-4.6 0-9 2-12.1 5.5L550 723.1V160c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v563.1L255.1 470.8c-3-3.5-7.4-5.5-12.1-5.5h-81c-6.8 0-10.5 8.1-6 13.2L487.9 861a31.96 31.96 0 0048.3 0L868 478.5c4.5-5.2.8-13.2-6-13.2z"></path></svg>-->
                    <!--          </span>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.likes!.likesToCommentedVideos!.value
                    }}</span>
                </span>
                <span
                    *ngIf="
                        (data$ | async)!.likes!.likesToCommentedVideos!.percent.includes('0.00') &&
                        !(data$ | async)!.likes!.likesToCommentedVideos!.percent.includes('-')
                    "
                    class="ant-statistic-content-prefix ng-star-inserted middle stats-value"
                >
                    <!--          <svg class="chevron-icon"  viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="minus" aria-hidden="true">-->
                    <!--            <path d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"></path>-->
                    <!--          </svg>-->
                    <span class="ant-statistic-content-value-int ng-star-inserted">{{
                        (data$ | async)!.likes!.likesToCommentedVideos!.value
                    }}</span>
                </span>
                <!--        <span class="ant-statistic-content-value-int ng-star-inserted stats-mini-value">{{(data$ | async)!.likes!.likesToCommentedVideos!.percent}}%</span>-->
            </nz-card>
        </nz-col>
    </nz-row>
</div>
