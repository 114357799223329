import { Component, OnInit } from '@angular/core';
import { FcaKicksApiService } from '@fca-app/api/kicks/fca-kicks-api.service';
import {
    KickRealtimeMetricsApiResponse,
    KickTotalSelection,
} from '@fca-app/api/kicks/interfaces/response/kick-api.response';
import { untilDestroy, UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { BehaviorSubject, interval, Subject } from 'rxjs';
import { mergeMap, take, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'statistics-kick-info',
    templateUrl: './statistics-kick-info.component.html',
    styleUrls: ['./statistics-kick-info.component.scss'],
})
export class StatisticsKickInfoComponent implements OnInit {
    public data$ = new BehaviorSubject<KickTotalSelection>({
        comments: {
            current: 0,
            lastWeek: 0,
            percent: '0',
            total: 0,
            users: {
                current: 0,
                lastWeek: 0,
                percent: '',
                total: 0,
            },
            videos: {
                current: 0,
                lastWeek: 0,
                percent: '0',
                total: 0,
            },
            commentsToViews: {
                value: '0',
                percent: '0',
            },
            commentsToViewedUsers: {
                value: '0',
                percent: '0',
            },
            commentsToVideos: {
                value: '0',
                percent: '0',
            },
            commentsToLikes: {
                value: '0',
                percent: '0',
            },
            commentsToSessions: {
                value: '0',
                percent: '0',
            },
            commentsToLikedVideos: {
                value: '0',
                percent: '0',
            },
        },
        downloaded: {
            current: 0,
            lastWeek: 0,
            percent: '0',
            total: 0,
        },
        likes: {
            current: 0,
            lastWeek: 0,
            percent: '0',
            total: 0,
            users: {
                current: 0,
                lastWeek: 0,
                percent: '0',
                total: 0,
            },
            video: {
                current: 0,
                lastWeek: 0,
                percent: '0',
                total: 0,
            },
            likesToViews: {
                value: '0',
                percent: '0',
            },
            likesToViewedUsers: {
                value: '0',
                percent: '0',
            },
            likesToVideos: {
                value: '0',
                percent: '0',
            },
            likesToComments: {
                value: '0',
                percent: '0',
            },
            likesToSessions: {
                value: '0',
                percent: '0',
            },
            likesToCommentedVideos: {
                value: '0',
                percent: '0',
            },
        },
        requests: {
            current: 0,
            lastWeek: 0,
            percent: '0',
            total: 0,
        },
        sessions: {
            current: 0,
            lastWeek: 0,
            percent: '0',
            total: 0,
        },
        unique: {
            current: 0,
            lastWeek: 0,
            percent: '0',
            total: 0,
        },
        users: {
            current: 0,
            lastWeek: 0,
            percent: '0',
            total: 0,
        },
        videos: {
            current: 0,
            lastWeek: 0,
            percent: '0',
            total: 0,
        },
        viewed: {
            current: 0,
            lastWeek: 0,
            percent: '0',
            total: 0,
        },
        views: {
            current: 0,
            lastWeek: 0,
            percent: '0',
            total: 0,
        },
    });
    private refresher = interval(5 * 1000)
        .pipe(
            untilDestroy(this),
            mergeMap(() => this.fcaKicksApiService.totalSelection().pipe(take(1)))
        )
        .subscribe(data => this.data$.next(data));

    constructor(private readonly fcaKicksApiService: FcaKicksApiService) {}

    public async ngOnInit(): Promise<void> {
        const getData = this.fcaKicksApiService
            .totalSelection()
            .pipe(tap(_ => this.data$.next(_)))
            .subscribe(() => getData.unsubscribe());
    }

    public formatBytes(bytes: number, decimals = 2) {
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    }
}
