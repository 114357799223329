import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppMainConfigService } from '@fca-app/config/app-main-config.service';
import { TableDataListConfig } from '@fca-app/config/app-main.config';
import { EArenaStatus } from '@fca-app/enums/arena-status.enum';
import { ArenaModel } from '@fca-app/models/users/arena/arena.model';
import { ArenasService } from '@fca-app/services/arenas.service';
import { EAdminPermissions } from '@fca-app/shared/enums/admin-permissions.enum';
import { TableViewData } from '@fca-app/shared/interfaces/table-view-data.interface';
import { untilDestroy, UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { FcaPreloadService } from '@fca-app/shared/services/fca-preload.service';
import { TableSearchParams } from '@fca-app/shared/types';
import { SaveViewArenasPage } from '@fca-app/store/actions';
import { FcaStoreService } from '@fca-app/store/store.service';
import { NzBadgeStatusType } from 'ng-zorro-antd/badge/types';
import { NzTableComponent, NzTableLayout } from 'ng-zorro-antd/table';
import { NgxPermissionsService } from 'ngx-permissions';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, take, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'app-arena-list',
    templateUrl: './arena-list.component.html',
    styleUrls: ['./arena-list.component.scss'],
})
export class ArenaListComponent implements AfterViewInit, OnInit {
    readonly tableListConfig: TableDataListConfig = this.configService.getConfig().arenaListConfig;
    readonly tableLayout: NzTableLayout = 'auto';

    @ViewChild('tableComponent')
    tableComponent: NzTableComponent<ArenaModel[]>;
    activePage: number = 1;
    onSearchChanged$ = new Subject<string>();
    search$ = new BehaviorSubject<string | undefined>(undefined);
    arenasData$: Observable<TableViewData<ArenaModel>>;

    constructor(
        private readonly arenaService: ArenasService,
        private readonly configService: AppMainConfigService,
        private readonly storeService: FcaStoreService,
        public readonly preloadService: FcaPreloadService,
        private readonly permissionsService: NgxPermissionsService,
        private readonly router: Router
    ) {}

    ngOnInit() {
        this.arenasData$ = combineLatest([this.storeService.getArenasViewPage(), this.search$.asObservable()]).pipe(
            tap(([page]) => {
                this.preloadService.preload(true);
                this.activePage = page;
            }),
            map(([page, search]) => ({
                pageIndex: page,
                pageSize: this.tableListConfig.countItemsOnPage,
                filter: [],
                sort: [],
                searchQuery: search,
            })),
            switchMap((qp: TableSearchParams) => {
                return this.arenaService
                    .searchArenas(qp, qp.searchQuery)
                    .pipe(tap(() => this.preloadService.preload(false)));
            })
        );
        this.onSearchChanged$
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                tap(value => this.search$.next(value)),
                untilDestroy(this)
            )
            .subscribe();
    }

    ngAfterViewInit() {}

    onSearchChange(search: string): void {
        this.onSearchChanged$.next(search);
    }

    public getNextPage(page: number): void {
        this.storeService.dispatch(new SaveViewArenasPage({ page }));
    }

    async goToDetails(arena: ArenaModel): Promise<void> {
        const hasPermission = await this.permissionsService.hasPermission(EAdminPermissions.ViewArenaDetails);

        if (hasPermission) {
            await this.router.navigate(['dashboard/arenas/' + arena.id], { state: arena });
        }
    }

    public approveArena(arena: ArenaModel): void {
        this.arenaService
            .approveArena(arena.location.id)
            .pipe(take(1), untilDestroy(this))
            .subscribe(() => {
                arena.location.isPending = false;
            });
    }

    public viewArena(arena: ArenaModel): void {
        this.arenaService.selectedArena$.next(arena);
    }

    public getArenaStatus(isPending: boolean): EArenaStatus {
        return isPending ? EArenaStatus.PENDING : EArenaStatus.CONFIRMED;
    }

    public getNzBadgeStatus(isPending: boolean): NzBadgeStatusType {
        return isPending ? 'processing' : 'success';
    }

    public formatStr(value: string | undefined): string {
        let formattedStr = '';
        if (value) {
            formattedStr =
                value.slice(this.tableListConfig.descCutPosition.from, this.tableListConfig.descCutPosition.to) + '...';
        }
        return formattedStr;
    }
}
