import { VideoApiResponse } from '@fca-app/api/fca/arenas/interfaces/response/video-api.response';
import { VideoModelData } from '@fca-app/models/video/video.model';

export class VideoMapper {
    mapData(raw: VideoApiResponse): VideoModelData {
        return {
            id: raw.id,
            name: raw.name,
            status: raw.status,
            streamUrl: raw.streamUrl,
            videoUrl: raw.videoUrl,
            languages: raw.languages,
        };
    }
}
