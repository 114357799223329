import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { VideoMetricsService } from '@fca-app/services/video-metrics.service';
import { untilDestroy, UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { Color } from '@swimlane/ngx-charts';
import { ScaleType } from '@swimlane/ngx-charts/lib/common/types/scale-type.enum';
import { BehaviorSubject } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'online-graph-statistics-stream',
    templateUrl: './online-graph-statistics-stream.component.html',
    styleUrls: ['./online-graph-statistics-stream.component.scss'],
})
export class OnlineGraphStatisticsStreamComponent implements OnInit {
    @Input() eventBus = new EventEmitter<{ date: number[]; timeStepSelected: string; videoId: string | null }>();
    public colorScheme: Color = {
        name: 't',
        selectable: true,
        group: 'ordinal' as ScaleType,
        domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA'],
    };
    public multi$ = new BehaviorSubject<{ series: any[]; name: string }[]>([{ name: 'Online', series: [] }]);

    constructor(private readonly videoMetricsService: VideoMetricsService) {}

    ngOnInit(): void {
        this.eventBus
            .pipe(
                untilDestroy(this),
                mergeMap(data => this.videoMetricsService.getGraphData(data).pipe(take(1)))
            )
            .subscribe(data =>
                this.multi$.next([
                    { name: 'Online', series: data.graph.map(key => ({ value: Number(key.online), name: key.date })) },
                ])
            );
    }
}
