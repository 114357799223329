import {
    TransactionHistoryItemResponse,
    TransactionHistoryWithdrawItemResponse,
} from '@fca-app/api/fca/users/interfaces/response/fighter/fighter-account-info.response';
import { AccountInfoTransactionItemHistoryItemModelData } from '@fca-app/models/users/user/account-info/account-info-transaction-item/account-info-transaction-item-history-item/account-info-transaction-item-history-item.model';

export class AccountInfoTransactionItemHistoryItemMapper {
    mapData(
        raw: TransactionHistoryItemResponse | TransactionHistoryWithdrawItemResponse
    ): AccountInfoTransactionItemHistoryItemModelData {
        return {
            amount: raw.amount,
            type: raw.type,
            created: raw.created,
            status: (raw as TransactionHistoryWithdrawItemResponse).status,
        };
    }
}
