import { AfterContentInit, AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VideosMetricsListApiResponse } from '@fca-app/api/videos/interfaces/response/video-api.response';
import { UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { BehaviorSubject } from 'rxjs';
import { EOrderStatisticsVideo } from './enums/order.statistics-video.enum';
import { IColumnItemStatisticsVideoTable } from './interfaces/column-item.statistics-video-table.interface';
import { IDataItemStatisticsVideoTable } from './interfaces/data-item.statistics-video-table.interface';
import { DataStatisticsVideoTableService } from './services/data.statistics-video-table.service';
import { DescriptionSearchStatisticsVideoTableService } from './services/description-search.statistics-video-table.service';
import { QueriesStatisticsVideoTableService } from './services/queries.statistics-video-table.service';
import { SearchStatisticsVideoTableService } from './services/search.statistics-video-table.service';
import { UserSearchStatisticsVideoTableService } from './services/user-search.statistics-video-table.service';

@UntilDestroy()
@Component({
    selector: 'statistics-video-table',
    templateUrl: './statistics-video-table.component.html',
    styleUrls: ['./statistics-video-table.component.scss'],
    providers: [
        QueriesStatisticsVideoTableService,
        SearchStatisticsVideoTableService,
        UserSearchStatisticsVideoTableService,
        DescriptionSearchStatisticsVideoTableService,
        DataStatisticsVideoTableService,
    ],
})
export class StatisticsVideoTableComponent implements AfterContentInit, AfterViewInit {
    public search$ = new BehaviorSubject(undefined);

    @ViewChild('userSearch', { static: true }) userSearch: ElementRef<NzDropdownMenuComponent>;

    listOfData: IDataItemStatisticsVideoTable[] = [];
    listOfDisplayData = [...this.listOfData];
    visible = false;
    searchValue = '';
    loading = true;
    pageSize = 10;
    pageIndex = 1;
    filterGender = [
        { text: 'male', value: 'male' },
        { text: 'female', value: 'female' },
    ];

    public listOfColumns: IColumnItemStatisticsVideoTable[] = [];

    onQueryParamsChange(params: NzTableQueryParams): void {
        const { pageSize, pageIndex, sort, filter } = params;
        this.queriesStatisticsVideoTableService.changeTablePagination$.next({ limit: pageSize, page: pageIndex });
        const currentSort = sort.find(item => item.value !== null);
        const sortField = (currentSort && currentSort.key) || null;
        const sortOrder = (currentSort && currentSort.value) || null;
    }

    constructor(
        private route: ActivatedRoute,
        public readonly queriesStatisticsVideoTableService: QueriesStatisticsVideoTableService,
        public readonly userSearchStatisticsVideoTableService: UserSearchStatisticsVideoTableService,
        public readonly descriptionSearchStatisticsVideoTableService: DescriptionSearchStatisticsVideoTableService,
        private readonly searchStatisticsVideoTableService: SearchStatisticsVideoTableService,
        public readonly dataStatisticsVideoTableService: DataStatisticsVideoTableService
    ) {}

    ngAfterContentInit(): void {
        this.listOfColumns = [
            {
                name: 'Description',
                width: null,
                sortOrderName: EOrderStatisticsVideo.DESCRIPTION,
                showSort: true,
                showFilter: false,
                sortOrder: null,
                sortFn: true,
                sortDirections: ['ascend', 'descend', null],
                sortOrderChange: (_, __, ___, ____, _____) => {
                    if (_ === EOrderStatisticsVideo.DESCRIPTION && __) {
                        this.pageIndex = 1;
                        this.queriesStatisticsVideoTableService.changeOrderParams$.next({
                            type: _,
                            directions: __,
                            table: ___,
                            page: 1,
                            limit: this.pageSize,
                        });
                    }
                },
                filterMultiple: true,
                listOfFilter: [],
                filterFn: null,
                filterTrigger: null,
            },
            {
                name: 'Duration',
                width: null,
                sortOrderName: EOrderStatisticsVideo.DURATION,
                showSort: true,
                showFilter: false,
                sortOrder: null,
                sortFn: true,
                sortDirections: ['ascend', 'descend', null],
                sortOrderChange: (_, __, ___, ____, _____) => {
                    if (_ === EOrderStatisticsVideo.DURATION && __) {
                        this.pageIndex = 1;
                        this.queriesStatisticsVideoTableService.changeOrderParams$.next({
                            type: _,
                            directions: __,
                            table: ___,
                            page: 1,
                            limit: this.pageSize,
                        });
                    }
                },
                filterMultiple: true,
                listOfFilter: [],
                filterFn: null,
                filterTrigger: null,
            },
            {
                name: 'Views',
                width: null,
                sortOrderName: EOrderStatisticsVideo.VIEWS,
                showSort: true,
                showFilter: false,
                sortOrder: null,
                sortFn: true,
                sortDirections: ['descend', 'ascend', null],
                sortOrderChange: (_, __, ___, ____, _____) => {
                    if (_ === EOrderStatisticsVideo.VIEWS && __) {
                        this.pageIndex = 1;
                        this.queriesStatisticsVideoTableService.changeOrderParams$.next({
                            type: _,
                            directions: __,
                            table: ___,
                            page: 1,
                            limit: this.pageSize,
                        });
                    }
                },
                listOfFilter: [],
                filterFn: null,
                filterMultiple: false,
                filterTrigger: null,
            },
            {
                name: 'Viewed',
                width: null,
                sortOrderName: null,
                showSort: false,
                showFilter: false,
                sortOrder: null,
                sortFn: true,
                sortDirections: [],
                sortOrderChange: () => true,
                listOfFilter: [],
                filterFn: null,
                filterMultiple: false,
                filterTrigger: null,
            },
            {
                name: 'Downloaded',
                width: null,
                sortOrderName: null,
                showSort: false,
                showFilter: false,
                sortOrder: null,
                sortFn: true,
                sortDirections: [],
                sortOrderChange: () => true,
                listOfFilter: [],
                filterFn: null,
                filterMultiple: false,
                filterTrigger: null,
            },
            {
                name: 'Unique',
                width: null,
                sortOrderName: EOrderStatisticsVideo.UNIQUE,
                showSort: true,
                showFilter: false,
                sortOrder: null,
                sortFn: true,
                sortDirections: ['descend', 'ascend', null],
                sortOrderChange: (_, __, ___, ____, _____) => {
                    if (_ === EOrderStatisticsVideo.UNIQUE && __) {
                        this.pageIndex = 1;
                        this.queriesStatisticsVideoTableService.changeOrderParams$.next({
                            type: _,
                            directions: __,
                            table: ___,
                            page: 1,
                            limit: this.pageSize,
                        });
                    }
                },
                listOfFilter: [],
                filterFn: null,
                filterMultiple: false,
                filterTrigger: null,
            },
            {
                name: 'Comments',
                width: null,
                sortOrderName: EOrderStatisticsVideo.COMMENTS,
                showSort: true,
                showFilter: false,
                sortOrder: null,
                sortFn: true,
                sortDirections: ['descend', 'ascend', null],
                sortOrderChange: (_, __, ___, ____, _____) => {
                    if (_ === EOrderStatisticsVideo.COMMENTS && __) {
                        this.pageIndex = 1;
                        this.queriesStatisticsVideoTableService.changeOrderParams$.next({
                            type: _,
                            directions: __,
                            table: ___,
                            page: 1,
                            limit: this.pageSize,
                        });
                    }
                },
                listOfFilter: [],
                filterFn: null,
                filterMultiple: false,
                filterTrigger: null,
            },
            {
                name: 'User',
                width: null,
                sortOrderName: EOrderStatisticsVideo.USER_ID,
                showSort: true,
                showFilter: false,
                sortOrder: null,
                sortFn: true,
                sortDirections: ['descend', 'ascend', null],
                sortOrderChange: (_, __, ___, ____, _____) => {
                    if (_ === EOrderStatisticsVideo.USER_ID && __) {
                        this.pageIndex = 1;
                        this.queriesStatisticsVideoTableService.changeOrderParams$.next({
                            type: _,
                            directions: __,
                            table: ___,
                            page: 1,
                            limit: this.pageSize,
                        });
                    }
                },
                listOfFilter: [],
                filterFn: null,
                filterMultiple: false,
                filterTrigger: {
                    isVisible: false,
                    isActive: false,
                    dropDownMenu: this.userSearch,
                },
            },
            {
                name: 'Tags',
                width: null,
                sortOrderName: EOrderStatisticsVideo.TAGS,
                showSort: true,
                showFilter: false,
                sortOrder: null,
                sortFn: true,
                sortDirections: ['descend', 'ascend', null],
                sortOrderChange: (_, __, ___, ____, _____) => {
                    if (_ === EOrderStatisticsVideo.TAGS && __) {
                        this.pageIndex = 1;
                        this.queriesStatisticsVideoTableService.changeOrderParams$.next({
                            type: _,
                            directions: __,
                            table: ___,
                            page: 1,
                            limit: this.pageSize,
                        });
                    }
                },
                listOfFilter: [],
                filterFn: null,
                filterMultiple: false,
                filterTrigger: null,
            },
            {
                name: 'Uploaded',
                width: null,
                sortOrderName: EOrderStatisticsVideo.UPLOADED,
                showSort: true,
                showFilter: false,
                sortOrder: null,
                sortFn: true,
                sortDirections: ['descend', 'ascend', null],
                sortOrderChange: (_, __, ___, ____, _____) => {
                    if (_ === EOrderStatisticsVideo.UPLOADED && __) {
                        this.pageIndex = 1;
                        this.queriesStatisticsVideoTableService.changeOrderParams$.next({
                            type: _,
                            directions: __,
                            table: ___,
                            page: 1,
                            limit: this.pageSize,
                        });
                    }
                },
                listOfFilter: [],
                filterFn: null,
                filterMultiple: false,
                filterTrigger: null,
            },
        ];
    }

    public async ngAfterViewInit(): Promise<void> {
        const params = await this.route.queryParams.toPromise();
    }

    public reset(): void {
        this.searchValue = '';
        this.search();
    }

    public search(): void {
        this.visible = false;
        this.listOfDisplayData = this.listOfData.filter(
            (item: IDataItemStatisticsVideoTable) => item.name.indexOf(this.searchValue) !== -1
        );
    }
}
