import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ListKicksMetricsParams } from '@fca-app/api/kicks/interfaces/params/list-metrics.params';
import { AppMainConfigService } from '@fca-app/config/app-main-config.service';
import { ETypeStatisticsKickMap } from '@fca-app/dashboard/components/statistics/statistics-kick/statistics-kick-maps/enums/type.statistics-kick-map.enum';
import { Observable } from 'rxjs';
import { map, publishReplay, refCount } from 'rxjs/operators';
import {
    KickLineChart,
    KickMapMetricsApiResponse,
    KickRealtimeMetricsApiResponse,
    KicksMetricsListApiResponse,
    KickTop5VideosAndUsersResponse,
    KickTotalSelection,
} from './interfaces/response/kick-api.response';

@Injectable()
export class FcaKicksApiService {
    readonly kicksApiUrl: string;
    readonly defaultHeaders = { 'Content-Type': 'application/json' };

    constructor(protected readonly configService: AppMainConfigService, protected readonly http: HttpClient) {
        this.kicksApiUrl = configService.getConfig().kicksApiUrl;
    }

    public listMetrics(
        params: ListKicksMetricsParams
    ): Observable<{
        total: number;
        data: KicksMetricsListApiResponse[];
    }> {
        const url = [this.kicksApiUrl, 'metrics', 'admin', 'list'].join('/');
        return this.http
            .post<{
                total: number;
                data: KicksMetricsListApiResponse[];
            }>(url, params)
            .pipe(publishReplay(1), refCount());
    }

    public realtime(): Observable<KickRealtimeMetricsApiResponse> {
        const url = [this.kicksApiUrl, 'metrics', 'admin', 'realtime'].join('/');
        return this.http.get<KickRealtimeMetricsApiResponse>(url).pipe(
            publishReplay(1),
            refCount(),
            map(_ => ({
                users: _.users,
                mostPopularCountry: _?.mostPopularCountry ?? '-',
                mostPopularPlatform: _?.mostPopularPlatform ?? '-',
            }))
        );
    }

    public map(type: ETypeStatisticsKickMap): Observable<KickMapMetricsApiResponse> {
        const url = [this.kicksApiUrl, 'metrics', 'admin', 'map'].join('/');
        return this.http
            .post<KickMapMetricsApiResponse>(url, { type })
            .pipe(publishReplay(1), refCount());
    }

    public totalSelection(): Observable<KickTotalSelection> {
        const url = [this.kicksApiUrl, 'metrics', 'admin', 'totalSection'].join('/');
        return this.http.get<KickTotalSelection>(url).pipe(publishReplay(1), refCount());
    }

    public getTop5UsersAndVideos(): Observable<KickTop5VideosAndUsersResponse> {
        const url = [this.kicksApiUrl, 'metrics', 'admin', 'topVideoAndUsers'].join('/');
        return this.http.get<KickTop5VideosAndUsersResponse>(url).pipe(publishReplay(1), refCount());
    }

    public getLineChart(): Observable<KickLineChart[]> {
        const url = [this.kicksApiUrl, 'metrics', 'admin', 'lineChart'].join('/');
        return this.http.get<KickLineChart[]>(url).pipe(publishReplay(1), refCount());
    }
}
