import { AccountInfoTransactionItemModel } from '@fca-app/models/users/user/account-info/account-info-transaction-item/account-info-transaction-item.model';

export interface UserAccountInfoModelData {
    balance: number;
    withdrawAvailable: boolean;
    transactions: AccountInfoTransactionItemModel[];
}

export interface UserAccountInfoModel extends UserAccountInfoModelData {}

export class UserAccountInfoModel {}
