<nz-layout class="statistics-feedbacks-layout">
    <div style="max-width: 600px">
        <div>
            <nz-form-item>
                <label class="form-label">Period</label>
                <nz-select
                    nzPlaceHolder="Select period..."
                    [(ngModel)]="request.activity"
                    (ngModelChange)="filterChange$.next(true)"
                >
                    <nz-option
                        *ngFor="let option of activities"
                        [nzLabel]="option.label"
                        [nzValue]="option.value"
                    ></nz-option>
                </nz-select>
            </nz-form-item>
        </div>
        <button
            nz-button
            nzType="primary"
            [nzLoading]="preloadService.isLoad$ | async"
            nzShape="round"
            (click)="onDownloadClick()"
        >
            <i nz-icon nzType="download"></i>
            Download
        </button>
    </div>
</nz-layout>
