import { Component, OnInit } from '@angular/core';
import { FcaVideosApiService } from '@fca-app/api/videos/fca-videos-api.service';
import {
    VideoTop5UserResponse,
    VideoTop5VideoResponse,
} from '@fca-app/api/videos/interfaces/response/video-api.response';
import { UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'statistics-video-users-videos',
    templateUrl: './statistics-video-users-videos.component.html',
    styleUrls: ['./statistics-video-users-videos.component.scss'],
    providers: [],
})
export class StatisticsVideoUsersVideosComponent implements OnInit {
    public videosData: VideoTop5VideoResponse[] = [];
    public userDate: VideoTop5UserResponse[] = [];

    constructor(private readonly fcaVideosApiService: FcaVideosApiService) {}

    public ngOnInit(): void {
        const firstReq = this.fcaVideosApiService
            .getTop5UsersAndVideos()
            .pipe(
                tap(key => {
                    this.videosData = key.videos;
                    this.userDate = key.users;
                })
            )
            .subscribe(() => firstReq.unsubscribe());
    }
}
