import { Component, Input, OnInit } from '@angular/core';
import { MatchmakingRequestModel } from '@fca-app/models/matchmaking/matchmaking-request.model';
import { FighterModel } from '@fca-app/models/users/fighter/fighter.model';
import { MatchmakingService } from '@fca-app/services/matchmaking.service';
import { untilDestroy, UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { FcaPreloadService } from '@fca-app/shared/services/fca-preload.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { finalize, take } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'fighter-details-matching',
    styleUrls: ['./fighter-details-matching.component.scss'],
    templateUrl: './fighter-details-matching.component.html',
})
export class FighterDetailsMatchingComponent implements OnInit {
    @Input() fighter: FighterModel;
    loadingMore = false;
    list: MatchmakingRequestModel[] = [];

    constructor(
        public readonly preloadService: FcaPreloadService,
        private msg: NzMessageService,
        private readonly matchMakingService: MatchmakingService
    ) {}

    ngOnInit(): void {
        this.preloadService.preload(true);
        this.matchMakingService
            .getFighterRequests(this.fighter.id)
            .pipe(
                untilDestroy(this),
                take(1),
                finalize(() => this.preloadService.preload(false))
            )
            .subscribe(requests => {
                this.list = [...requests, ...requests, ...requests, ...requests];
            });
    }

    // onLoadMore(): void {
    //   this.loadingMore = true;
    //   this.list = this.data.concat([...Array(count)].fill({}).map(() => ({ loading: true, name: {} })));
    //   this.http
    //     .get(fakeDataUrl)
    //     .pipe(catchError(() => of({ results: [] })))
    //     .subscribe((res: any) => {
    //       this.data = this.data.concat(res.results);
    //       this.list = [...this.data];
    //       this.loadingMore = false;
    //     });
    // }

    edit(item: any): void {
        this.msg.success(item.email);
    }
}
