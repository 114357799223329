import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FanDetailsComponent } from '@fca-app/dashboard/components/home/fan-list/fan-details/fan-details-component';
import { FanListComponent } from '@fca-app/dashboard/components/home/fan-list/fan-list.component';
import { UserDetailsAccountInfoModule } from '@fca-app/dashboard/components/home/shared/modules/user-details-account-info/user-details-account-info.module';
import { SharedModule } from '@fca-app/shared/shared.module';

@NgModule({
    declarations: [FanListComponent, FanDetailsComponent],
    imports: [UserDetailsAccountInfoModule, FormsModule, SharedModule, RouterModule, ReactiveFormsModule],
    providers: [],
    exports: [FanListComponent],
})
export class FansModule {}
