import { Component, EventEmitter, Input } from '@angular/core';
import { FcaKicksApiService } from '@fca-app/api/kicks/fca-kicks-api.service';
import { ICities, ICoords, ICountries, IQualitiesDB } from '@fca-app/api/video-metrics/video.metrics.interface';
import { ETypeStatisticsKickMap } from '@fca-app/dashboard/components/statistics/statistics-kick/statistics-kick-maps/enums/type.statistics-kick-map.enum';
import { VideoMetricsService } from '@fca-app/services/video-metrics.service';
import { untilDestroy, UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { Color } from '@swimlane/ngx-charts';
import { ScaleType } from '@swimlane/ngx-charts/lib/common/types/scale-type.enum';
import * as L from 'leaflet';
import { latLng, tileLayer } from 'leaflet';
import { BehaviorSubject } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'statistics-kick-maps',
    templateUrl: './statistics-kick-maps.component.html',
    styleUrls: ['./statistics-kick-maps.component.scss'],
})
export class StatisticsKickMapsComponent {
    public readonly leafletOptions: { layers: L.TileLayer[]; zoom: number; center: L.LatLng } = {
        layers: [tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })],
        zoom: 2,
        center: latLng(0, 0),
    };

    public leafletMarkers: L.Marker[] = [];
    public selectedType = ETypeStatisticsKickMap.NO_LIMITS;
    public ETypeStatisticsKickMap = ETypeStatisticsKickMap;
    public leafletMarkerClusterOptions: L.MarkerClusterGroupOptions = {
        spiderfyOnMaxZoom: false,
        showCoverageOnHover: false,
        zoomToBoundsOnClick: false,
        disableClusteringAtZoom: 5,
    };

    public readonly nzChartsColorScheme: Color = {
        name: 't',
        selectable: true,
        group: 'ordinal' as ScaleType,
        domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA'],
    };

    public results$ = new BehaviorSubject<ICountries[] | ICities[]>([] as ICountries[]);
    public countries: ICountries[] = [];
    public cities: ICities[] = [];
    public selected: 'Countries' | 'Cities' = 'Countries';

    public query$ = new BehaviorSubject<ETypeStatisticsKickMap>(this.selectedType);
    public queryPipe = this.query$
        .pipe(
            untilDestroy(this),
            mergeMap(key => this.fcaKicksApiService.map(key)),
            tap(data => (this.leafletMarkers = this.getLeafletKicksMarkers(data.coords))),
            tap(data => {
                this.countries = data.countries;
                this.cities = data.cities;
            })
        )
        .subscribe(_ => {
            if (this.selected === 'Countries') {
                this.results$.next(this.countries);
            }
            if (this.selected === 'Cities') {
                this.results$.next(this.cities);
            }
        });

    constructor(
        private readonly videoMetricsService: VideoMetricsService,
        private readonly fcaKicksApiService: FcaKicksApiService
    ) {}

    public getLeafletKicksMarkers(response: ICoords[]): L.Marker[] {
        return response
            .filter(key => Boolean(key.lat && key.lat !== 'null'))
            .map(key => {
                const icon = L.icon({
                    iconSize: [25, 41],
                    iconAnchor: [13, 41],
                    iconUrl: '/assets/marker-icon.png',
                    shadowUrl: '/assets/marker-shadow.png',
                });
                const popup = L.popup({ closeButton: false, closeOnClick: true }).setContent(`
                <b>User:</b> ${key.userId}</br>
                <b>Lat:</b> ${key.lat}</br>
                <b>Lon:</b> ${key.lon}
            `);
                return L.marker([Number(key.lat), Number(key.lon)], { icon, title: key.userId }).bindPopup(popup);
            });
    }

    public onMapReady(map: L.Map): void {
        setTimeout(() => {
            map.invalidateSize();
        }, 0);
    }
}
