import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppMainConfigService } from '@fca-app/config/app-main-config.service';
import { TableDataListConfig } from '@fca-app/config/app-main.config';
import { UserImageModel } from '@fca-app/models/images/user-image/user-image.model';
import { FighterModel } from '@fca-app/models/users/fighter/fighter.model';
import { FightersService } from '@fca-app/services/fighters.service';
import { ImagesService } from '@fca-app/services/images.service';
import { EAdminPermissions } from '@fca-app/shared/enums/admin-permissions.enum';
import { EImageType } from '@fca-app/shared/enums/image-type.enum';
import { TableViewData } from '@fca-app/shared/interfaces/table-view-data.interface';
import { UploadedCropperImage } from '@fca-app/shared/interfaces/uploaded-cropper-image.interface';
import { untilDestroy, UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { FcaPreloadService } from '@fca-app/shared/services/fca-preload.service';
import { TableSearchParams } from '@fca-app/shared/types';
import { SaveViewFightersPage } from '@fca-app/store/actions';
import { FcaStoreService } from '@fca-app/store/store.service';
import { NzBadgeStatusType } from 'ng-zorro-antd/badge/types';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableComponent, NzTableLayout } from 'ng-zorro-antd/table';
import { NgxPermissionsService } from 'ngx-permissions';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, map, switchMap, take, tap } from 'rxjs/operators';

const EMPTY_FILE_SELECT = 0;

@UntilDestroy()
@Component({
    selector: 'app-fighter-list',
    templateUrl: './fighter-list.component.html',
    styleUrls: ['./fighter-list.component.scss'],
})
export class FighterListComponent implements AfterViewInit, OnInit {
    readonly tableLayout: NzTableLayout = 'auto';
    readonly tableListConfig: TableDataListConfig = this.configService.getConfig().fighterListConfig;

    defaultImagePlaceholder = this.appConfigService.getConfig().previewImage;
    searchQuery?: string = undefined;
    activePage: number = 1;
    cropperImage?: UserImageModel;
    imageChangedEvent: Event;
    isModalCropperVisible$ = new BehaviorSubject<boolean>(false);
    uploadTo?: FighterModel;
    permissions = EAdminPermissions;
    imageTypes = EImageType;
    imageType = EImageType.FULL_FACE_AVATAR;
    search$ = new BehaviorSubject<string | undefined>(undefined);
    fightersData$: Observable<TableViewData<FighterModel>>;

    @ViewChild('tableComponent')
    tableComponent: NzTableComponent<FighterModel[]>;
    onSearchChanged$ = new Subject<string>();

    @ViewChild('fileSelect')
    fileSelect: ElementRef;

    constructor(
        private readonly fighterService: FightersService,
        private readonly configService: AppMainConfigService,
        public readonly preloadService: FcaPreloadService,
        private readonly imagesService: ImagesService,
        private readonly storeService: FcaStoreService,
        private readonly nzNotificationService: NzNotificationService,
        private readonly appConfigService: AppMainConfigService,
        private readonly permissionsService: NgxPermissionsService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.fightersData$ = combineLatest([this.storeService.getFightersViewPage(), this.search$.asObservable()]).pipe(
            tap(([page]) => {
                this.preloadService.preload(true);
                this.activePage = page;
            }),
            map(([page, search]) => ({
                pageIndex: page,
                pageSize: this.tableListConfig.countItemsOnPage,
                filter: [],
                sort: [],
                searchQuery: search,
            })),
            switchMap((qp: TableSearchParams) => {
                return this.fighterService
                    .getFighters(qp, qp.searchQuery)
                    .pipe(tap(() => this.preloadService.preload(false)));
            })
        );
    }

    ngAfterViewInit() {
        this.onSearchChanged$
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                tap(value => this.search$.next(value)),
                untilDestroy(this)
            )
            .subscribe();
    }

    onImgClick(ev: Event) {
        ev.stopPropagation();
    }

    async onFighterDetails(fighter: FighterModel): Promise<void> {
        const hasPermission = await this.permissionsService.hasPermission(EAdminPermissions.ViewFighterDetails);

        if (hasPermission) {
            await this.router.navigate(['/dashboard/fighters', fighter.id]);
        }
    }

    onSearchChange(search: string): void {
        this.onSearchChanged$.next(search);
    }

    getNzBadgeStatus(isActive: boolean): NzBadgeStatusType {
        return isActive ? 'success' : 'processing';
    }

    onSelectFile(ev: Event): void {
        if ((ev.target as any).files.length !== EMPTY_FILE_SELECT) {
            this.imageChangedEvent = ev;
            this.isModalCropperVisible$.next(true);
        }
    }

    onCloseCropper(): void {
        this.isModalCropperVisible$.next(false);
    }

    onUploadImage(image: UploadedCropperImage): void {
        if (this.uploadTo) {
            this.preloadService.preload(true);
            this.imagesService
                .uploadImage(this.uploadTo.id, {
                    file: image.file,
                    type: image.type,
                    position: 0,
                })
                .pipe(
                    take(1),
                    finalize(() => {
                        this.preloadService.preload(false);
                        this.isModalCropperVisible$.next(false);
                    }),
                    untilDestroy(this)
                )
                .subscribe(data => {
                    this.nzNotificationService
                        .success('Success', '', { nzDuration: 1500 })
                        .onClick.pipe(take(1), untilDestroy(this))
                        .subscribe();
                    this.uploadTo!.images.splice(0, this.uploadTo!.images.length);
                    this.uploadTo!.images.push(...data);
                    const [orig] = data.filter(img => img.original);
                    if (image.type === EImageType.HALF_LENGTH_AVATAR) {
                        this.uploadTo!.halfLengthAvatar = orig;
                    } else if (image.type === EImageType.FULL_FACE_AVATAR) {
                        this.uploadTo!.fullFaceAvatar = orig;
                    }
                    this.uploadTo = undefined;
                    this.fileSelect.nativeElement.value = '';
                });
        }
    }

    public getNextPage(page: number): void {
        this.storeService.dispatch(new SaveViewFightersPage({ page }));
    }

    public formatStr(value: string | undefined): string {
        let formattedStr = '';
        if (value) {
            formattedStr =
                value.slice(this.tableListConfig.descCutPosition.from, this.tableListConfig.descCutPosition.to) + '...';
        }
        return formattedStr;
    }
}
