<app-modal-address-selection
    [modalVisible]="isModalLocationVisible"
    (addressChangedEvent)="onAddressChange($event)"
    (cancelEvent)="handleModalCancel()"
></app-modal-address-selection>

<nz-form-item>
    <nz-form-label [nzSpan]="5">Location</nz-form-label>
    <nz-form-control [nzSpan]="12">
        <nz-input-group [nzSuffix]="suffixIconSearch">
            <input
                type="text"
                [value]="selectedGeo?.formattedAddress || ''"
                (keydown)="onKeyDownLocation($event)"
                nz-input
                placeholder="Tap this field"
                (click)="openLocationSelection()"
            />
        </nz-input-group>
        <ng-template #suffixIconSearch>
            <i nz-icon nzType="global"></i>
        </ng-template>
    </nz-form-control>
</nz-form-item>

<nz-form-item>
    <nz-form-label [nzSpan]="5">First name</nz-form-label>
    <nz-form-control nzHasFeedback [nzSpan]="12" nzErrorTip="Input is required">
        <input nz-input formControlName="firstName" />
    </nz-form-control>
</nz-form-item>

<nz-form-item>
    <nz-form-label [nzSpan]="5">Last name</nz-form-label>
    <nz-form-control nzHasFeedback [nzSpan]="12" nzErrorTip="Input is required">
        <input nz-input formControlName="lastName" />
    </nz-form-control>
</nz-form-item>

<nz-form-item>
    <nz-form-label [nzSpan]="5">Name</nz-form-label>
    <nz-form-control nzHasFeedback [nzSpan]="12" nzErrorTip="Input is required">
        <input nz-input formControlName="name" />
    </nz-form-control>
</nz-form-item>

<nz-form-item>
    <nz-form-label [nzSpan]="5">Link</nz-form-label>
    <nz-form-control nzHasFeedback [nzSpan]="12" nzErrorTip="Input is required">
        <input nz-input formControlName="link" />
    </nz-form-control>
</nz-form-item>

<nz-form-item>
    <nz-form-label [nzSpan]="5">Description</nz-form-label>
    <nz-form-control nzHasFeedback [nzSpan]="12" nzErrorTip="Input is required">
        <input nz-input formControlName="description" />
    </nz-form-control>
</nz-form-item>
