import { EVideoStatus } from '@fca-app/api/fca/arenas/interfaces/response/video-api.response';
import { ELocale } from '@fca-app/shared/enums/locale.enum';

export interface VideoModelData {
    id: number;
    name: string;
    status: EVideoStatus;
    streamUrl: string | null;
    videoUrl: string | null;
    languages: ELocale[] | null;
}

export interface VideoModel extends VideoModelData {}

export class VideoModel {}
