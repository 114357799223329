import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'time' })
export class TimePipe implements PipeTransform {
    transform(value: number, args?: any): string {
        const milliseconds = value * 1000;
        let seconds = Math.floor(milliseconds / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);

        seconds = seconds % 60;
        minutes = minutes % 60;
        hours = hours % 24;

        return `${this.padTo2Digits(hours)}h ${this.padTo2Digits(minutes)}m ${this.padTo2Digits(seconds)}s`;
    }

    private padTo2Digits(value: number) {
        return value.toString().padStart(2, '0');
    }
}
