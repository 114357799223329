import { UserModel, UserModelData } from '@fca-app/models/users/user/user.model';

export interface FanModelData extends UserModelData {
    fullname: string;
    nickname: string;
    firstName: string;
    lastName: string;
    created: Date;
    lastVisit: Date;
}

export interface FanModel extends FanModelData {}

export class FanModel extends UserModel {}
