<div
    style="padding-left: 10px; padding-right: 10px; padding-bottom: 10px; margin-bottom: 10px; background-color: #e6f7ff00; padding-top: 10px;"
>
    <div style="display: flex; padding-top: 10px;">
        <h4>History analytics</h4>
        <div class="tag-table">Week over week percentages</div>
    </div>
    <nz-table
        nzShowSizeChanger
        [nzData]="dataStatisticsKickTableService.listOfData"
        [nzFrontPagination]="false"
        [nzLoading]="dataStatisticsKickTableService.isTableLoading | async"
        [nzTotal]="(dataStatisticsKickTableService.total | async) ?? 0"
        [nzPageSize]="pageSize"
        [nzPageIndex]="pageIndex"
        (nzPageIndexChange)="
            queriesStatisticsKickTableService.changeTablePagination$.next({ limit: pageSize, page: $event });
            pageIndex = $event
        "
        (nzPageSizeChange)="
            queriesStatisticsKickTableService.changeTablePagination$.next({ limit: $event, page: pageIndex });
            pageSize = $event
        "
    >
        <thead>
            <tr>
                <th nzWidth="60px"></th>
                <th
                    nzCustomFilter
                    *ngFor="let column of listOfColumns"
                    [nzWidth]="column.width"
                    [nzSortOrder]="column.sortOrder"
                    [nzSortFn]="column.sortFn"
                    [nzShowFilter]="column.showFilter"
                    [nzShowSort]="column.showSort"
                    [nzSortDirections]="column.sortDirections"
                    [nzFilterMultiple]="column.filterMultiple"
                    [nzFilters]="column.listOfFilter"
                    [nzFilterFn]="column.filterFn"
                    (nzSortOrderChange)="
                        column.sortOrderName &&
                            column.sortOrderChange(column.sortOrderName, $event, listOfColumns, pageIndex, pageSize)
                    "
                >
                    {{ column.name }}
                    <nz-filter-trigger
                        *ngIf="column.filterTrigger && column.filterTrigger.dropDownMenu?.nativeElement"
                        [(nzVisible)]="column.filterTrigger.isVisible"
                        [nzActive]="column.filterTrigger.isActive"
                        [nzDropdownMenu]="column.filterTrigger.dropDownMenu.nativeElement"
                    >
                        <span nz-icon nzType="search"></span>
                    </nz-filter-trigger>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of dataStatisticsKickTableService.listOfData">
                <td>
                    <img
                        [id]="data.id + '_displayed'"
                        *ngIf="data?.thumbnailHref"
                        nz-image
                        width="54px"
                        height="96px"
                        onerror="this.src='assets/images/no-video.png'"
                        [nzSrc]="data!.thumbnailHref!"
                        alt=""
                    />
                    <img
                        [id]="data.id + '_hidden'"
                        *ngIf="!data?.thumbnailHref"
                        oner
                        nz-image
                        width="54px"
                        height="96px"
                        [nzSrc]="'assets/images/no-video.png'"
                        alt=""
                    />
                    <video
                        hidden
                        [id]="data.id + '_video'"
                        (loadeddata)="videoLoaded(data)"
                        autoplay
                        loop
                        width="54px"
                        height="96px"
                    >
                        <source
                            (error)="videoDontLoaded($event, data)"
                            type="video/mp4"
                            [src]="data.href.replace('m3u8', 'mp4').replace('video', 'default')"
                        />
                    </video>
                </td>
                <td>{{ data.description }}</td>
                <td>{{ data.duration }} sec</td>
                <td>
                    <nz-statistic
                        [nzValue]="data.views.total"
                        [nzSuffix]="' '"
                        [nzValueStyle]="{ 'font-size': '14px' }"
                    ></nz-statistic>
                    <!--    Больше в процентах-->
                    <nz-statistic
                        *ngIf="data.views.toDay > data.views.minusDay"
                        [nzValue]="
                            (((data.views.toDay - data.views.minusDay) / data.views.toDay) * 100 | number: '1.0-2')!
                        "
                        [nzPrefix]="prefixUp"
                        [nzSuffix]="'%)'"
                        [nzValueStyle]="{ color: '#3F8600', 'font-size': '14px' }"
                    ></nz-statistic>
                    <!--    Равно в процентах-->
                    <nz-statistic
                        *ngIf="data.views.toDay === data.views.minusDay"
                        [nzValue]="(0 | number: '1.0-2')!"
                        [nzPrefix]="prefixMiddle"
                        [nzSuffix]="'%)'"
                        [nzValueStyle]="{ color: '#faad14', 'font-size': '14px' }"
                    ></nz-statistic>
                    <!--    Меньше в процентах-->
                    <nz-statistic
                        *ngIf="data.views.toDay < data.views.minusDay"
                        [nzValue]="
                            -(((data.views.minusDay - data.views.toDay) / data.views.minusDay) * 100 | number: '1.0-2')!
                        "
                        [nzPrefix]="prefixDown"
                        [nzSuffix]="'%)'"
                        [nzValueStyle]="{ color: '#cf1322', 'font-size': '14px' }"
                    ></nz-statistic>
                    <ng-template #prefixUp>
                        <span nz-icon nzType="arrow-up" class="staticon">(</span>
                    </ng-template>
                    <ng-template #prefixDown>
                        <span nz-icon nzType="arrow-down" class="staticon">(</span>
                    </ng-template>
                    <ng-template #prefixMiddle>
                        <span nz-icon nzType="minus" class="staticon">(</span>
                    </ng-template>
                </td>
                <td>{{ data.viewed | time }}</td>
                <td>{{ data.downloaded | bites }}</td>
                <td>
                    <nz-statistic
                        [nzValue]="data.unique.total"
                        [nzSuffix]="' '"
                        [nzValueStyle]="{ 'font-size': '14px' }"
                    ></nz-statistic>
                    <!--    Больше в процентах-->
                    <nz-statistic
                        *ngIf="data.unique.toDay > data.unique.minusDay"
                        [nzValue]="
                            (((data.unique.toDay - data.unique.minusDay) / data.unique.toDay) * 100 | number: '1.0-2')!
                        "
                        [nzPrefix]="prefixUp"
                        [nzSuffix]="'%)'"
                        [nzValueStyle]="{ color: '#3F8600', 'font-size': '14px' }"
                    ></nz-statistic>
                    <!--    Равно в процентах-->
                    <nz-statistic
                        *ngIf="data.unique.toDay === data.unique.minusDay"
                        [nzValue]="(0 | number: '1.0-2')!"
                        [nzPrefix]="prefixMiddle"
                        [nzSuffix]="'%)'"
                        [nzValueStyle]="{ color: '#faad14', 'font-size': '14px' }"
                    ></nz-statistic>
                    <!--    Меньше в процентах-->
                    <nz-statistic
                        *ngIf="data.unique.toDay < data.unique.minusDay"
                        [nzValue]="
                            -(((data.views.minusDay - data.views.toDay) / data.views.minusDay) * 100 | number: '1.0-2')!
                        "
                        [nzPrefix]="prefixDown"
                        [nzSuffix]="'%)'"
                        [nzValueStyle]="{ color: '#cf1322', 'font-size': '14px' }"
                    ></nz-statistic>
                    <ng-template #prefixUp>
                        <span nz-icon nzType="arrow-up" class="staticon">(</span>
                    </ng-template>
                    <ng-template #prefixDown>
                        <span nz-icon nzType="arrow-down" class="staticon">(</span>
                    </ng-template>
                    <ng-template #prefixMiddle>
                        <span nz-icon nzType="minus" class="staticon">(</span>
                    </ng-template>
                </td>
                <td>
                    <nz-statistic
                        [nzValue]="data.likes.total"
                        [nzSuffix]="' '"
                        [nzValueStyle]="{ 'font-size': '14px' }"
                    ></nz-statistic>
                    <!--    Больше в процентах-->
                    <nz-statistic
                        *ngIf="data.likes.toDay > data.likes.minusDay"
                        [nzValue]="
                            (((data.likes.toDay - data.likes.minusDay) / data.likes.toDay) * 100 | number: '1.0-2')!
                        "
                        [nzPrefix]="prefixUp"
                        [nzSuffix]="'%)'"
                        [nzValueStyle]="{ color: '#3F8600', 'font-size': '14px' }"
                    ></nz-statistic>
                    <!--    Равно в процентах-->
                    <nz-statistic
                        *ngIf="data.likes.toDay === data.likes.minusDay"
                        [nzValue]="(0 | number: '1.0-2')!"
                        [nzPrefix]="prefixMiddle"
                        [nzSuffix]="'%)'"
                        [nzValueStyle]="{ color: '#faad14', 'font-size': '14px' }"
                    ></nz-statistic>
                    <!--    Меньше в процентах-->
                    <nz-statistic
                        *ngIf="data.likes.toDay < data.likes.minusDay"
                        [nzValue]="
                            -(((data.views.minusDay - data.views.toDay) / data.views.minusDay) * 100 | number: '1.0-2')!
                        "
                        [nzPrefix]="prefixDown"
                        [nzSuffix]="'%)'"
                        [nzValueStyle]="{ color: '#cf1322', 'font-size': '14px' }"
                    ></nz-statistic>
                    <ng-template #prefixUp>
                        <span nz-icon nzType="arrow-up" class="staticon">(</span>
                    </ng-template>
                    <ng-template #prefixDown>
                        <span nz-icon nzType="arrow-down" class="staticon">(</span>
                    </ng-template>
                    <ng-template #prefixMiddle>
                        <span nz-icon nzType="minus" class="staticon">(</span>
                    </ng-template>
                </td>
                <td>
                    <nz-statistic
                        [nzValue]="data.comments.total"
                        [nzSuffix]="' '"
                        [nzValueStyle]="{ 'font-size': '14px' }"
                    ></nz-statistic>
                    <!--    Больше в процентах-->
                    <nz-statistic
                        *ngIf="data.comments.toDay > data.comments.minusDay"
                        [nzValue]="
                            (((data.comments.toDay - data.comments.minusDay) / data.comments.toDay) * 100
                                | number: '1.0-2')!
                        "
                        [nzPrefix]="prefixUp"
                        [nzSuffix]="'%)'"
                        [nzValueStyle]="{ color: '#3F8600', 'font-size': '14px' }"
                    ></nz-statistic>
                    <!--    Равно в процентах-->
                    <nz-statistic
                        *ngIf="data.comments.toDay === data.comments.minusDay"
                        [nzValue]="(0 | number: '1.0-2')!"
                        [nzPrefix]="prefixMiddle"
                        [nzSuffix]="'%)'"
                        [nzValueStyle]="{ color: '#faad14', 'font-size': '14px' }"
                    ></nz-statistic>
                    <!--    Меньше в процентах-->
                    <nz-statistic
                        *ngIf="data.comments.toDay < data.comments.minusDay"
                        [nzValue]="
                            -(((data.views.minusDay - data.views.toDay) / data.views.minusDay) * 100 | number: '1.0-2')!
                        "
                        [nzPrefix]="prefixDown"
                        [nzSuffix]="'%)'"
                        [nzValueStyle]="{ color: '#cf1322', 'font-size': '14px' }"
                    ></nz-statistic>
                    <ng-template #prefixUp>
                        <span nz-icon nzType="arrow-up" class="staticon">(</span>
                    </ng-template>
                    <ng-template #prefixDown>
                        <span nz-icon nzType="arrow-down" class="staticon">(</span>
                    </ng-template>
                    <ng-template #prefixMiddle>
                        <span nz-icon nzType="minus" class="staticon">(</span>
                    </ng-template>
                </td>
                <td>{{ data.user.firstName }} {{ data.user.lastName }}</td>
                <td>{{data.tags ?? '-'}}</td>
                <td>{{ data.create_date | date: 'medium' }}</td>
            </tr>
        </tbody>
    </nz-table>
    <nz-dropdown-menu #userSearch="nzDropdownMenu">
        <div class="ant-table-filter-dropdown">
            <div class="search-box">
                <input
                    type="text"
                    nz-input
                    placeholder="Search user"
                    [ngModel]="userSearchStatisticsKickTableService.query$ | async"
                    (ngModelChange)="userSearchStatisticsKickTableService.query$.next($event)"
                />
                <button
                    nz-button
                    nzSize="small"
                    nzType="primary"
                    (click)="userSearchStatisticsKickTableService.search$.next()"
                    class="search-button"
                >
                    Search
                </button>
                <button nz-button nzSize="small" (click)="userSearchStatisticsKickTableService.reset$.next()">
                    Reset
                </button>
            </div>
        </div>
    </nz-dropdown-menu>
</div>
