import {
    FightEventConferenceModel,
    FightEventConferenceModelData,
} from '@fca-app/models/users/arena/fight-event/fight-event-conference/fight-event-conference.model';
import { ModelFactory } from '@fca-app/shared/abstract/model-factory.abstract';

export class FightEventConferenceFactory extends ModelFactory<
    FightEventConferenceModelData,
    FightEventConferenceModel
> {
    protected getInstance(): FightEventConferenceModel {
        return new FightEventConferenceModel();
    }
}
