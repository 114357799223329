import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FighterDetailsMatchingComponent } from '@fca-app/dashboard/components/home/fighter-list/fighter-details/fighter-details-matching/fighter-details-matching.component';
import { FighterDetailsComponent } from '@fca-app/dashboard/components/home/fighter-list/fighter-details/fighter-details.component';
import { FighterListComponent } from '@fca-app/dashboard/components/home/fighter-list/fighter-list.component';
import { UserDetailsAccountInfoModule } from '@fca-app/dashboard/components/home/shared/modules/user-details-account-info/user-details-account-info.module';
import { SharedModule } from '@fca-app/shared/shared.module';

@NgModule({
    declarations: [FighterListComponent, FighterDetailsComponent, FighterDetailsMatchingComponent],
    imports: [UserDetailsAccountInfoModule, FormsModule, SharedModule, RouterModule, ReactiveFormsModule],
    providers: [],
    exports: [FighterListComponent],
})
export class FightersModule {}
