import { Component } from '@angular/core';
import { UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';

@UntilDestroy()
@Component({
    selector: 'statistics-video',
    templateUrl: './statistics-video.component.html',
    styleUrls: ['./statistics-video.component.scss'],
})
export class StatisticsVideoComponent {}
