import { Component } from '@angular/core';
import { UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';

@UntilDestroy()
@Component({
    selector: 'statistics-kick',
    templateUrl: './statistics-kick.component.html',
    styleUrls: ['./statistics-kick.component.scss'],
})
export class StatisticsKickComponent {}
