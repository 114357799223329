import { Injectable } from '@angular/core';
import { SaveBannerParams } from '@fca-app/api/fca/banners/interfaces/params/save-banner.params';
import { BannerDataResponse } from '@fca-app/api/fca/banners/interfaces/response/banner-data.response';
import { FcaApiServiceBase } from '@fca-app/api/fca/fca-api-service-base.service';
import { Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

@Injectable()
export class FcaBannersApiService extends FcaApiServiceBase {
    getBanners(): Observable<{ [key: string]: BannerDataResponse[] }> {
        const url = [this.apiUrl, 'banners', 'admin'].join('/');

        return this.http
            .get<{ [key: string]: BannerDataResponse[] }>(url, { headers: this.defaultHeaders })
            .pipe(publishReplay(1), refCount());
    }

    deleteBanner(id: string): Observable<void> {
        const url = [this.apiUrl, 'banners', 'admin', id].join('/');

        return this.http
            .delete<void>(url, { headers: this.defaultHeaders })
            .pipe(publishReplay(1), refCount());
    }

    saveBanner(params: SaveBannerParams): Observable<BannerDataResponse> {
        const url = [this.apiUrl, 'banners', 'admin', 'save'].join('/');
        const formData: FormData = new FormData();
        formData.append('openNewTab', String(params.openNewTab));
        formData.append('position', String(params.position));
        formData.append('title', String(params.title));
        formData.append('text', String(params.text));
        formData.append('btnText', String(params.btnText));
        formData.append('locale', String(params.locale));
        formData.append('platform', String(params.platform));
        formData.append('purpose', String(params.purpose));
        if (params.name) {
            formData.append('name', String(params.name));
        }

        if (params.image) {
            formData.append('image', params.image, params.image.name);
        }

        if (params.id) {
            formData.append('id', String(params.id));
        }

        if (params.url) {
            formData.append('url', String(params.url));
        }

        return this.http.post<BannerDataResponse>(url, formData).pipe(publishReplay(1), refCount());
    }
}
