import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    NavigationExtras,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { AuthService } from '@fca-app/auth/services/auth.service';
import { EUserRoleName } from '@fca-app/enums/user-role-names.enum';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class IsAuthenticatedGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {}

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.authService.currentUser$.pipe(
            map(user => Boolean(user?.role === EUserRoleName.ADMIN)),
            map((isLoggedIn: boolean) => {
                if (isLoggedIn) {
                    return true;
                }
                const extras: NavigationExtras = {
                    queryParams: {
                        redirectUrl: state.url,
                    },
                };
                this.router.navigate(['/login'], extras);
                return false;
            }),
            catchError(err => {
                console.log(err);
                return throwError(err);
            })
        );
    }
}
