<div>
    <nz-form-item>
        <nz-form-control>
            <nz-input-group>
                <label class="form-label">Name, surname or nick</label>
                <input
                    type="text"
                    nz-input
                    [(ngModel)]="request.search"
                    (ngModelChange)="searchChange$.next(true)"
                    placeholder="Type anything..."
                />
            </nz-input-group>
        </nz-form-control>
    </nz-form-item>
</div>
<div>
    <nz-form-item>
        <label class="form-label">Fight event</label>
        <nz-select
            nzMode="multiple"
            nzPlaceHolder="Select events"
            nzAllowClear
            nzShowSearch
            nzServerSearch
            class="event-select"
            [(ngModel)]="request.fightEvents"
            (nzOnSearch)="onSearch($event)"
            (ngModelChange)="searchChange$.next(true)"
        >
            <ng-container *ngFor="let o of optionList$ | async">
                <nz-option *ngIf="!isTextSearchLoading" [nzValue]="o.id" [nzLabel]="o.name"></nz-option>
            </ng-container>
            <nz-option *ngIf="isTextSearchLoading" nzDisabled nzCustomContent>
                <span nz-icon nzType="loading" class="loading-icon"></span>
                Loading Data...
            </nz-option>
        </nz-select>
    </nz-form-item>
</div>
<div>
    <nz-form-item>
        <label class="form-label">Matchmaking status</label>
        <nz-select
            nzMode="multiple"
            nzPlaceHolder="Select statuses..."
            [(ngModel)]="request.matchmakingStatuses"
            (ngModelChange)="searchChange$.next(true)"
        >
            <nz-option
                *ngFor="let option of matchmakingStatusOptions"
                [nzLabel]="option.key"
                [nzValue]="option.value"
                [nzHide]="!isNotSelectedMatchmakingStatus(option.value)"
            ></nz-option>
        </nz-select>
    </nz-form-item>
</div>
<div>
    <nz-form-item>
        <label class="form-label">Gender</label>
        <nz-select
            nzMode="multiple"
            nzPlaceHolder="Select gender..."
            [(ngModel)]="request.gender"
            (ngModelChange)="searchChange$.next(true)"
        >
            <nz-option
                *ngFor="let option of fightersGender"
                [nzLabel]="option.key"
                [nzValue]="option.value"
                [nzHide]="!isNotSelectedGender(option.value)"
            ></nz-option>
        </nz-select>
    </nz-form-item>
</div>
<div>
    <nz-form-item>
        <label class="form-label">Weighting</label>
        <nz-select
            nzMode="multiple"
            nzPlaceHolder="Select fighter weighting..."
            [(ngModel)]="request.weightings"
            (ngModelChange)="searchChange$.next(true)"
        >
            <nz-option
                *ngFor="let option of fightersWeight"
                [nzLabel]="option.key"
                [nzValue]="option.value"
                [nzHide]="!isNotSelectedWeighting(option.value)"
            ></nz-option>
        </nz-select>
    </nz-form-item>
</div>
<div class="pt-2">
    <label nz-checkbox [(ngModel)]="request.withPhone" (ngModelChange)="searchChange$.next(true)">
        <span>Only with mobile number</span>
    </label>
</div>
