import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { ListVideosMetricsParams } from '@fca-app/api/videos/interfaces/params/list-metrics.params';
import { untilDestroy, UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { Observable, Subject } from 'rxjs';
import { filter, mergeMap, take, tap } from 'rxjs/operators';
import { EOrderStatisticsVideo } from '../enums/order.statistics-video.enum';
import { IColumnItemStatisticsVideoTable } from '../interfaces/column-item.statistics-video-table.interface';
import { IQueryParamsStatisticsVideoTable } from '../interfaces/query-params.statistics-video-table.interface';
import { DataStatisticsVideoTableService } from './data.statistics-video-table.service';
import { SearchStatisticsVideoTableService } from './search.statistics-video-table.service';

@Injectable()
@UntilDestroy()
export class QueriesStatisticsVideoTableService {
    private defaultParams: Params = {
        description: 'null',
        user: 'null',
        order: 'UPLOADED',
        orderBy: 'ASC',
        page: 1,
        limit: 10,
    };

    private params: ListVideosMetricsParams = {
        description: null,
        user: null,
        order: EOrderStatisticsVideo.UPLOADED,
        orderBy: 'DESC',
        page: 1,
        limit: 10,
    };

    public readonly changeOrderParams$ = new Subject<{
        type: EOrderStatisticsVideo;
        directions: string | null;
        table: IColumnItemStatisticsVideoTable[];
        page: number;
        limit: number;
    }>();

    public readonly changeTablePagination$ = new Subject<{
        page: number;
        limit: number;
    }>();

    private readonly initParams$ = new Subject<undefined>();

    private readonly routeChangedPipe = this.activatedRoute.queryParams
        .pipe(
            untilDestroy(this),
            filter(_ => Boolean(Object.keys(_).length)),
            tap(_ => this.dataStatisticsVideoTableService.getData$.next(this.params))
        )
        .subscribe();

    private readonly iniParamsPipe = this.initParams$
        .pipe(
            take(1),
            untilDestroy(this),
            mergeMap(_ => this.activatedRoute.queryParams as Observable<IQueryParamsStatisticsVideoTable>),
            filter(params =>
                Boolean(
                    !params?.page &&
                        !params?.limit &&
                        !params?.order &&
                        !params?.user &&
                        !params?.description &&
                        !params?.orderBy
                )
            ),
            tap(_ =>
                this.router.navigate([], {
                    relativeTo: this.activatedRoute,
                    queryParams: this.defaultParams,
                    queryParamsHandling: 'merge',
                })
            )
        )
        .subscribe();

    private readonly changeOrderParamsPipe = this.changeOrderParams$
        .pipe(
            untilDestroy(this),
            tap(key => (key.directions === 'descend' ? (key.directions = 'DESC') : key)),
            tap(key => (key.directions === 'ascend' ? (key.directions = 'ASC') : key)),
            tap(key => (key.directions !== 'DESC' && key.directions !== 'ASC' ? (key.directions = null) : key)),
            tap(key =>
                key.type !== EOrderStatisticsVideo.UPLOADED
                    ? (key.table
                          .filter(_ => _.sortOrderName === EOrderStatisticsVideo.UPLOADED)
                          .pop()!.sortOrder = null)
                    : undefined
            ),
            tap(key => {
                if (!key.directions) {
                    key.type = EOrderStatisticsVideo.UPLOADED;
                    key.table.filter(_ => _.sortOrderName === EOrderStatisticsVideo.UPLOADED).pop()!.sortOrder =
                        'descend';
                }
            }),
            tap(key => (!key.directions ? (key.directions = 'DESC') : key)),
            tap(key => (this.defaultParams.order = key.type)),
            tap(key => {
                this.params.order = key.type;
                this.params.orderBy = key.directions as 'ASC' | 'DESC';
                this.params.page = 1;
                this.params.limit = key.limit;
                this.router.navigate([], {
                    relativeTo: this.activatedRoute,
                    queryParams: this.params,
                    queryParamsHandling: 'merge',
                });
            })
        )
        .subscribe();

    private readonly changeTablePaginationPipe = this.changeTablePagination$
        .pipe(
            untilDestroy(this),
            tap(key => {
                this.params.page = key.page;
                this.params.limit = key.limit;
                this.router.navigate([], {
                    relativeTo: this.activatedRoute,
                    queryParams: this.params,
                    queryParamsHandling: 'merge',
                });
            }),
            tap(() => this.dataStatisticsVideoTableService.getData$.next(this.params))
        )
        .subscribe();

    constructor(
        private readonly searchStatisticsVideoTableService: SearchStatisticsVideoTableService,
        private readonly dataStatisticsVideoTableService: DataStatisticsVideoTableService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly router: Router
    ) {
        this.initParams$.next();
    }

    public changeOrderParams(type: EOrderStatisticsVideo, directions: string | null): void {}
}
