<nz-list class="demo-loadmore-list" [nzLoading]="preloadService.isLoad$ | async">
    <nz-list-item *ngFor="let item of list">
        <ng-container>
            <nz-list-item-meta [nzDescription]="item.status">
                <nz-list-item-meta-title>
                    <a href="https://ng.ant.design">{{ item.event.name }} ({{ item.event.status }})</a>
                </nz-list-item-meta-title>
            </nz-list-item-meta>
            <!--      content-->
            <ul *ngIf="item.canEdit()" nz-list-item-actions>
                <nz-list-item-action><a>edit</a></nz-list-item-action>
                <nz-list-item-action><a>more</a></nz-list-item-action>
            </ul>
        </ng-container>
        <!--    <nz-skeleton-->
        <!--      *ngIf="item.loading"-->
        <!--      [nzAvatar]="true"-->
        <!--      [nzActive]="true"-->
        <!--      [nzTitle]="false"-->
        <!--      [nzLoading]="true"-->
        <!--    ></nz-skeleton>-->
    </nz-list-item>
    <!--  <div class="loadmore" nz-list-load-more>-->
    <!--    <button nz-button *ngIf="!loadingMore" (click)="onLoadMore()">loading more</button>-->
    <!--  </div>-->
</nz-list>
