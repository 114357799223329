import { Component, OnInit } from '@angular/core';
import { FcaKicksApiService } from '@fca-app/api/kicks/fca-kicks-api.service';
import { UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { Color } from '@swimlane/ngx-charts';
import { ScaleType } from '@swimlane/ngx-charts/lib/common/types/scale-type.enum';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'statistics-kick-chart',
    templateUrl: './statistics-kick-chart.component.html',
    styleUrls: ['./statistics-kick-chart.component.scss'],
})
export class StatisticsKickChartComponent implements OnInit {
    public colorScheme: Color = {
        name: 't',
        selectable: true,
        group: 'ordinal' as ScaleType,
        domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA'],
    };

    public views$ = new BehaviorSubject<{ series: any[]; name: string }[]>([{ name: 'Views', series: [] }]);
    public comments$ = new BehaviorSubject<{ series: any[]; name: string }[]>([{ name: 'Comments', series: [] }]);
    public likes$ = new BehaviorSubject<{ series: any[]; name: string }[]>([{ name: 'Likes', series: [] }]);
    public videos$ = new BehaviorSubject<{ series: any[]; name: string }[]>([{ name: 'Videos', series: [] }]);

    constructor(private readonly fcaKicksApiService: FcaKicksApiService) {}

    public async ngOnInit(): Promise<void> {
        const firstRequest = this.fcaKicksApiService
            .getLineChart()
            .pipe(
                map(data => data.map(key => ({ ...key, date: key.date.toString().split('T')[0] }))),
                tap(data => {
                    this.views$.next([
                        {
                            name: 'Views',
                            series: data.map(key => ({ value: Number(key.views), name: key.date })),
                        },
                    ]);
                    this.comments$.next([
                        {
                            name: 'Comments',
                            series: data.map(key => ({ value: Number(key.comments), name: key.date })),
                        },
                    ]);
                    this.likes$.next([
                        {
                            name: 'Likes',
                            series: data.map(key => ({ value: Number(key.likes), name: key.date })),
                        },
                    ]);
                    this.videos$.next([
                        {
                            name: 'Videos',
                            series: data.map(key => ({ value: Number(key.videos), name: key.date })),
                        },
                    ]);
                })
            )
            .subscribe(() => firstRequest.unsubscribe());
    }
}
