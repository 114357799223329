import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { StatisticsBstComponent } from '@fca-app/dashboard/components/statistics/statistics-bst/statistics-bst.component';
import { StatisticsFeedbacksComponent } from '@fca-app/dashboard/components/statistics/statistics-feedbacks/statistics-feedbacks.component';
import { StatisticsKickChartComponent } from '@fca-app/dashboard/components/statistics/statistics-kick/statistics-kick-chart/statistics-kick-chart.component';
import { StatisticsKickInfoComponent } from '@fca-app/dashboard/components/statistics/statistics-kick/statistics-kick-info/statistics-kick-info.component';
import { StatisticsKickMapsComponent } from '@fca-app/dashboard/components/statistics/statistics-kick/statistics-kick-maps/statistics-kick-maps.component';
import { StatisticsKickQueryComponent } from '@fca-app/dashboard/components/statistics/statistics-kick/statistics-kick-query/statistics-kick-query.component';
import { StatisticsKickRealtimeComponent } from '@fca-app/dashboard/components/statistics/statistics-kick/statistics-kick-realtime/statistics-kick-realtime.component';
import { StatisticsKickTableComponent } from '@fca-app/dashboard/components/statistics/statistics-kick/statistics-kick-table/statistics-kick-table.component';
import { StatisticsKickUsersVideosComponent } from '@fca-app/dashboard/components/statistics/statistics-kick/statistics-kick-users-videos/statistics-kick-users-videos.component';
import { StatisticsKickComponent } from '@fca-app/dashboard/components/statistics/statistics-kick/statistics-kick.component';
import { BasicStatisticsStreamComponent } from '@fca-app/dashboard/components/statistics/statistics-stream/basic-statistics-stream/basic-statistics-stream.component';
import { MapsStatisticsStreamComponent } from '@fca-app/dashboard/components/statistics/statistics-stream/maps-statistics-stream/maps-statistics-stream.component';
import { OnlineGraphStatisticsStreamComponent } from '@fca-app/dashboard/components/statistics/statistics-stream/online-graph-statistics-stream/online-graph-statistics-stream.component';
import { RealtimeStatisticsStreamComponent } from '@fca-app/dashboard/components/statistics/statistics-stream/realtime-statistics-stream/realtime-statistics-stream.component';
import { StatisticsStreamComponent } from '@fca-app/dashboard/components/statistics/statistics-stream/statistics-stream.component';
import { TitleStatisticsStreamComponent } from '@fca-app/dashboard/components/statistics/statistics-stream/title-statistics-stream/title-statistics-stream.component';
import { StatisticsUsersComponent } from '@fca-app/dashboard/components/statistics/statistics-users/statistics-users.component';
import { StatisticsVideoChartComponent } from '@fca-app/dashboard/components/statistics/statistics-video/statistics-video-chart/statistics-video-chart.component';
import { StatisticsVideoInfoComponent } from '@fca-app/dashboard/components/statistics/statistics-video/statistics-video-info/statistics-video-info.component';
import { StatisticsVideoMapsComponent } from '@fca-app/dashboard/components/statistics/statistics-video/statistics-video-maps/statistics-video-maps.component';
import { StatisticsVideoQueryComponent } from '@fca-app/dashboard/components/statistics/statistics-video/statistics-video-query/statistics-video-query.component';
import { StatisticsVideoRealtimeComponent } from '@fca-app/dashboard/components/statistics/statistics-video/statistics-video-realtime/statistics-video-realtime.component';
import { StatisticsVideoTableComponent } from '@fca-app/dashboard/components/statistics/statistics-video/statistics-video-table/statistics-video-table.component';
import { StatisticsVideoUsersVideosComponent } from '@fca-app/dashboard/components/statistics/statistics-video/statistics-video-users-videos/statistics-video-users-videos.component';
import { StatisticsVideoComponent } from '@fca-app/dashboard/components/statistics/statistics-video/statistics-video.component';
import { DashboardStatisticsComponent } from '@fca-app/dashboard/components/statistics/statistics.component';
import { SharedModule } from '@fca-app/shared/shared.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';

@NgModule({
    declarations: [
        DashboardStatisticsComponent,
        StatisticsUsersComponent,
        StatisticsStreamComponent,
        StatisticsFeedbacksComponent,
        BasicStatisticsStreamComponent,
        TitleStatisticsStreamComponent,
        OnlineGraphStatisticsStreamComponent,
        MapsStatisticsStreamComponent,
        RealtimeStatisticsStreamComponent,
        StatisticsKickComponent,
        StatisticsVideoComponent,
        StatisticsBstComponent,
        StatisticsKickTableComponent,
        StatisticsKickQueryComponent,
        StatisticsKickInfoComponent,
        StatisticsKickRealtimeComponent,
        StatisticsKickMapsComponent,
        StatisticsKickUsersVideosComponent,
        StatisticsKickChartComponent,
        StatisticsVideoChartComponent,
        StatisticsVideoInfoComponent,
        StatisticsVideoMapsComponent,
        StatisticsVideoQueryComponent,
        StatisticsVideoRealtimeComponent,
        StatisticsVideoTableComponent,
        StatisticsVideoUsersVideosComponent,
    ],
    imports: [
        FormsModule,
        SharedModule,
        RouterModule,
        ReactiveFormsModule,
        LeafletModule,
        LeafletMarkerClusterModule,
        NgxChartsModule,
    ],
    providers: [],
    exports: [DashboardStatisticsComponent],
})
export class DashboardStatisticsModule {}
