import { Injectable } from '@angular/core';
import { AuthData } from '@fca-app/auth/interfaces/tokens-data.interface';
import { Action, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, publishReplay, refCount } from 'rxjs/operators';
import * as FcaSelectors from './selectors';
import { FcaState } from './state';

@Injectable()
export class FcaStoreService {
    constructor(private store: Store<FcaState>) {}

    public getAuthData(): Observable<AuthData | null> {
        return this.store.pipe(
            select(FcaSelectors.getAuthData),
            // @ts-ignore
            distinctUntilChanged(null, val => JSON.stringify(val)),
            publishReplay(1),
            refCount()
        );
    }

    public getArenasViewPage(): Observable<number> {
        return this.store.pipe(
            select(FcaSelectors.currentViewArenasPage),
            // @ts-ignore
            distinctUntilChanged(null, val => JSON.stringify(val)),
            publishReplay(1),
            refCount()
        );
    }

    getFightersViewPage(): Observable<number> {
        return this.store.pipe(
            select(FcaSelectors.currentViewFightersPage),
            // @ts-ignore
            distinctUntilChanged(null, val => JSON.stringify(val)),
            publishReplay(1),
            refCount()
        );
    }

    getFansViewPage(): Observable<number> {
        return this.store.pipe(
            select(FcaSelectors.currentViewFansPage),
            // @ts-ignore
            distinctUntilChanged(null, val => JSON.stringify(val)),
            publishReplay(1),
            refCount()
        );
    }

    dispatch<V extends Action = Action>(action: V) {
        return this.store.dispatch(action as any);
    }
}
