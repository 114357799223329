import { Component } from '@angular/core';
import { AdminModel } from '@fca-app/models/users/admin/admin.model';
import { AdminsService } from '@fca-app/services/admins.service';
import { FcaPreloadService } from '@fca-app/shared/services/fca-preload.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-dashboard-manage-admins',
    templateUrl: './manage-admins.component.html',
    styleUrls: ['./manage-admins.component.scss'],
})
export class ManageAdminsComponent {
    adminsList$: Observable<AdminModel[]>;

    constructor(public readonly preloadService: FcaPreloadService, private readonly adminsService: AdminsService) {
        this.adminsList$ = this.adminsService.getAdminsList();
    }
}
