<nz-layout>
    <nz-content>
        <div class="content">
            <form class="create-user-form" nz-form [formGroup]="form">
                <nz-form-item>
                    <nz-form-control [nzOffset]="5">
                        <button nz-button nz-dropdown [nzDropdownMenu]="menu">
                            {{ selectedRole }}
                            <i nz-icon nzType="down"></i>
                        </button>
                        <nz-dropdown-menu #menu="nzDropdownMenu">
                            <ul nz-menu>
                                <li nz-menu-item *ngFor="let role of roles" (click)="onSelectRole(role)">{{ role }}</li>
                            </ul>
                        </nz-dropdown-menu>
                    </nz-form-control>
                </nz-form-item>
                <!--        <nz-form-item>-->
                <!--          <nz-form-label [nzSpan]="5">Phone</nz-form-label>-->
                <!--          <nz-form-control nzHasFeedback [nzSpan]="12" nzErrorTip="Input is required">-->
                <!--            <input nz-input formControlName="phone" />-->
                <!--          </nz-form-control>-->
                <!--        </nz-form-item>-->
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Email</nz-form-label>
                    <nz-form-control nzHasFeedback [nzSpan]="12" nzErrorTip="Input is required">
                        <input nz-input formControlName="email" />
                    </nz-form-control>
                </nz-form-item>

                <ng-container [ngSwitch]="selectedRole">
                    <users-create-fighter *ngSwitchCase="availableRoles.FIGHTER"></users-create-fighter>
                    <users-create-fan *ngSwitchCase="availableRoles.FAN"></users-create-fan>
                    <users-create-location-provider
                        *ngSwitchCase="availableRoles.LOCATION_PROVIDER"
                    ></users-create-location-provider>
                </ng-container>

                <nz-form-item>
                    <nz-form-control [nzOffset]="10">
                        <button nz-button nzType="primary" [disabled]="!form.valid" (click)="onCreate()">Create</button>
                    </nz-form-control>
                </nz-form-item>
                <!--  <nz-form-item>-->
                <!--    <nz-form-label [nzSpan]="5">Last name</nz-form-label>-->
                <!--    <nz-form-control nzHasFeedback [nzSpan]="12" nzErrorTip="MaxLength is 6">-->
                <!--      <input nz-input [ngModel]="'MaxLength is 6'" name="maxlength" maxlength="6" />-->
                <!--    </nz-form-control>-->
                <!--  </nz-form-item>-->
                <!--  <nz-form-item>-->
                <!--    <nz-form-label [nzSpan]="5">MinLength</nz-form-label>-->
                <!--    <nz-form-control nzHasFeedback [nzSpan]="12" nzErrorTip="MinLength is 6">-->
                <!--      <input nz-input [ngModel]="'MinLength is 6'" name="minlength" minlength="6" />-->
                <!--    </nz-form-control>-->
                <!--  </nz-form-item>-->
                <!--  <nz-form-item>-->
                <!--    <nz-form-label [nzSpan]="5">Email</nz-form-label>-->
                <!--    <nz-form-control nzHasFeedback [nzSpan]="12" nzErrorTip="Email is not valid">-->
                <!--      <input nz-input [ngModel]="'Input Email'" name="email" email />-->
                <!--    </nz-form-control>-->
                <!--  </nz-form-item>-->
                <!--  <nz-form-item>-->
                <!--    <nz-form-label [nzSpan]="5">Pattern</nz-form-label>-->
                <!--    <nz-form-control nzHasFeedback [nzSpan]="12" nzErrorTip="Pattern not match">-->
                <!--      <input nz-input [ngModel]="'Match pattern'" name="pattern" pattern=".{3,}" />-->
                <!--    </nz-form-control>-->
                <!--  </nz-form-item>-->
                <!--  <nz-form-item>-->
                <!--    <nz-form-label [nzSpan]="5">Mix</nz-form-label>-->
                <!--    <nz-form-control nzHasFeedback [nzSpan]="12" [nzErrorTip]="combineTpl">-->
                <!--      <input-->
                <!--        nz-input-->
                <!--        [ngModel]="'MaxLength is 12 and MinLength is 6'"-->
                <!--        name="mix"-->
                <!--        minlength="6"-->
                <!--        maxlength="12"-->
                <!--        required-->
                <!--      />-->
                <!--      <ng-template #combineTpl let-control>-->
                <!--        <ng-container *ngIf="control.hasError('maxlength')">MaxLength is 12</ng-container>-->
                <!--        <ng-container *ngIf="control.hasError('minlength')">MinLength is 6</ng-container>-->
                <!--        <ng-container *ngIf="control.hasError('required')">Input is required</ng-container>-->
                <!--      </ng-template>-->
                <!--    </nz-form-control>-->
                <!--  </nz-form-item>-->
            </form>
        </div>
    </nz-content>
</nz-layout>
