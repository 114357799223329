import { Injectable, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroy, UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
@UntilDestroy()
export class SearchStatisticsVideoTableService {
    public search$ = new BehaviorSubject(undefined);
    private searchPipe = this.search$.pipe(untilDestroy(this)).subscribe();

    private setQuery(): void {}

    constructor(private route: ActivatedRoute) {}
}
