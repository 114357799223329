import { Component, Input, OnInit } from '@angular/core';
import { AdminModel } from '@fca-app/models/users/admin/admin.model';
import { AdminsService } from '@fca-app/services/admins.service';
import { EAdminPermissions } from '@fca-app/shared/enums/admin-permissions.enum';
import { UntilDestroy, untilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'manage-admins-item',
    templateUrl: './manage-admins-item.component.html',
    styleUrls: ['./manage-admins-item.component.scss'],
})
export class ManageAdminsItemComponent implements OnInit {
    @Input() admin: AdminModel;
    listOfSelectedValues: EAdminPermissions[] = [];
    listOfAvailableValues = Object.values(EAdminPermissions).sort((a, b) => a.localeCompare(b));

    constructor(
        private readonly adminsService: AdminsService,
        private readonly nzNotificationService: NzNotificationService
    ) {}

    ngOnInit(): void {
        this.listOfSelectedValues = this.admin.permissions;
    }

    onSavePermissions(): void {
        this.adminsService
            .updateAdmin(this.admin.id, this.listOfSelectedValues)
            .pipe(take(1), untilDestroy(this))
            .subscribe(() => {
                this.nzNotificationService.success('Success', '', { nzDuration: 1500 });
            });
    }

    get canSave(): boolean {
        return (
            this.listOfSelectedValues.length > 0 &&
            JSON.stringify(this.admin.permissions) !== JSON.stringify(this.listOfSelectedValues)
        );
    }
}
