import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'bites' })
export class BitesPipe implements PipeTransform {
    transform(value: number, args?: any): string {
        if (value / (1024 * 1024) < 1000) {
            return (value / (1000 * 1000)).toFixed(2) + ' Mb';
        } else if (value / (1000 * 1000) > 1000 && value / (1000 * 1000) < 1000000) {
            return (value / (1000 * 1000) / 1000).toFixed(2) + ' GB';
        } else if (value / (1000 * 1000) > 1000000) {
            return (value / (1000 * 1000) / 1000 / 1000).toFixed(2) + ' Tb';
        }
        return '-';
    }
}
