import { Injectable } from '@angular/core';
import { FcaBannersApiService } from '@fca-app/api/fca/banners/fca-banners-api.service';
import { BannerSlideItemFactory } from '@fca-app/models/banners/banner-slide-item.factory';
import { BannerSlideItemMapper } from '@fca-app/models/banners/banner-slide-item.mapper';
import { BannerSlideItemModel, BannerSlideItemModelData } from '@fca-app/models/banners/banner-slide-item.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface FormBannerParams extends BannerSlideItemModelData {
    purpose: string;
}

@Injectable()
export class BannersService {
    constructor(private readonly bannersService: FcaBannersApiService) {}

    getBanners(): Observable<{ [key: string]: BannerSlideItemModel[] }> {
        return this.bannersService.getBanners().pipe(
            map(rawData => {
                return Object.entries(rawData).reduce<{ [key: string]: BannerSlideItemModel[] }>((acc, [key, data]) => {
                    acc[key] = data.map(raw =>
                        new BannerSlideItemFactory().getModelFromData(new BannerSlideItemMapper().mapData(raw))
                    );
                    return acc;
                }, {});
            })
        );
    }

    deleteBanner(id: string): Observable<void> {
        return this.bannersService.deleteBanner(id);
    }

    saveBanner(params: FormBannerParams, image: File | null): Observable<BannerSlideItemModel> {
        return this.bannersService
            .saveBanner({
                id: params.id,
                image,
                openNewTab: params.needToOpenNewTab,
                url: params.destinationUrl ?? null,
                position: params.position,
                title: params.title,
                text: params.content,
                btnText: params.btnText,
                locale: params.locale,
                platform: params.platform,
                purpose: params.purpose,
                name: params.name,
            })
            .pipe(map(raw => new BannerSlideItemFactory().getModelFromData(new BannerSlideItemMapper().mapData(raw))));
    }
}
