import { Component } from '@angular/core';
import { FcaKicksApiService } from '@fca-app/api/kicks/fca-kicks-api.service';
import { KickRealtimeMetricsApiResponse } from '@fca-app/api/kicks/interfaces/response/kick-api.response';
import { untilDestroy, UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { BehaviorSubject, interval } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'statistics-kick-realtime',
    templateUrl: './statistics-kick-realtime.component.html',
    styleUrls: ['./statistics-kick-realtime.component.scss'],
})
export class StatisticsKickRealtimeComponent {
    public realtime$ = new BehaviorSubject<KickRealtimeMetricsApiResponse>({
        mostPopularCountry: '-',
        mostPopularPlatform: '-',
        users: 0,
    });
    private refresher = interval(2 * 1000)
        .pipe(
            untilDestroy(this),
            mergeMap(() => this.fcaKicksApiService.realtime().pipe(take(1)))
        )
        .subscribe(data => this.realtime$.next(data));

    constructor(private readonly fcaKicksApiService: FcaKicksApiService) {}
}
