import { Component, OnInit } from '@angular/core';
import { FcaKicksApiService } from '@fca-app/api/kicks/fca-kicks-api.service';
import { KickTop5UserResponse, KickTop5VideoResponse } from '@fca-app/api/kicks/interfaces/response/kick-api.response';
import { UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'statistics-kick-users-videos',
    templateUrl: './statistics-kick-users-videos.component.html',
    styleUrls: ['./statistics-kick-users-videos.component.scss'],
    providers: [],
})
export class StatisticsKickUsersVideosComponent implements OnInit {
    public videosData: KickTop5VideoResponse[] = [];
    public userDate: KickTop5UserResponse[] = [];

    constructor(private readonly fcaKicksApiService: FcaKicksApiService) {}

    public ngOnInit(): void {
        const firstReq = this.fcaKicksApiService
            .getTop5UsersAndVideos()
            .pipe(
                tap(key => {
                    this.videosData = key.videos;
                    this.userDate = key.users;
                })
            )
            .subscribe(() => firstReq.unsubscribe());
    }
}
