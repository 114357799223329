<div>
    <nz-row [nzGutter]="16">
        <nz-col [nzSpan]="4">
            <nz-statistic [nzValue]="balance" [nzTitle]="'Account Balance'"></nz-statistic>
            <nz-statistic
                [nzValue]="booleanToString(user.account!.withdrawAvailable)"
                [nzTitle]="'Withdraw available'"
            ></nz-statistic>
        </nz-col>
        <nz-col [nzSpan]="12">
            <nz-timeline nzMode="alternate" class="transactions-timeline">
                <ng-container *ngFor="let transaction of user.account!.transactions">
                    <ng-container [ngSwitch]="transaction.type">
                        <nz-timeline-item
                            [nzLabel]="getTransactionDate(transaction)"
                            *ngSwitchCase="'money'"
                            nzPosition="left"
                            [nzDot]="dotMoneyTemplate"
                        >
                            <b>{{ getItemText(transaction) }} ${{ prepareMoney(transaction.amount) }}</b>
                        </nz-timeline-item>
                        <nz-timeline-item
                            [nzLabel]="getTransactionDate(transaction)"
                            *ngSwitchCase="'withdraw'"
                            nzPosition="right"
                            [nzDot]="
                                transactionIsPending(transaction)
                                    ? dotPendingTemplate
                                    : transactionIsWithdrawn(transaction)
                                    ? dotCompletedTemplate
                                    : undefined
                            "
                        >
                            <b>{{ getItemText(transaction) }} ${{ prepareMoney(transaction.amount) }}</b>
                            <ul class="withdrawals-list">
                                <li *ngFor="let historyItem of transaction.history">
                                    ${{ prepareMoney(historyItem.amount) }}
                                </li>
                            </ul>
                            <ng-container *ngxPermissionsOnly="[permissions.CompleteFighterPaymentRequest]">
                                <button
                                    *ngIf="transactionIsPending(transaction)"
                                    nz-button
                                    nzType="primary"
                                    [nzLoading]="preloadService.isLoad$ | async"
                                    (click)="onConfirmPaymentClick()"
                                >
                                    Confirm payment
                                </button>
                            </ng-container>
                        </nz-timeline-item>
                    </ng-container>
                </ng-container>
            </nz-timeline>
        </nz-col>
    </nz-row>
    <ng-template #dotPendingTemplate>
        <span [nzSpin]="true" nz-icon nzType="clock-circle-o" style="font-size: 16px;"></span>
    </ng-template>
    <ng-template #dotCompletedTemplate>
        <span nz-icon nzType="check-circle-o" style="font-size: 16px; color: #52c41a;"></span>
    </ng-template>
    <ng-template #dotMoneyTemplate>
        <span nz-icon nzType="dollar-circle-o" style="font-size: 16px; color: #52c41a;"></span>
    </ng-template>
</div>
