import { AfterContentInit, Component, Input, OnInit } from '@angular/core';
import { EMatchingStatus } from '@fca-app/api/fca/matchmaking/interfaces/response/fighter-matchmaking-request-api.response';
import { EFighterWeight } from '@fca-app/enums/fighter-weight.enum';
import { EUserGender } from '@fca-app/enums/gender.enum';
import { MatchmakingRequestModel } from '@fca-app/models/matchmaking/matchmaking-request.model';
import { MatchmakingService } from '@fca-app/services/matchmaking.service';
import { untilDestroy, UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import { catchError, debounceTime, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';

export interface IMatchmakingSearchRequest {
    matchmakingStatuses: EMatchingStatus[];
    search?: string | undefined;
    gender: EUserGender[];
    weightings: EFighterWeight[];
    withPhone: boolean;
    fightEvents: string[];
}

@UntilDestroy()
@Component({
    selector: 'app-matchmaking-search',
    templateUrl: './search-matchmaking.component.html',
    styleUrls: ['./search-matchmaking.component.scss'],
})
export class SearchMatchmakingComponent implements OnInit, AfterContentInit {
    public isTextSearchLoading: boolean = false;

    @Input() public isLoading$: BehaviorSubject<boolean>;
    @Input() public searchResultList$: BehaviorSubject<MatchmakingRequestModel[]>;

    public textSearchChange$ = new BehaviorSubject('');
    public searchChange$ = new Subject<boolean>();
    matchmakingStatusOptions: { key: string; value: EMatchingStatus }[] = [
        {
            key: 'Matched',
            value: EMatchingStatus.MATCHED,
        },
        {
            key: 'Pending',
            value: EMatchingStatus.PENDING,
        },
        {
            key: 'Canceled',
            value: EMatchingStatus.CANCELED,
        },
        {
            key: 'Expired',
            value: EMatchingStatus.EXPIRED,
        },
    ];
    fightersGender: { key: string; value: EUserGender }[] = [
        {
            key: 'Male',
            value: EUserGender.MALE,
        },
        {
            key: 'Female',
            value: EUserGender.FEMALE,
        },
    ];
    fightersWeight: { key: string; value: EFighterWeight }[] = [
        {
            key: 'Flyweight',
            value: EFighterWeight.FLYWEIGHT,
        },
        {
            key: 'Strawweight',
            value: EFighterWeight.STRAWWEIGHT,
        },
        {
            key: 'Feather Weight',
            value: EFighterWeight.FEATHERWEIGHT,
        },
        {
            key: 'Bantam Weight',
            value: EFighterWeight.BANTAMWEIGHT,
        },
        {
            key: 'Heavy Weight',
            value: EFighterWeight.HEAVYWEIGHT,
        },
        {
            key: 'Light Weight',
            value: EFighterWeight.LIGHTWEIGHT,
        },
        {
            key: 'Welter Weight',
            value: EFighterWeight.WELTERWEIGHT,
        },
        {
            key: 'Middle Weight',
            value: EFighterWeight.MIDDLEWEIGHT,
        },
        {
            key: 'Light Heavy Weight',
            value: EFighterWeight.LIGHTHEAVYWEIGHT,
        },
    ];
    request: IMatchmakingSearchRequest = {
        matchmakingStatuses: [EMatchingStatus.PENDING],
        search: undefined,
        gender: [EUserGender.MALE],
        weightings: [],
        withPhone: false,
        fightEvents: [],
    };

    public optionList$: Observable<{ name: string; id: number }[] | null> = this.textSearchChange$.asObservable().pipe(
        untilDestroy(this),
        debounceTime(500),
        mergeMap(data =>
            this.fcaMatchmakingService.getFightEvents(data).pipe(tap(_ => (this.isTextSearchLoading = false)))
        )
    );

    constructor(private fcaMatchmakingService: MatchmakingService) {}

    public isNotSelectedMatchmakingStatus(value: EMatchingStatus): boolean {
        return this.request.matchmakingStatuses.indexOf(value) === -1;
    }

    public isNotSelectedGender(value: EUserGender): boolean {
        return this.request.gender.indexOf(value) === -1;
    }

    public isNotSelectedWeighting(value: EFighterWeight): boolean {
        return this.request.weightings.indexOf(value) === -1;
    }

    public onSearch(value: string): void {
        this.isTextSearchLoading = true;
        this.textSearchChange$.next(value);
    }

    public ngOnInit(): void {
        this.searchChange$
            .pipe(
                untilDestroy(this),
                map(_ => this.prepareRequest()),
                filter((req: IMatchmakingSearchRequest) => req.matchmakingStatuses.length > 0),
                tap(_ => this.isLoading$.next(true)),
                mergeMap(_ => this.fcaMatchmakingService.getMatchmakingList(_)),
                tap(_ => this.searchResultList$.next(_)),
                tap(_ => this.isLoading$.next(false))
            )
            .subscribe();
    }

    private prepareRequest(): IMatchmakingSearchRequest {
        return {
            fightEvents: this.request.fightEvents,
            gender: this.request.gender,
            matchmakingStatuses: this.request.matchmakingStatuses,
            search: this.request.search?.length ? this.request.search : undefined,
            weightings: this.request.weightings,
            withPhone: this.request.withPhone,
        };
    }

    public ngAfterContentInit(): void {
        this.searchChange$.next(true);
    }
}
