<div>
    <div *ngIf="!fightEvent.isPPV">
        <ng-container *ngIf="canAddMorePrices">
            <nz-select [(ngModel)]="selectedCurrency">
                <nz-option
                    *ngFor="let currency of availableCurrencies"
                    [nzValue]="currency"
                    [nzLabel]="currency"
                ></nz-option>
            </nz-select>
            <form style="margin:0;" nz-form [formGroup]="ppvValidateForm">
                <nz-form-item>
                    <nz-form-label [nzSpan]="6" nzFor="price">Price</nz-form-label>
                    <nz-form-control nzErrorTip="Price is required">
                        <nz-input-group>
                            <input type="number" nz-input formControlName="price" id="price" placeholder="PPV price" />
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6" nzFor="discountPrice">Discount price</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <input
                                type="number"
                                nz-input
                                formControlName="discountPrice"
                                id="discountPrice"
                                placeholder="PPV discount price"
                            />
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6" nzFor="sale">Sale %</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <input type="number" nz-input formControlName="sale" id="sale" placeholder="Sale %" />
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <button
                    nz-button
                    [disabled]="!ppvValidateForm.valid || !canAddToList"
                    [nzType]="'primary'"
                    (click)="onAddToList()"
                >
                    Add
                </button>
            </form>
        </ng-container>
        <ul nz-list nzBordered nzSize="large">
            <li nz-list-item *ngFor="let item of pricesToSave" nzNoFlex>
                <nz-list nzBordered nzSize="small">
                    <nz-list-item>Currency: {{ item.currency }}</nz-list-item>
                    <nz-list-item>Price: {{ item.price / 100 }}</nz-list-item>
                    <nz-list-item *ngIf="item.discountPrice"
                        >Discount price: {{ item.discountPrice / 100 }}</nz-list-item
                    >
                    <nz-list-item *ngIf="item.sale">Sale %: {{ item.sale }}</nz-list-item>
                    <button nz-button nzType="default" nzDanger (click)="onDeletePreSavePrice(item)">Delete</button>
                </nz-list>
            </li>
        </ul>
        <button [disabled]="canAddMorePrices || !canConvertToPpv" nz-button nzType="primary" (click)="onConvertClick()">
            Convert to PPV
        </button>
    </div>
</div>
