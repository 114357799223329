import { EUserRoleName } from '@fca-app/enums/user-role-names.enum';
import { UserAccountInfoModel } from '@fca-app/models/users/user/account-info/user-account-info.model';

export interface UserModelData {
    id: number;
    phoneNumber?: string;
    email: string;
    role: EUserRoleName;
    country: string;
    account?: UserAccountInfoModel;
}

export interface UserModel extends UserModelData {}

export class UserModel {}
