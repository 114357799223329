import { EMatchingStatus } from '@fca-app/api/fca/matchmaking/interfaces/response/fighter-matchmaking-request-api.response';
import { EventStatus } from '@fca-app/enums/event-status.enum';
import { FightEventModel } from '@fca-app/models/users/arena/fight-event/fight-event.model';
import { FighterModel } from '@fca-app/models/users/fighter/fighter.model';

export interface MatchmakingRequestModelData {
    id: number;
    status: EMatchingStatus;
    fighter?: FighterModel;
    fighterId: number;
    eventId: string;
    event: FightEventModel;
}

export interface MatchmakingRequestModel extends MatchmakingRequestModelData {}

export class MatchmakingRequestModel {
    canEdit(): boolean {
        return this.event.status === EventStatus.Upcoming;
    }
}
