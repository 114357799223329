import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { ICities, ICountries, IQualitiesDB } from '@fca-app/api/video-metrics/video.metrics.interface';
import { VideoMetricsService } from '@fca-app/services/video-metrics.service';
import { untilDestroy, UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { Color } from '@swimlane/ngx-charts';
import { ScaleType } from '@swimlane/ngx-charts/lib/common/types/scale-type.enum';
import * as L from 'leaflet';
import { latLng, tileLayer } from 'leaflet';
import 'leaflet.markercluster';
import { BehaviorSubject } from 'rxjs';
import { mergeMap, take, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'maps-statistics-stream',
    templateUrl: './maps-statistics-stream.component.html',
    styleUrls: ['./maps-statistics-stream.component.scss'],
})
export class MapsStatisticsStreamComponent implements OnInit {
    @Input() public eventBus = new EventEmitter<{ date: number[]; timeStepSelected: string; videoId: string | null }>();
    public readonly leafletOptions: { layers: L.TileLayer[]; zoom: number; center: L.LatLng } = {
        layers: [tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })],
        zoom: 2,
        center: latLng(0, 0),
    };

    public leafletMarkers: L.Marker[] = [];
    public leafletMarkerClusterOptions: L.MarkerClusterGroupOptions = {
        spiderfyOnMaxZoom: false,
        showCoverageOnHover: false,
        zoomToBoundsOnClick: false,
        disableClusteringAtZoom: 5,
    };

    public readonly nzChartsColorScheme: Color = {
        name: 't',
        selectable: true,
        group: 'ordinal' as ScaleType,
        domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA'],
    };

    public results$ = new BehaviorSubject<ICountries[] | ICities[] | IQualitiesDB[]>([] as ICountries[]);
    public countries: ICountries[] = [];
    public cities: ICities[] = [];
    public qualities: IQualitiesDB[] = [];
    public selected: 'Counties' | 'Cities' | 'Qualities' = 'Counties';

    constructor(private readonly videoMetricsService: VideoMetricsService) {}

    ngOnInit(): void {
        this.eventBus
            .pipe(
                untilDestroy(this),
                mergeMap(data => this.videoMetricsService.getMapData(data).pipe(take(1))),
                tap(data => (this.leafletMarkers = this.videoMetricsService.getLeafletMarkers(data.coords))),
                tap(data => {
                    this.countries = data.countries;
                    this.cities = data.cities;
                    this.qualities = data.qualities;
                })
            )
            .subscribe(_ => {
                if (this.selected === 'Counties') {
                    this.results$.next(this.countries);
                }
                if (this.selected === 'Cities') {
                    this.results$.next(this.cities);
                }
                if (this.selected === 'Qualities') {
                    this.results$.next(this.qualities);
                }
            });
    }

    public onMapReady(map: L.Map): void {
        setTimeout(() => {
            map.invalidateSize();
        }, 0);
    }
}
