<div style="padding-left: 10px; padding-right: 10px; padding-bottom: 10px">
    <div style="display: flex; padding-bottom: 10px">
        <h4>Chart</h4>
        <div class="tag-table">Chart about views, comments and uploaded videos (last 6 month)</div>
    </div>
    <nz-row [nzGutter]="12">
        <nz-col
            [nzXs]="{ span: 24, offset: 0 }"
            [nzSm]="{ span: 24, offset: 0 }"
            [nzMd]="{ span: 24, offset: 0 }"
            [nzLg]="{ span: 24, offset: 0 }"
            [nzXl]="{ span: 24, offset: 0 }"
        >
            <nz-card [nzBordered]="false" nzTitle="Views" [nzBodyStyle]="{ padding: '0px' }">
                <ngx-charts-line-chart
                    #chartComponent
                    [scheme]="colorScheme"
                    [legend]="false"
                    [showXAxisLabel]="true"
                    [showYAxisLabel]="true"
                    [xAxis]="true"
                    [yAxis]="true"
                    [xAxisLabel]="'Date'"
                    [yAxisLabel]="'Views'"
                    [timeline]="true"
                    [results]="views$ | async"
                >
                </ngx-charts-line-chart>
            </nz-card>
        </nz-col>
    </nz-row>
    <nz-row [nzGutter]="12">
        <nz-col
            [nzXs]="{ span: 24, offset: 0 }"
            [nzSm]="{ span: 24, offset: 0 }"
            [nzMd]="{ span: 24, offset: 0 }"
            [nzLg]="{ span: 24, offset: 0 }"
            [nzXl]="{ span: 24, offset: 0 }"
        >
            <nz-card [nzBordered]="false" nzTitle="Comments" [nzBodyStyle]="{ padding: '0px' }">
                <ngx-charts-line-chart
                    #chartComponent
                    [scheme]="colorScheme"
                    [legend]="false"
                    [showXAxisLabel]="true"
                    [showYAxisLabel]="true"
                    [xAxis]="true"
                    [yAxis]="true"
                    [xAxisLabel]="'Date'"
                    [yAxisLabel]="'Comments'"
                    [timeline]="true"
                    [results]="comments$ | async"
                >
                </ngx-charts-line-chart>
            </nz-card>
        </nz-col>
    </nz-row>
    <nz-row [nzGutter]="12">
        <nz-col
            [nzXs]="{ span: 24, offset: 0 }"
            [nzSm]="{ span: 24, offset: 0 }"
            [nzMd]="{ span: 24, offset: 0 }"
            [nzLg]="{ span: 24, offset: 0 }"
            [nzXl]="{ span: 24, offset: 0 }"
        >
            <nz-card [nzBordered]="false" nzTitle="Videos" [nzBodyStyle]="{ padding: '0px' }">
                <ngx-charts-line-chart
                    #chartComponent
                    [scheme]="colorScheme"
                    [legend]="false"
                    [showXAxisLabel]="true"
                    [showYAxisLabel]="true"
                    [xAxis]="true"
                    [yAxis]="true"
                    [xAxisLabel]="'Date'"
                    [yAxisLabel]="'Videos'"
                    [timeline]="true"
                    [results]="videos$ | async"
                >
                </ngx-charts-line-chart>
            </nz-card>
        </nz-col>
    </nz-row>
</div>
