import { createSelector } from '@ngrx/store';
import { FcaState } from './state';

export const selectFcaFeature = (state: any): FcaState => state.fca;

export const getAuthData = createSelector(selectFcaFeature, state => state.authData);

export const currentViewArenasPage = createSelector(selectFcaFeature, state => state.viewArenasPage);

export const currentViewFightersPage = createSelector(selectFcaFeature, state => state.viewFightersPage);
export const currentViewFansPage = createSelector(selectFcaFeature, state => state.viewFansPage);
