import { Injectable } from '@angular/core';
import { FcaWebHooksApiService } from '@fca-app/api/fca/web-hooks/fca-web-hooks-api.service';
import { FightEventStatus, FightEventType } from '@fca-app/api/fca/web-hooks/interfaces/params/start-stop-event.params';
import { ELocale } from '@fca-app/shared/enums/locale.enum';
import { Observable } from 'rxjs';

@Injectable()
export class WebHooksService {
    constructor(private webHooksService: FcaWebHooksApiService) {}

    startEvent(
        fightEventId: string,
        status: FightEventStatus,
        streamUrl: string,
        type: FightEventType,
        languages: ELocale[]
    ): Observable<void> {
        return this.webHooksService.startStopEvent(fightEventId, {
            status,
            type,
            urls: { stream: streamUrl, video: null },
            languages,
        });
    }

    stopEvent(
        fightEventId: string,
        status: FightEventStatus,
        videoUrl: string,
        type: FightEventType,
        languages: ELocale[]
    ): Observable<void> {
        return this.webHooksService.startStopEvent(fightEventId, {
            status,
            type,
            urls: { stream: null, video: videoUrl },
            languages,
        });
    }
}
