import { HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RefreshTokenResponse } from '@fca-app/api/fca/auth/interfaces/response/refresh-token.response';
import { AuthData } from '@fca-app/auth/interfaces/tokens-data.interface';
import { SaveAuthDataAction } from '@fca-app/store/actions';
import { FcaStoreService } from '@fca-app/store/store.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { of, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from '../../auth/services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        private readonly authService: AuthService,
        private readonly storeService: FcaStoreService,
        private readonly permissionsService: NgxPermissionsService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): any {
        return this.storeService.getAuthData().pipe(
            tap(data => {
                if (data?.permissions) {
                    this.permissionsService.loadPermissions(data.permissions);
                }
            }),
            map(tokens => {
                return request.clone({
                    setHeaders: { Authorization: request.headers.get('authorization') || `Bearer ${tokens?.jwt}` },
                });
            }),
            switchMap((req: HttpRequest<any>) => {
                return this.isRefreshRequest(req)
                    ? next.handle(req)
                    : next.handle(req).pipe(
                          catchError(err => {
                              if ([HttpStatusCode.Forbidden, HttpStatusCode.Unauthorized].includes(err.status)) {
                                  return this.storeService.getAuthData().pipe(
                                      take(1),
                                      switchMap((authData: AuthData | null) => {
                                          return !authData
                                              ? of(null).pipe(
                                                    tap(() => {
                                                        this.authService.logout();
                                                    })
                                                )
                                              : this.authService.getNewTokens().pipe(
                                                    switchMap((tokens: RefreshTokenResponse) => {
                                                        this.storeService.dispatch(
                                                            new SaveAuthDataAction({
                                                                data: {
                                                                    ...authData,
                                                                    ...tokens,
                                                                    email: authData?.email || '',
                                                                },
                                                            })
                                                        );
                                                        return next.handle(
                                                            request.clone({
                                                                setHeaders: {
                                                                    Authorization: `Bearer ${tokens?.refreshToken ||
                                                                        ''}`,
                                                                },
                                                            })
                                                        );
                                                    })
                                                );
                                      }),
                                      catchError(() => {
                                          this.authService.logout();
                                          return of(null);
                                      })
                                  );
                              }
                              return throwError(err);
                          })
                      );
            })
        );
    }

    private isRefreshRequest(request: HttpRequest<any>): boolean {
        return request.url.indexOf('auth/refresh') >= 0;
    }
}
