import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FcaKicksApiService } from '@fca-app/api/kicks/fca-kicks-api.service';
import { KicksMetricsListApiResponse } from '@fca-app/api/kicks/interfaces/response/kick-api.response';
import { EOrderStatisticsKick } from '@fca-app/dashboard/components/statistics/statistics-kick/statistics-kick-table/enums/order.statistics-kick.enum';
import { SearchStatisticsKickTableService } from '@fca-app/dashboard/components/statistics/statistics-kick/statistics-kick-table/services/search.statistics-kick-table.service';
import { untilDestroy, UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

@Injectable()
@UntilDestroy()
export class DataStatisticsKickTableService {
    public listOfData: KicksMetricsListApiResponse[] = [];
    public readonly isTableLoading = new BehaviorSubject<boolean>(true);
    public readonly total = new BehaviorSubject<number>(0);
    public fileExists = new Map<string, boolean>();

    public fileExists$ = new Subject<string>();

    public getData$ = new Subject<{
        description: string | null;
        user: number | null;
        order: EOrderStatisticsKick;
        orderBy: 'ASC' | 'DESC';
        page: number;
        limit: number;
    }>();

    private readonly fileExistsSub = this.fileExists$
        .pipe(
            untilDestroy(this),
            mergeMap(key =>
                this.http
                    .head(key, {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                        },
                    })
                    .pipe(
                        catchError(_ => of(false)),
                        map(_ => ({ key, data: !!_ }))
                    )
            ),
            tap(key => this.fileExists.set(key.key, key.data))
        )
        .subscribe();

    private readonly getDataPipe = this.getData$
        .pipe(
            untilDestroy(this),
            tap(() => this.isTableLoading.next(true)),
            map(_ => ({
                ..._,
                page: _.page - 1,
            })),
            mergeMap(_ => this.fcaKicksApiService.listMetrics(_)),
            tap(_ => this.total.next(_.total)),
            tap(_ => (this.listOfData = _.data)),
            tap(_ => _.data.forEach(key => this.fileExists$.next(key.href.replace('video.m3u8', 'default.mp4')))),
            tap(_ => _.data.forEach(key => this.fileExists$.next(key.thumbnailHref))),
            tap(() => this.isTableLoading.next(false))
        )
        .subscribe();

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly searchStatisticsKickTableService: SearchStatisticsKickTableService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private fcaKicksApiService: FcaKicksApiService,
        private readonly http: HttpClient
    ) {}
}
