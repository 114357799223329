<nz-modal
    [nzVisible]="isVisible"
    [nzBodyStyle]="{ height: '500px' }"
    nzWidth="50%"
    [nzTitle]="title"
    [nzFooter]="null"
    (nzOnCancel)="handleCancel()"
    (nzOnOk)="handleOk()"
>
    <ng-container *nzModalContent>
        <nz-carousel [nzDotPosition]="'bottom'" [nzEnableSwipe]="false">
            <div nz-carousel-content *ngFor="let imgSource of imgSources">
                <img loading="lazy" [src]="imgSource.href" style="object-fit: contain;" width="100%" height="100%" />
            </div>
        </nz-carousel>
    </ng-container>
</nz-modal>
