<div *ngIf="isLoading$ | async" class="spin-loader">
    <nz-spin nzSimple></nz-spin>
</div>
<div *ngIf="!(isLoading$ | async)" class="list">
    <nz-card *ngFor="let req of list" [nzBodyStyle]="bodyStyle">
        <div class="ribbon bg-red">id: {{ req.fighter!.id }}</div>
        <div class="row row-0">
            <div class="col-3">
                <img
                    style="padding-right: 0;"
                    [src]="fighterImage(req.fighter!.images)"
                    class="w-100 h-100 object-cover card-img-start"
                />
            </div>
            <div class="col" style="padding-left: 0;">
                <div class="card-body">
                    <h5 class="card-title">
                        {{ req.fighter!.firstName }}
                        {{ req.fighter!.nickname ? '"' + req.fighter!.nickname + '"' : '' }}
                        {{ req.fighter!.lastName }} <small>({{ req.event.name }})</small>
                    </h5>
                    <p class="text-muted">
                        Status: <b>{{ req.status }}</b>
                    </p>
                    <p class="text-muted">
                        Description:
                        <b
                            >{{ req.fighter!.details.gender }}, {{ req.fighter!.details.weight }},
                            {{ age(req.fighter!.details.birthdate) }}</b
                        >
                        y.o.
                    </p>
                    <p class="text-muted">
                        Location: <b>{{ req.fighter!.residence?.formattedAddress }}</b>
                    </p>
                    <p *ngIf="req.fighter!.details.club" class="text-muted">
                        Camp: <b>{{ req.fighter!.details.club }}</b>
                        {{ req.fighter!.details.coaches ? '(' + req.fighter!.details.coaches + ')' : '' }}
                    </p>
                    <p *ngIf="req.fighter!.email" class="text-muted">
                        Email: <b>{{ req.fighter!.email }}</b>
                    </p>
                    <p *ngIf="req.fighter!.phone" class="text-muted">
                        Mobile: <b>+{{ req.fighter!.phone }}</b>
                    </p>
                    <a
                        *ngIf="instagramExists(req.fighter!.socials)"
                        [href]="getInstagramLink(req.fighter!.socials)"
                        target="_blank"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-brand-instagram"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <rect x="4" y="4" width="16" height="16" rx="4"></rect>
                            <circle cx="12" cy="12" r="3"></circle>
                            <line x1="16.5" y1="7.5" x2="16.5" y2="7.501"></line>
                        </svg>
                    </a>

                    <a
                        *ngIf="twitterExists(req.fighter!.socials)"
                        [href]="getTwitterLink(req.fighter!.socials)"
                        target="_blank"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-brand-twitter"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path
                                d="M22 4.01c-1 .49 -1.98 .689 -3 .99c-1.121 -1.265 -2.783 -1.335 -4.38 -.737s-2.643 2.06 -2.62 3.737v1c-3.245 .083 -6.135 -1.395 -8 -4c0 0 -4.182 7.433 4 11c-1.872 1.247 -3.739 2.088 -6 2c3.308 1.803 6.913 2.423 10.034 1.517c3.58 -1.04 6.522 -3.723 7.651 -7.742a13.84 13.84 0 0 0 .497 -3.753c-.002 -.249 1.51 -2.772 1.818 -4.013z"
                            ></path>
                        </svg>
                    </a>

                    <a
                        *ngIf="facebookExists(req.fighter!.socials)"
                        [href]="getFacebookLink(req.fighter!.socials)"
                        target="_blank"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-brand-facebook"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"></path>
                        </svg>
                    </a>

                    <a
                        *ngIf="sherdogExists(req.fighter!.socials)"
                        [href]="getSherdogLink(req.fighter!.socials)"
                        target="_blank"
                    >
                        <img width="24" height="24" src="https://www.sherdog.com/img/new/dog_footer.svg" />
                    </a>
                </div>
            </div>
            <div class="col-3" class="drop-item">
                <a nz-dropdown [nzDropdownMenu]="menu">
                    More
                    <span nz-icon nzType="down"></span>
                </a>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu nzSelectable>
                        <li nz-menu-item nzDanger (click)="onCancelClick(req)">Cancel</li>
                        <li nz-menu-item (click)="onMatchClick(req)">Match</li>
                    </ul>
                </nz-dropdown-menu>
            </div>
        </div>
    </nz-card>
    <nz-empty *ngIf="list.length === 0"></nz-empty>
</div>
