import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UserDetailsAccountInfoComponent } from '@fca-app/dashboard/components/home/shared/modules/user-details-account-info/user-details-account-info.component';
import { SharedModule } from '@fca-app/shared/shared.module';

@NgModule({
    declarations: [UserDetailsAccountInfoComponent],
    imports: [FormsModule, SharedModule, RouterModule],
    providers: [],
    exports: [UserDetailsAccountInfoComponent],
})
export class UserDetailsAccountInfoModule {}
