import {
    UserAccountInfoModel,
    UserAccountInfoModelData,
} from '@fca-app/models/users/user/account-info/user-account-info.model';
import { ModelFactory } from '@fca-app/shared/abstract/model-factory.abstract';

export class UserAccountInfoFactory extends ModelFactory<UserAccountInfoModelData, UserAccountInfoModel> {
    protected getInstance(): UserAccountInfoModel {
        return new UserAccountInfoModel();
    }
}
