<nz-list-item>
    <nz-list-item-meta
        nzAvatar="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
        [nzDescription]="admin.email"
    >
        <!--    <nz-list-item-meta-title>-->
        <!--      <a href="https://ng.ant.design">{{admin.email}}</a>-->
        <!--    </nz-list-item-meta-title>-->
    </nz-list-item-meta>
    <nz-select
        [nzMaxTagCount]="3"
        [nzMaxTagPlaceholder]="tagPlaceHolder"
        nzMode="multiple"
        nzPlaceHolder="Please select permissions"
        [(ngModel)]="listOfSelectedValues"
    >
        <nz-option *ngFor="let item of listOfAvailableValues" [nzLabel]="item" [nzValue]="item"></nz-option>
    </nz-select>
    <button class="btn-save-permissions" nz-button nzType="default" [disabled]="!canSave" (click)="onSavePermissions()">
        Save
    </button>
    <ng-template #tagPlaceHolder let-selectedList>and {{ selectedList.length }} more selected</ng-template>
</nz-list-item>
