<div
    *ngIf="Hls.isSupported() && videoSrc; else empty"
    class="video"
    style="width: 75%; margin: 0 auto; margin-bottom: 1rem"
>
    <video #videoPlayer controls preload="auto" data-setup="{}"></video>
</div>
<ng-template #empty>
    <p></p>
</ng-template>
