<nz-layout *ngIf="data">
    <div class="site-page-header-ghost-wrapper">
        <nz-page-header nzBackIcon [nzGhost]="false">
            <nz-page-header-title>
                {{data.nickname}}
            </nz-page-header-title>
            <nz-page-header-subtitle>{{data.firstName}} {{data.lastName}}</nz-page-header-subtitle>
        </nz-page-header>
    </div>
    <nz-content class="inner-content">
        <nz-spin [nzSpinning]="false">
            <nz-row [nzGutter]="[16, 8]">
                <nz-col [nzSpan]="12" [nzMd]="24" [nzXl]="6">
                    <nz-card>
                        <nz-row [nzGutter]="[16, 16]">
                            <nz-card style="width:300px; position: relative;">
                                <nz-card-meta [nzTitle]="data.fullname" [nzDescription]="data.email"></nz-card-meta>
                            </nz-card>
                        </nz-row>
                    </nz-card>
                </nz-col>
                <nz-col [nzSpan]="12" [nzMd]="24" [nzXl]="16">
                    <nz-row nzAlign="top" [nzGutter]="[16, 16]">
                        <nz-tabset style="width: 100%; padding: 10px">
                            <nz-tab *ngxPermissionsOnly="[permissions.ViewFanAccountInfo]" nzTitle="Account info">
                                <user-details-account-info
                                    *ngIf="data?.account"
                                    [user]="data"
                                ></user-details-account-info>
                            </nz-tab>
                        </nz-tabset>
                    </nz-row>
                    <nz-row [nzGutter]="[16, 16]">
                        <nz-col nzFlex="auto"> </nz-col>
                    </nz-row>
                </nz-col>
            </nz-row>
        </nz-spin>
    </nz-content>
    <nz-footer>
        <nz-row nzJustify="end">
            <nz-button-group>
                <!--        <button nz-button nzType="text" >CANCEL</button>&lt;!&ndash;-->
                <!--        <button nz-button nzType="primary" >SAVE</button>&ndash;&gt;-->
            </nz-button-group>
        </nz-row>
    </nz-footer>
</nz-layout>
