import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppMainConfigService } from '@fca-app/config/app-main-config.service';
import { TableDataListConfig } from '@fca-app/config/app-main.config';
import { ArenaModel } from '@fca-app/models/users/arena/arena.model';
import { FanModel } from '@fca-app/models/users/fan/fan.model';
import { FansService } from '@fca-app/services/fans.service';
import { ImagesService } from '@fca-app/services/images.service';
import { EAdminPermissions } from '@fca-app/shared/enums/admin-permissions.enum';
import { TableViewData } from '@fca-app/shared/interfaces/table-view-data.interface';
import { untilDestroy, UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { FcaPreloadService } from '@fca-app/shared/services/fca-preload.service';
import { TableSearchParams } from '@fca-app/shared/types';
import { SaveViewFansPage } from '@fca-app/store/actions';
import { FcaStoreService } from '@fca-app/store/store.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableComponent, NzTableLayout } from 'ng-zorro-antd/table';
import { NgxPermissionsService } from 'ngx-permissions';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'app-fan-list',
    templateUrl: './fan-list.component.html',
    styleUrls: ['./fan-list.component.scss'],
})
export class FanListComponent implements AfterViewInit, OnInit {
    readonly tableLayout: NzTableLayout = 'auto';
    readonly tableListConfig: TableDataListConfig = this.configService.getConfig().fanListConfig;

    searchQuery?: string = undefined;
    activePage: number = 1;
    permissions = EAdminPermissions;
    search$ = new BehaviorSubject<string | undefined>(undefined);
    fansData$: Observable<TableViewData<FanModel>>;

    @ViewChild('tableComponent')
    tableComponent: NzTableComponent<FanModel[]>;
    onSearchChanged$ = new Subject<string>();

    constructor(
        private readonly fansService: FansService,
        private readonly configService: AppMainConfigService,
        public readonly preloadService: FcaPreloadService,
        private readonly imagesService: ImagesService,
        private readonly storeService: FcaStoreService,
        private readonly nzNotificationService: NzNotificationService,
        private readonly appConfigService: AppMainConfigService,
        private readonly permissionsService: NgxPermissionsService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.fansData$ = combineLatest([this.storeService.getFansViewPage(), this.search$.asObservable()]).pipe(
            tap(([page]) => {
                this.preloadService.preload(true);
                this.activePage = page;
            }),
            map(([page, search]) => ({
                pageIndex: page,
                pageSize: this.tableListConfig.countItemsOnPage,
                filter: [],
                sort: [],
                searchQuery: search,
            })),
            switchMap((qp: TableSearchParams) => {
                return this.fansService
                    .searchFans(qp, qp.searchQuery)
                    .pipe(tap(() => this.preloadService.preload(false)));
            })
        );
    }

    ngAfterViewInit() {
        this.onSearchChanged$
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                tap(value => this.search$.next(value)),
                untilDestroy(this)
            )
            .subscribe();
    }

    onSearchChange(search: string): void {
        this.onSearchChanged$.next(search);
    }

    getNextPage(page: number): void {
        this.storeService.dispatch(new SaveViewFansPage({ page }));
    }

    async goToDetails(fan: FanModel): Promise<void> {
        // const hasPermission = await this.permissionsService.hasPermission(EAdminPermissions.ViewFanDetails);

        // if (hasPermission) {
        //   await this.router.navigate(['dashboard/arenas/' + arena.id], { state: arena });
        // }
        await this.router.navigate(['dashboard/fans/' + fan.id], { state: fan });
    }

    formatStr(value: string | undefined): string {
        let formattedStr = '';
        if (value) {
            formattedStr =
                value.slice(this.tableListConfig.descCutPosition.from, this.tableListConfig.descCutPosition.to) + '...';
        }
        return formattedStr;
    }
}
