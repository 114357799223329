import { GroupedTransactionsType } from '@fca-app/api/fca/users/interfaces/response/fighter/fighter-account-info.response';
import { AccountInfoTransactionItemHistoryItemModel } from '@fca-app/models/users/user/account-info/account-info-transaction-item/account-info-transaction-item-history-item/account-info-transaction-item-history-item.model';

export interface AccountInfoTransactionItemModelData {
    amount: number;
    type: GroupedTransactionsType;
    history: AccountInfoTransactionItemHistoryItemModel[];
}

export interface AccountInfoTransactionItemModel extends AccountInfoTransactionItemModelData {}

export class AccountInfoTransactionItemModel {}
