import { Injectable } from '@angular/core';
import { FcaUsersApiService } from '@fca-app/api/fca/users/fca-users-api.service';
import { FanApiResponse } from '@fca-app/api/fca/users/interfaces/response/fan-api.response';
import { EUserRoleName } from '@fca-app/enums/user-role-names.enum';
import { FanFactory } from '@fca-app/models/users/fan/fan.factory';
import { FanMapper } from '@fca-app/models/users/fan/fan.mapper';
import { FanModel } from '@fca-app/models/users/fan/fan.model';
import { UserAccountInfoFactory } from '@fca-app/models/users/user/account-info/user-account-info.factory';
import { UserAccountInfoMapper } from '@fca-app/models/users/user/account-info/user-account-info.mapper';
import { TableViewData } from '@fca-app/shared/interfaces/table-view-data.interface';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class FansService {
    constructor(private readonly usersService: FcaUsersApiService) {}

    searchFans(
        queryParams: { pageSize: number; pageIndex: number },
        searchQuery?: string
    ): Observable<TableViewData<FanModel>> {
        return this.usersService
            .search<FanApiResponse>({
                limit: queryParams.pageSize,
                page: queryParams.pageIndex,
                searchTerm: searchQuery,
                roleName: EUserRoleName.FAN,
            })
            .pipe(
                map(response => ({
                    data: response.data.map(raw => new FanFactory().getModelFromData(new FanMapper().mapData(raw))),
                    count: response.count,
                }))
            );
    }

    getFan(id: number): Observable<FanModel> {
        return combineLatest([
            this.usersService.getUserById<FanApiResponse>(id),
            this.usersService.getUserAccountInfo(id),
        ]).pipe(
            map(([fan, accountInfo]) => {
                return new FanFactory().getModelFromData({
                    ...new FanMapper().mapData(fan),
                    account: new UserAccountInfoFactory().getModelFromData(
                        new UserAccountInfoMapper().mapData(accountInfo)
                    ),
                });
            })
        );
    }
}
