import { FighterAccountInfoResponse } from '@fca-app/api/fca/users/interfaces/response/fighter/fighter-account-info.response';
import { AccountInfoTransactionItemFactory } from '@fca-app/models/users/user/account-info/account-info-transaction-item/account-info-transaction-item.factory';
import { AccountInfoTransactionItemMapper } from '@fca-app/models/users/user/account-info/account-info-transaction-item/account-info-transaction-item.mapper';
import { UserAccountInfoModelData } from '@fca-app/models/users/user/account-info/user-account-info.model';

export class UserAccountInfoMapper {
    mapData(raw: FighterAccountInfoResponse): UserAccountInfoModelData {
        return {
            balance: raw.balance,
            withdrawAvailable: raw.withdrawAvailable,
            transactions: (raw.transactions || []).map(rawTransactionItem =>
                new AccountInfoTransactionItemFactory().getModelFromData(
                    new AccountInfoTransactionItemMapper().mapData(rawTransactionItem)
                )
            ),
        };
    }
}
