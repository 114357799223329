import { Component } from '@angular/core';
import { UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';

@UntilDestroy()
@Component({
    selector: 'statistics-bst',
    templateUrl: './statistics-bst.component.html',
    styleUrls: ['./statistics-bst.component.scss'],
})
export class StatisticsBstComponent {}
