<div>
    <form [formGroup]="formGroup">Enter address: <input nz-input formControlName="searchControl" /></form>
    <div nzSpaceItem>
        <nz-table *ngIf="data != null && data.length > 0" #basicTable [nzData]="data">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Address</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of basicTable.data; let i = index" (click)="selectAddress(data)">
                    <td>{{ i + 1 }}</td>
                    <td>{{ data.formattedAddress }}</td>
                </tr>
            </tbody>
        </nz-table>
    </div>
</div>
