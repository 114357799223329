<div class="start-type">
    <a nz-dropdown [nzDropdownMenu]="menu">
        {{ type || 'Choose type' }}
        <span nz-icon nzType="down"></span>
    </a>
    <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
            <li *ngFor="let t of fightEventTypes" nz-menu-item (click)="onClickType(t)">{{ t }}</li>
        </ul>
    </nz-dropdown-menu>
</div>
<input nz-input [placeholder]="inputPlaceholder" [(ngModel)]="value" />
<nz-select
    nzMode="multiple"
    nzPlaceHolder="Choose languages"
    [nzOptions]="listOfOption"
    [(ngModel)]="languages"
></nz-select>
