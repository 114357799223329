export enum EOrderStatisticsKick {
    'DESCRIPTION' = 'DESCRIPTION',
    'DURATION' = 'DURATION',
    'VIEWS' = 'VIEWS',
    'UNIQUE' = 'UNIQUE',
    'LIKES' = 'LIKES',
    'COMMENTS' = 'COMMENTS',
    'UPLOADED' = 'UPLOADED',
    'USER_ID' = 'USER_ID',
    'TAGS' = 'TAGS',
}
