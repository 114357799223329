<nz-layout class="statistics-layout">
    <div style="max-width: 600px">
        <div>
            <nz-form-item>
                <label class="form-label">Role</label>
                <nz-select
                    nzMode="multiple"
                    nzPlaceHolder="Select fighter weighting..."
                    [(ngModel)]="request.roles"
                    (ngModelChange)="filterChange$.next(true)"
                >
                    <nz-option
                        *ngFor="let option of roles"
                        [nzLabel]="option.label"
                        [nzValue]="option.value"
                    ></nz-option>
                </nz-select>
            </nz-form-item>
        </div>
        <div>
            <nz-form-item>
                <label class="form-label">Language</label>
                <nz-select
                    nzMode="multiple"
                    nzPlaceHolder="Select language..."
                    [nzMaxTagCount]="5"
                    [nzMaxTagPlaceholder]="tagPlaceHolder"
                    [(ngModel)]="request.languages"
                    (ngModelChange)="filterChange$.next(true)"
                >
                    <nz-option *ngFor="let option of languages" [nzLabel]="option" [nzValue]="option"></nz-option>
                </nz-select>
                <label nz-checkbox [(ngModel)]="selectAllChecked" (click)="onSelectAllLanguages(selectAllChecked)"
                    >Select all languages</label
                >
                <ng-template #tagPlaceHolder let-selectedList>and {{ selectedList.length }} more selected</ng-template>
            </nz-form-item>
        </div>
        <div>
            <nz-form-item>
                <label class="form-label">Last activity</label>
                <nz-select
                    nzPlaceHolder="Select fighter weighting..."
                    [(ngModel)]="request.activity"
                    (ngModelChange)="filterChange$.next(true)"
                >
                    <nz-option
                        *ngFor="let option of activities"
                        [nzLabel]="option.label"
                        [nzValue]="option.value"
                    ></nz-option>
                </nz-select>
            </nz-form-item>
        </div>
        <button
            nz-button
            nzType="primary"
            [disabled]="downloadDisabled"
            [nzLoading]="preloadService.isLoad$ | async"
            nzShape="round"
            (click)="onDownloadClick()"
        >
            <i nz-icon nzType="download"></i>
            Download
        </button>
    </div>
</nz-layout>
