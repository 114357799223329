import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

export class GoogleApiInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.indexOf('maps.googleapis.com') > -1) {
            req = req.clone({ headers: req.headers.delete('Authorization') });
            return next.handle(req);
        }
        return next.handle(req);
    }
}
