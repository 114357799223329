import { Injectable } from '@angular/core';
import { FcaUsersApiService } from '@fca-app/api/fca/users/fca-users-api.service';
import { EUserRoleName } from '@fca-app/enums/user-role-names.enum';
import { UntilDestroy, untilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, mergeMap, tap } from 'rxjs/operators';

@Injectable()
@UntilDestroy()
export class UserSearchStatisticsVideoTableService {
    public data$ = new Subject();

    public readonly query$ = new BehaviorSubject<string | null>(null);
    public readonly isVisible$ = new BehaviorSubject<boolean>(false);
    public readonly search$ = new Subject();
    public readonly reset$ = new Subject();

    private readonly resetPipe = this.reset$
        .pipe(
            untilDestroy(this),
            tap(_ => this.query$.next(null)),
            tap(_ => this.search$.next())
        )
        .subscribe();

    private readonly searchPipe = this.search$
        .pipe(
            untilDestroy(this),
            filter(_ => Boolean(this.query$.value)),
            mergeMap(_ =>
                this.fcaUsersApiService.search({
                    limit: 10,
                    page: 1,
                    roleName: EUserRoleName.FAN,
                    searchTerm: this.query$.value as string,
                })
            ),
            tap(_ => (this.data$ = new Subject())),
            tap(_ => _.data.forEach(key => this.data$.next(key)))
        )
        .subscribe();

    constructor(private readonly fcaUsersApiService: FcaUsersApiService) {}
}
