import { Component } from '@angular/core';
import { FcaVideosApiService } from '@fca-app/api/videos/fca-videos-api.service';
import { VideoRealtimeMetricsApiResponse } from '@fca-app/api/videos/interfaces/response/video-api.response';
import { untilDestroy, UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { BehaviorSubject, interval } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'statistics-video-realtime',
    templateUrl: './statistics-video-realtime.component.html',
    styleUrls: ['./statistics-video-realtime.component.scss'],
})
export class StatisticsVideoRealtimeComponent {
    public realtime$ = new BehaviorSubject<VideoRealtimeMetricsApiResponse>({
        mostPopularCountry: '-',
        mostPopularPlatform: '-',
        users: 0,
    });
    private refresher = interval(2 * 1000)
        .pipe(
            untilDestroy(this),
            mergeMap(() => this.fcaVideosApiService.realtime().pipe(take(1)))
        )
        .subscribe(data => this.realtime$.next(data));

    constructor(private readonly fcaVideosApiService: FcaVideosApiService) {}
}
