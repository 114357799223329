<div style="padding: 10px">
    <nz-row [nzGutter]="12">
        <nz-col
            [nzXs]="{ span: 24, offset: 0 }"
            [nzSm]="{ span: 24, offset: 0 }"
            [nzMd]="{ span: 24, offset: 0 }"
            [nzLg]="{ span: 24, offset: 0 }"
            [nzXl]="{ span: 12, offset: 0 }"
            style="padding-bottom: 10px"
        >
            <nz-card [nzBordered]="false" nzTitle="Users map" [nzBodyStyle]="{ padding: '0px' }">
                <div
                    class="box"
                    leaflet
                    [leafletOptions]="leafletOptions"
                    (leafletMapReady)="onMapReady($event)"
                    [leafletMarkerCluster]="leafletMarkers"
                    [leafletMarkerClusterOptions]="leafletMarkerClusterOptions"
                ></div>
            </nz-card>
        </nz-col>
        <nz-col
            [nzXs]="{ span: 24, offset: 0 }"
            [nzSm]="{ span: 24, offset: 0 }"
            [nzMd]="{ span: 24, offset: 0 }"
            [nzLg]="{ span: 24, offset: 0 }"
            [nzXl]="{ span: 12, offset: 0 }"
        >
            <nz-card
                [nzBordered]="false"
                [nzTitle]="selected"
                [nzBodyStyle]="{ padding: '0px' }"
                [nzExtra]="countryCityTmp"
            >
                <ngx-charts-pie-chart
                    [scheme]="nzChartsColorScheme"
                    [results]="results$ | async"
                    [gradient]="false"
                    [legend]="false"
                    [labels]="true"
                    [doughnut]="false"
                    class="box"
                >
                </ngx-charts-pie-chart>
            </nz-card>
            <ng-template #countryCityTmp>
                <a nz-dropdown [nzDropdownMenu]="menu">
                    Group by
                    <span nz-icon nzType="down"></span>
                </a>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu nzSelectable>
                        <li nz-menu-item (click)="results$.next(countries); selected = 'Counties'" nzSelected>
                            Country
                        </li>
                        <li nz-menu-item (click)="results$.next(cities); selected = 'Cities'">City</li>
                        <li nz-menu-item (click)="results$.next(qualities); selected = 'Qualities'">Quality</li>
                    </ul>
                </nz-dropdown-menu>
            </ng-template>
        </nz-col>
    </nz-row>
</div>
