import { InjectionToken } from '@angular/core';
import { environment } from '@env/environment';

export const APP_MAIN_CONFIG = new InjectionToken('APP_MAIN_CONFIG_TOKEN');

export function appMainConfigFactory(): AppMainConfig {
    return {
        apiUrl: environment.baseUrl,
        chatUrl: environment.chatUrl,
        kicksApiUrl: environment.kicksUrl,
        videosApiUrl: environment.videosUrl,
        previewImage: 'assets/images/placeholder-image.png',
        videoStreamingAuthKey: environment.videoStreamingApiAuthKey,
        arenaListConfig: {
            descCutPosition: {
                from: 0,
                to: 15,
            },
            countItemsOnPage: 10,
            isShowDefaultTablePagination: false,
        },
        fighterListConfig: {
            descCutPosition: {
                from: 0,
                to: 15,
            },
            countItemsOnPage: 10,
            isShowDefaultTablePagination: false,
        },
        fanListConfig: {
            descCutPosition: {
                from: 0,
                to: 15,
            },
            countItemsOnPage: 10,
            isShowDefaultTablePagination: false,
        },
        paymentEditRequestsListConfig: {
            isShowDefaultTablePagination: false,
            countItemsOnPage: 5,
            descCutPosition: {
                from: 0,
                to: 0,
            },
        },
    };
}

export interface AppMainConfig {
    previewImage: string;
    apiUrl: string;
    kicksApiUrl: string;
    videosApiUrl: string;
    arenaListConfig: TableDataListConfig;
    fighterListConfig: TableDataListConfig;
    fanListConfig: TableDataListConfig;
    paymentEditRequestsListConfig: TableDataListConfig;
    chatUrl: string;
    videoStreamingAuthKey: string;
}

export interface TableDataListConfig {
    descCutPosition: {
        from: number;
        to: number;
    };
    countItemsOnPage: number;
    isShowDefaultTablePagination: boolean;
}
