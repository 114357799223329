<ng-container *ngIf="fightersData$ | async as data">
    <div class="fighters-search">
        <nz-input-group nzSearch>
            <input #srchInput type="text" (input)="onSearchChange(srchInput.value)" nz-input placeholder="Search..." />
        </nz-input-group>
    </div>

    <input style="display: none" #fileSelect type="file" (change)="onSelectFile($event)" accept=".jpg, .jpeg, .png" />
    <app-image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [image]="cropperImage"
        (onUpload)="onUploadImage($event)"
        (onClose)="onCloseCropper()"
        [imageType]="imageType"
        [inputFile]="fileSelect"
        [isModalVisible]="!!(isModalCropperVisible$ | async)"
    >
    </app-image-cropper>
    <nz-table
        #tableComponent
        nzBordered
        [nzData]="data.data"
        [nzTableLayout]="tableLayout"
        [nzShowPagination]="tableListConfig.isShowDefaultTablePagination"
        [nzLoading]="preloadService.isLoad$ | async"
        style="overflow: auto;"
    >
        <thead>
            <tr class="fighter-list-table">
                <th>Name</th>
                <th>Surname</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Country</th>
                <th>Gender</th>
                <th>Weight</th>
                <th>Height</th>
                <th>Birthdate</th>
                <th>Ranking</th>
                <th>Registered</th>
                <th>Face avatar</th>
                <th>Length avatar</th>
                <th>Active</th>
            </tr>
        </thead>
        <tbody>
            <tr
                *ngFor="let data of data.data"
                (click)="onFighterDetails(data)"
                class="fighter-list-table fighter-row"
                [ngStyle]="{ 'background-color': data.penaltyInfo?.banned ? 'rgba(255, 77, 79, .5)' : '' }"
            >
                <td>{{ data.firstName }}</td>
                <td>{{ data.lastName }}</td>
                <td>
                    <span nz-tooltip [nzTooltipTitle]="data.email">
                        {{ formatStr(data.email) }}
                    </span>
                </td>
                <td>{{ data.phoneNumber }}</td>
                <td>{{ data.country }}</td>
                <td>{{ data.details.gender }}</td>
                <td>{{ data.details.weight }}</td>
                <td>{{ data.details.height }}</td>
                <td>{{ data.details.birthdate | date: 'longDate' }}</td>
                <td>{{ data.details.ranking }}</td>
                <td>{{ data.createDate | date: 'longDate' }}</td>
                <td class="fighter-avatar-cell">
                    <div class="fighter-avatar">
                        <ng-container *ngIf="data.fullFaceAvatar; else imagePlaceholder">
                            <img
                                loading="lazy"
                                (click)="onImgClick($event)"
                                nz-image
                                [nzSrc]="data.fullFaceAvatar.href || ''"
                            />
                        </ng-container>
                        <ng-template #imagePlaceholder>
                            <img loading="lazy" [src]="defaultImagePlaceholder" />
                        </ng-template>
                    </div>
                    <button
                        *ngxPermissionsOnly="[permissions.UploadFighterImages]"
                        nz-button
                        (click)="
                            fileSelect.click();
                            $event.stopImmediatePropagation();
                            uploadTo = data;
                            cropperImage = data.fullFaceAvatar;
                            imageType = imageTypes.FULL_FACE_AVATAR
                        "
                    >
                        <i nz-icon nzType="upload"></i>
                        Upload
                    </button>
                </td>
                <td class="fighter-avatar-cell">
                    <div class="fighter-avatar">
                        <ng-container *ngIf="data.halfLengthAvatar; else imagePlaceholder">
                            <img
                                loading="lazy"
                                (click)="onImgClick($event)"
                                nz-image
                                [nzSrc]="data.halfLengthAvatar?.href || ''"
                            />
                        </ng-container>
                        <ng-template #imagePlaceholder>
                            <img loading="lazy" [src]="defaultImagePlaceholder" />
                        </ng-template>
                    </div>
                    <button
                        *ngxPermissionsOnly="[permissions.UploadFighterImages]"
                        nz-button
                        (click)="
                            fileSelect.click();
                            $event.stopImmediatePropagation();
                            uploadTo = data;
                            cropperImage = data.halfLengthAvatar;
                            imageType = imageTypes.HALF_LENGTH_AVATAR
                        "
                    >
                        <i nz-icon nzType="upload"></i>
                        Upload
                    </button>
                </td>
                <td class="fighter-status">
                    <nz-tag [nzColor]="getNzBadgeStatus(data.details.isActive)">
                        <i nz-icon nzType="check-circle" [nzSpin]="!data.details.isActive"></i>
                        <span>{{ data.isActiveToString }}</span>
                    </nz-tag>
                </td>
            </tr>
        </tbody>
    </nz-table>

    <br />

    <nz-pagination
        [nzPageIndex]="activePage"
        [nzTotal]="data.count"
        (nzPageIndexChange)="getNextPage($event)"
    ></nz-pagination>
</ng-container>
