<nz-modal
    [(nzVisible)]="isModalVisible"
    nzTitle="Crop Image"
    (nzOnCancel)="handleCancelModal()"
    (nzOnOk)="handleOkModal()"
>
    <ng-container *nzModalContent>
        <div nz-row>
            <div nz-col nzSpan="11" class="image-cropper">
                <image-cropper
                    [imageChangedEvent]="imageChangedEvent"
                    [imageBase64]="imageBase64"
                    [maintainAspectRatio]="true"
                    [aspectRatio]="photoAspectRatio()"
                    format="jpeg"
                    [maintainAspectRatio]="false"
                    [resizeToWidth]="1200"
                    [resizeToHeight]="1200"
                    (imageCropped)="imageCropped($event)"
                    (cropperReady)="cropperReady()"
                    (loadImageFailed)="loadImageFailed()"
                ></image-cropper>
                <span nz-typography nzType="secondary">Source</span>
            </div>
            <div nz-col nzSpan="2"></div>
            <div nz-col nzSpan="11" class="image-cropper-result">
                <img loading="lazy" class="result-image" [src]="croppedImage" />
                <span nz-typography nzType="secondary">Result</span>
            </div>
        </div>
        <div nz-col nzSpan="24" class="buttons">
            <button (click)="rotate('LEFT')" nz-button nzType="primary" nzShape="circle">
                <span nz-icon nzType="rotate-left" nzTheme="outline" class="rotate-span"></span>
            </button>
            <button (click)="rotate('RIGHT')" nz-button nzType="primary" nzShape="circle">
                <span nz-icon nzType="rotate-right" nzTheme="outline" class="rotate-span"></span>
            </button>
        </div>
    </ng-container>
</nz-modal>
