import {
    AccountInfoTransactionItemModel,
    AccountInfoTransactionItemModelData,
} from '@fca-app/models/users/user/account-info/account-info-transaction-item/account-info-transaction-item.model';
import { ModelFactory } from '@fca-app/shared/abstract/model-factory.abstract';

export class AccountInfoTransactionItemFactory extends ModelFactory<
    AccountInfoTransactionItemModelData,
    AccountInfoTransactionItemModel
> {
    protected getInstance(): AccountInfoTransactionItemModel {
        return new AccountInfoTransactionItemModel();
    }
}
