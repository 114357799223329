import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EWithdrawMoneyStatus } from '@fca-app/api/fca/users/interfaces/response/fighter/fighter-account-info.response';
import { AccountInfoTransactionItemModel } from '@fca-app/models/users/user/account-info/account-info-transaction-item/account-info-transaction-item.model';
import { UserModel } from '@fca-app/models/users/user/user.model';
import { UsersService } from '@fca-app/services/users.service';
import { EAdminPermissions } from '@fca-app/shared/enums/admin-permissions.enum';
import { untilDestroy, UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { FcaPreloadService } from '@fca-app/shared/services/fca-preload.service';
import * as moment from 'moment';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'user-details-account-info',
    styleUrls: ['./user-details-account-info.component.scss'],
    templateUrl: './user-details-account-info.component.html',
})
export class UserDetailsAccountInfoComponent {
    @Input() user: UserModel;
    permissions = EAdminPermissions;

    constructor(private readonly usersService: UsersService, public readonly preloadService: FcaPreloadService) {}

    get balance(): string {
        return `$${this.prepareMoney(this.user.account!.balance)}`;
    }

    booleanToString(value: boolean): string {
        return value ? 'Yes' : 'No';
    }

    getItemText(transaction: AccountInfoTransactionItemModel): string {
        const receivedMoneyType = transaction.history[0].type;

        return transaction.type === 'withdraw'
            ? 'Withdraw'
            : `Received ${receivedMoneyType === 'donate' ? 'donate' : 'honorarium'}`;
    }

    getTransactionDate(transaction: AccountInfoTransactionItemModel): string {
        return moment(transaction.history[0].created)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss');
    }

    transactionIsPending(transaction: AccountInfoTransactionItemModel): boolean {
        return transaction.type === 'withdraw' && transaction.history[0].status === EWithdrawMoneyStatus.Pending;
    }

    transactionIsWithdrawn(transaction: AccountInfoTransactionItemModel): boolean {
        return transaction.type === 'withdraw' && transaction.history[0].status === EWithdrawMoneyStatus.Withdrawn;
    }

    prepareMoney(value: number): number {
        return value / 100;
    }

    onConfirmPaymentClick(): void {
        this.preloadService.preload(true);
        this.usersService
            .confirmWithdrawal(this.user.id)
            .pipe(take(1), untilDestroy(this))
            .subscribe(resp => {
                this.preloadService.preload(false);
                this.user.account = resp;
            });
    }
}
