import { Injectable } from '@angular/core';
import { FcaApiServiceBase } from '@fca-app/api/fca/fca-api-service-base.service';
import { DownloadStatisticsParams } from '@fca-app/api/fca/statistics/interfaces/download-statistics-params.interface';
import { Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

@Injectable()
export class FcaUsersStatisticsService extends FcaApiServiceBase {
    downloadStatistics(params: DownloadStatisticsParams): Observable<string> {
        const url = [this.apiUrl, 'statistics', 'download-users-csv'].join('/');

        return this.http
            .post<string>(url, params, { headers: this.defaultHeaders, responseType: 'blob' as 'json' })
            .pipe(publishReplay(1), refCount());
    }

    downloadEventJson(eventId: string): Observable<string> {
        const url = [this.apiUrl, 'statistics', 'download-event-json', eventId].join('/');

        return this.http
            .get<string>(url, { headers: this.defaultHeaders, responseType: 'blob' as 'json' })
            .pipe(publishReplay(1), refCount());
    }

    getFightEvents(name: string): Observable<{ name: string; video: string }[]> {
        return this.http
            .post<{ name: string; video: string }[]>(`${this.apiUrl}/fight-events/admin/stream/getEvents`, {
                name: name?.length ? name : null,
            })
            .pipe(publishReplay(1), refCount());
    }
}
