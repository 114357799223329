import { Injectable } from '@angular/core';
import { FcaApiServiceBase } from '@fca-app/api/fca/fca-api-service-base.service';
import {
    EMatchingStatus,
    FighterMatchmakingRequestApiResponse,
} from '@fca-app/api/fca/matchmaking/interfaces/response/fighter-matchmaking-request-api.response';
import { IMatchmakingSearchRequest } from '@fca-app/dashboard/components/matchmaking/search/search-matchmaking.component';
import { Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

@Injectable()
export class FcaMatchmakingApiService extends FcaApiServiceBase {
    getFighterRequests(fighterId: number): Observable<FighterMatchmakingRequestApiResponse[]> {
        const url = [this.apiUrl, 'matchmaking', 'admin', 'fighter-requests', fighterId].join('/');

        return this.http
            .get<any>(url, { headers: this.defaultHeaders })
            .pipe(publishReplay(1), refCount());
    }

    getFightEvents(name: string | null): Observable<{ name: string; id: number }[]> {
        return this.http
            .post<{ name: string; id: number }[]>(`${this.apiUrl}/matchmaking/admin/find-events`, { name })
            .pipe(publishReplay(1), refCount());
    }

    getMatchmakingList(request: IMatchmakingSearchRequest): Observable<FighterMatchmakingRequestApiResponse[]> {
        return this.http
            .post<FighterMatchmakingRequestApiResponse[]>(`${this.apiUrl}/matchmaking/admin/list`, request)
            .pipe(publishReplay(1), refCount());
    }

    cancelRequest(userId: number, fightEventId: string, status: EMatchingStatus): Observable<void> {
        return this.http
            .post<void>(`${this.apiUrl}/matchmaking/admin/cancel`, { userId, fightEventId, status })
            .pipe(publishReplay(1), refCount());
    }

    confirmRequest(fighterId: number, fightEventId: string): Observable<void> {
        return this.http
            .post<void>(`${this.apiUrl}/matchmaking/admin/confirm`, { fighterId, fightEventId })
            .pipe(publishReplay(1), refCount());
    }
}
