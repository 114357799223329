import { Component, EventEmitter } from '@angular/core';
import { UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';

@UntilDestroy()
@Component({
    selector: 'statistics-stream',
    templateUrl: './statistics-stream.component.html',
    styleUrls: ['./statistics-stream.component.scss'],
})
export class StatisticsStreamComponent {
    public outputEventEmitter = new EventEmitter<{
        date: number[];
        timeStepSelected: string;
        videoId: string | null;
    }>();

    public emmit(event: { date: number[]; timeStepSelected: string; videoId: string | null }): void {
        this.outputEventEmitter.emit(event);
    }
}
