import {
    FightEventStartInfoModel,
    FightEventStartInfoModelData,
} from '@fca-app/models/users/arena/fight-event/fight-event-start-info/fight-event-start-info.model';

export interface FightEventConferenceModelData extends FightEventStartInfoModelData {}

export interface FightEventConferenceModel extends FightEventConferenceModelData {}

export class FightEventConferenceModel extends FightEventStartInfoModel {}
