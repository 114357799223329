<div>
    <nz-card [nzTitle]="photoTypeString(imageType)">
        <div class="arena-photo-container">
            <img loading="lazy" class="arena-photo-img" nz-image [nzSrc]="previewPlaceholder" />
            <div *ngxPermissionsOnly="[permissions.UploadArenaImages]" class="arena-photo-actions">
                <div class="arena-photo-actions-list">
                    <button nz-button nzType="primary" nz-tooltip="Zoom" (click)="click()">
                        <i nz-icon nzType="zoom-in"></i>
                    </button>
                    <button nz-button nzType="primary" nz-tooltip="Edit" (click)="fileSelect.click()">
                        <i nz-icon nzType="edit"></i>
                    </button>
                    <button nz-button nzType="primary" nz-tooltip="Delete" (click)="onDeletePhotoClick()">
                        <i nz-icon nzType="delete"></i>
                    </button>
                </div>
            </div>
        </div>
        <app-image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [imageType]="imageType"
            (onUpload)="onPhotoUpload($event)"
            (onClose)="onCloseCropper()"
            [inputFile]="fileSelect"
            [isModalVisible]="!!(isModalVisible$ | async)"
        >
        </app-image-cropper>
        <input style="display: none" #fileSelect type="file" (change)="edit($event)" accept=".jpg, .jpeg, .png" />
    </nz-card>
</div>
