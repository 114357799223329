import { ELocale } from '@fca-app/shared/enums/locale.enum';

export const Languages: { value: ELocale; label: string }[] = [
    { value: ELocale.EN, label: 'English' },
    { value: ELocale.RU, label: 'Russian' },
    { value: ELocale.PT, label: 'Portuguese' },
    { value: ELocale.AB, label: 'Abkhazian' },
    { value: ELocale.AA, label: 'Afar' },
    { value: ELocale.AF, label: 'Afrikaans' },
    { value: ELocale.AK, label: 'Akan' },
    { value: ELocale.SQ, label: 'Albanian' },
    { value: ELocale.AM, label: 'Amharic' },
    { value: ELocale.AR, label: 'Arabic' },
    { value: ELocale.AN, label: 'Aragonese' },
    { value: ELocale.HY, label: 'Armenian' },
    { value: ELocale.AS, label: 'Assamese' },
    { value: ELocale.AV, label: 'Avaric' },
    { value: ELocale.AE, label: 'Avestan' },
    { value: ELocale.AY, label: 'Aymara' },
    { value: ELocale.AZ, label: 'Azerbaijani' },
    { value: ELocale.BM, label: 'Bambara' },
    { value: ELocale.BA, label: 'Bashkir' },
    { value: ELocale.EU, label: 'Basque' },
    { value: ELocale.BE, label: 'Belarusian' },
    { value: ELocale.BN, label: 'Bengali' },
    { value: ELocale.BH, label: 'Bihari' },
    { value: ELocale.BI, label: 'Bislama' },
    { value: ELocale.BS, label: 'Bosnian' },
    { value: ELocale.BR, label: 'Breton' },
    { value: ELocale.BG, label: 'Bulgarian' },
    { value: ELocale.MY, label: 'Burmese' },
    { value: ELocale.CA, label: 'Catalan' },
    { value: ELocale.CH, label: 'Chamorro' },
    { value: ELocale.CE, label: 'Chechen' },
    { value: ELocale.NY, label: 'Chichewa' },
    { value: ELocale.ZH, label: 'Chinese' },
    { value: ELocale.CV, label: 'Chuvash' },
    { value: ELocale.KW, label: 'Cornish' },
    { value: ELocale.CO, label: 'Corsican' },
    { value: ELocale.CR, label: 'Cree' },
    { value: ELocale.HR, label: 'Croatian' },
    { value: ELocale.CS, label: 'Czech' },
    { value: ELocale.DA, label: 'Danish' },
    { value: ELocale.DV, label: 'Divehi' },
    { value: ELocale.NL, label: 'Dutch' },
    { value: ELocale.DZ, label: 'Dzongkha' },
    { value: ELocale.EO, label: 'Esperanto' },
    { value: ELocale.ET, label: 'Estonian' },
    { value: ELocale.EE, label: 'Ewe' },
    { value: ELocale.FO, label: 'Faroese' },
    { value: ELocale.FJ, label: 'Fijian' },
    { value: ELocale.FI, label: 'Finnish' },
    { value: ELocale.FR, label: 'French' },
    { value: ELocale.FF, label: 'Western Frisian' },
    { value: ELocale.GL, label: 'Galician' },
    { value: ELocale.KA, label: 'Georgian' },
    { value: ELocale.DE, label: 'German' },
    { value: ELocale.EL, label: 'Greek' },
    { value: ELocale.GN, label: 'Guarani' },
    { value: ELocale.GU, label: 'Gujarati' },
    { value: ELocale.HT, label: 'Haitian' },
    { value: ELocale.HA, label: 'Hausa' },
    { value: ELocale.HE, label: 'Hebrew' },
    { value: ELocale.HZ, label: 'Herero' },
    { value: ELocale.HI, label: 'Hindi' },
    { value: ELocale.HO, label: 'Hiri Motu' },
    { value: ELocale.HU, label: 'Hungarian' },
    { value: ELocale.IA, label: 'Interlingua' },
    { value: ELocale.ID, label: 'Indonesian' },
    { value: ELocale.IE, label: 'Interlingue' },
    { value: ELocale.GA, label: 'Irish' },
    { value: ELocale.IG, label: 'Igbo' },
    { value: ELocale.IK, label: 'Inupiaq' },
    { value: ELocale.IO, label: 'Ido' },
    { value: ELocale.IS, label: 'Icelandic' },
    { value: ELocale.IT, label: 'Italian' },
    { value: ELocale.IU, label: 'Inuktitut' },
    { value: ELocale.JA, label: 'Japanese' },
    { value: ELocale.JV, label: 'Javanese' },
    { value: ELocale.KL, label: 'Kalaallisut' },
    { value: ELocale.KN, label: 'Kannada' },
    { value: ELocale.KR, label: 'Kanuri' },
    { value: ELocale.KS, label: 'Kashmiri' },
    { value: ELocale.KK, label: 'Kazakh' },
    { value: ELocale.KM, label: 'Central Khmer' },
    { value: ELocale.KI, label: 'Kikuyu' },
    { value: ELocale.RW, label: 'Kinyarwanda' },
    { value: ELocale.KY, label: 'Kirghiz' },
    { value: ELocale.KV, label: 'Komi' },
    { value: ELocale.KG, label: 'Kongo' },
    { value: ELocale.KO, label: 'Korean' },
    { value: ELocale.KU, label: 'Kurdish' },
    { value: ELocale.KJ, label: 'Kuanyama' },
    { value: ELocale.LA, label: 'Latin' },
    { value: ELocale.LB, label: 'Luxembourgish' },
    { value: ELocale.LG, label: 'Ganda' },
    { value: ELocale.LI, label: 'Limburgan' },
    { value: ELocale.LN, label: 'Lingala' },
    { value: ELocale.LO, label: 'Lao' },
    { value: ELocale.LT, label: 'Lithuanian' },
    { value: ELocale.LU, label: 'Luba-Katanga' },
    { value: ELocale.LV, label: 'Latvian' },
    { value: ELocale.GV, label: 'Manx' },
    { value: ELocale.MK, label: 'Macedonian' },
    { value: ELocale.MG, label: 'Malagasy' },
    { value: ELocale.MS, label: 'Malay' },
    { value: ELocale.ML, label: 'Malayalam' },
    { value: ELocale.MT, label: 'Maltese' },
    { value: ELocale.MI, label: 'Maori' },
    { value: ELocale.MR, label: 'Marathi' },
    { value: ELocale.MH, label: 'Marshallese' },
    { value: ELocale.MN, label: 'Mongolian' },
    { value: ELocale.NA, label: 'Nauru' },
    { value: ELocale.NV, label: 'Navajo' },
    { value: ELocale.ND, label: 'North Ndebele' },
    { value: ELocale.NE, label: 'Nepali' },
    { value: ELocale.NG, label: 'Ndonga' },
    { value: ELocale.NB, label: 'Norwegian Bokmål' },
    { value: ELocale.NN, label: 'Norwegian Nynorsk' },
    { value: ELocale.NO, label: 'Norwegian' },
    { value: ELocale.II, label: 'Sichuan Yi' },
    { value: ELocale.NR, label: 'South Ndebele' },
    { value: ELocale.OC, label: 'Occitan' },
    { value: ELocale.OJ, label: 'Ojibwa' },
    { value: ELocale.CU, label: 'Church Slavonic' },
    { value: ELocale.OM, label: 'Oromo' },
    { value: ELocale.OR, label: 'Oriya' },
    { value: ELocale.OS, label: 'Ossetian' },
    { value: ELocale.PA, label: 'Punjabi' },
    { value: ELocale.PI, label: 'Pali' },
    { value: ELocale.FA, label: 'Persian' },
    { value: ELocale.POX, label: 'POX' },
    { value: ELocale.PL, label: 'Polish' },
    { value: ELocale.PS, label: 'Pashto' },
    { value: ELocale.QU, label: 'Quechua' },
    { value: ELocale.RM, label: 'Romansh' },
    { value: ELocale.RN, label: 'Rundi' },
    { value: ELocale.RO, label: 'Romanian' },
    { value: ELocale.SA, label: 'Sanskrit' },
    { value: ELocale.SC, label: 'Sardinian' },
    { value: ELocale.SD, label: 'Sindhi' },
    { value: ELocale.SE, label: 'Northern Sami' },
    { value: ELocale.SM, label: 'Samoan' },
    { value: ELocale.SG, label: 'Sango' },
    { value: ELocale.SR, label: 'Serbian' },
    { value: ELocale.GD, label: 'Gaelic' },
    { value: ELocale.SN, label: 'Shona' },
    { value: ELocale.SI, label: 'Sinhala' },
    { value: ELocale.SK, label: 'Slovak' },
    { value: ELocale.SL, label: 'Slovenian' },
    { value: ELocale.SO, label: 'Somali' },
    { value: ELocale.ST, label: 'Southern Sotho' },
    { value: ELocale.ES, label: 'Spanish' },
    { value: ELocale.SU, label: 'Sundanese' },
    { value: ELocale.SW, label: 'Swahili' },
    { value: ELocale.SS, label: 'Swati' },
    { value: ELocale.SV, label: 'Swedish' },
    { value: ELocale.TA, label: 'Tamil' },
    { value: ELocale.TE, label: 'Telugu' },
    { value: ELocale.TG, label: 'Tajik' },
    { value: ELocale.TH, label: 'Thai' },
    { value: ELocale.TI, label: 'Tigrinya' },
    { value: ELocale.BO, label: 'Tibetan' },
    { value: ELocale.TK, label: 'Turkmen' },
    { value: ELocale.TL, label: 'Tagalog' },
    { value: ELocale.TN, label: 'Tswana' },
    { value: ELocale.TO, label: 'Tonga' },
    { value: ELocale.TR, label: 'Turkish' },
    { value: ELocale.TS, label: 'Tsonga' },
    { value: ELocale.TT, label: 'Tatar' },
    { value: ELocale.TW, label: 'Twi' },
    { value: ELocale.TY, label: 'Tahitian' },
    { value: ELocale.UG, label: 'Uighur' },
    { value: ELocale.UK, label: 'Ukrainian' },
    { value: ELocale.UR, label: 'Urdu' },
    { value: ELocale.UZ, label: 'Uzbek' },
    { value: ELocale.VE, label: 'Venda' },
    { value: ELocale.VI, label: 'Vietnamese' },
    { value: ELocale.VO, label: 'Volapük' },
    { value: ELocale.WA, label: 'Walloon' },
    { value: ELocale.CY, label: 'Welsh' },
    { value: ELocale.WO, label: 'Wolof' },
    { value: ELocale.FY, label: 'Western Frisian' },
    { value: ELocale.XH, label: 'Xhosa' },
    { value: ELocale.YI, label: 'Yiddish' },
    { value: ELocale.YO, label: 'Yoruba' },
    { value: ELocale.ZA, label: 'Zhuang' },
    { value: ELocale.ZU, label: 'Zulu' },
];
