<div style="padding-left: 10px; padding-right: 10px; background-color: #e6f7ff00;">
    <div style="display: flex; padding-top: 10px;">
        <h4>Videos and users</h4>
        <div class="tag-table">Top 5 most viewed videos and most active users</div>
    </div>
</div>
<nz-row style="padding: 10px;" [nzGutter]="12">
    <nz-col
        [nzXs]="{ span: 24, offset: 0 }"
        [nzSm]="{ span: 24, offset: 0 }"
        [nzMd]="{ span: 24, offset: 0 }"
        [nzLg]="{ span: 24, offset: 0 }"
        [nzXl]="{ span: 12, offset: 0 }"
        style="padding-bottom: 10px"
    >
        <nz-table #videosTable [nzData]="videosData" [nzFrontPagination]="false">
            <thead>
                <tr>
                    <th nzWidth="60px"></th>
                    <th>Video description</th>
                    <th>Views</th>
                    <th>Unique</th>
                    <th>Shares</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of videosTable.data; let i = index">
                    <td style="padding-bottom: 5px; padding-left: 16px; padding-right: 16px; padding-top: 5px">
                        <img
                            *ngIf="data?.thumbnailHref"
                            nz-image
                            width="27px"
                            height="48px"
                            [nzSrc]="data!.thumbnailHref!"
                            alt=""
                        />
                        <img
                            *ngIf="!data?.thumbnailHref"
                            nz-image
                            width="27px"
                            height="48px"
                            [nzSrc]="'assets/images/no-video.png'"
                            alt=""
                        />
                    </td>
                    <td>
                        <span
                            style="vertical-align: text-bottom; color: green;"
                            *ngIf="i > data.lastRankIndex"
                            nz-icon
                            nzType="caret-up"
                            nzTheme="outline"
                        ></span>
                        <span
                            style="vertical-align: text-bottom; color: red;"
                            *ngIf="i < data.lastRankIndex"
                            nz-icon
                            nzType="caret-down"
                            nzTheme="outline"
                        ></span>
                        <span
                            style="vertical-align: text-bottom; color: #faad14;"
                            *ngIf="i === data.lastRankIndex"
                            nz-icon
                            nzType="minus"
                            nzTheme="outline"
                        ></span>
                        {{ data.description }}
                    </td>
                    <td>{{ data.views }}</td>
                    <td>{{ data.unique }}</td>
                    <td>{{ data.shares }}</td>
                </tr>
            </tbody>
        </nz-table>
    </nz-col>
    <nz-col
        [nzXs]="{ span: 24, offset: 0 }"
        [nzSm]="{ span: 24, offset: 0 }"
        [nzMd]="{ span: 24, offset: 0 }"
        [nzLg]="{ span: 24, offset: 0 }"
        [nzXl]="{ span: 12, offset: 0 }"
        style="padding-bottom: 10px"
    >
        <nz-table #usersTable [nzData]="userDate" [nzFrontPagination]="false">
            <thead>
                <tr>
                    <th nzWidth="60px"></th>
                    <th>Full name</th>
                    <th>Viewed</th>
                    <th>Videos</th>
                    <th>Shares</th>
                    <th>Likes</th>
                    <th>Comments</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of usersTable.data; let i = index">
                    <td style="padding-bottom: 5px; padding-left: 16px; padding-right: 16px; padding-top: 5px">
                        <img
                            *ngIf="data?.data?.thumbnailAvatar"
                            nz-image
                            width="48px"
                            height="48px"
                            [nzSrc]="data!.data!.thumbnailAvatar!"
                            alt=""
                        />
                        <img
                            *ngIf="!data?.data?.thumbnailAvatar"
                            nz-image
                            width="48px"
                            height="48px"
                            [nzSrc]="'assets/images/unkuser.png'"
                            alt=""
                        />
                    </td>
                    <td>
                        <span
                            style="vertical-align: text-bottom; color: green;"
                            *ngIf="i > data.lastRankIndex"
                            nz-icon
                            nzType="caret-up"
                            nzTheme="outline"
                        ></span>
                        <span
                            style="vertical-align: text-bottom; color: red;"
                            *ngIf="i < data.lastRankIndex"
                            nz-icon
                            nzType="caret-down"
                            nzTheme="outline"
                        ></span>
                        <span
                            style="vertical-align: text-bottom; color: #faad14;"
                            *ngIf="i === data.lastRankIndex"
                            nz-icon
                            nzType="minus"
                            nzTheme="outline"
                        ></span>
                        {{ data?.data?.firstName ?? '(unknown ' }} {{ data?.data?.lastName ?? 'user)' }}
                    </td>
                    <td>{{ data?.view }}</td>
                    <td>{{ data.video }}</td>
                    <td>{{ data?.shares }}</td>
                    <td>{{ data?.likes }}</td>
                    <td>{{ data?.comments }}</td>
                </tr>
            </tbody>
        </nz-table>
    </nz-col>
</nz-row>
