import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FanFactory } from '@fca-app/models/users/fan/fan.factory';
import { FanModel } from '@fca-app/models/users/fan/fan.model';
import { FansService } from '@fca-app/services/fans.service';
import { EAdminPermissions } from '@fca-app/shared/enums/admin-permissions.enum';
import { untilDestroy, UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { FcaPreloadService } from '@fca-app/shared/services/fca-preload.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { combineLatest, Subject } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'app-fan-details',
    templateUrl: './fan-details-component.html',
    styleUrls: ['./fan-details-component.scss'],
})
export class FanDetailsComponent implements OnInit, OnDestroy {
    private initialData: FanModel;

    id: number;
    data: FanModel;
    permissions = EAdminPermissions;
    reloadFan$ = new Subject<void>();

    constructor(
        public readonly preloadService: FcaPreloadService,
        private readonly fansService: FansService,
        private readonly notificationService: NzNotificationService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        if (this.router.getCurrentNavigation()?.extras.state as FanModel) {
            const routerData = this.router.getCurrentNavigation()?.extras.state as FanModel;
            if (routerData.id > -1) {
                this.setFanData(routerData);
            }
        }
        combineLatest([this.route.params, this.reloadFan$.asObservable().pipe(startWith(null))])
            .pipe(
                switchMap(([params]) => {
                    const id = Number(params['id']);
                    return this.fansService.getFan(id);
                }),
                untilDestroy(this)
            )
            .subscribe(fan => {
                this.data = fan;
                this.setFanData(fan);
            });
    }

    ngOnInit() {}

    ngOnDestroy() {}

    private setFanData(fan: FanModel): void {
        this.data = fan;
        this.initialData = new FanFactory().getModelFromData(fan);
    }
}
