import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ListVideosMetricsParams } from '@fca-app/api/videos/interfaces/params/list-metrics.params';
import {
    VideoLineChart,
    VideoMapMetricsApiResponse,
    VideoRealtimeMetricsApiResponse,
    VideosMetricsListApiResponse,
    VideoTop5VideosAndUsersResponse,
    VideoTotalSelection,
} from '@fca-app/api/videos/interfaces/response/video-api.response';
import { AppMainConfigService } from '@fca-app/config/app-main-config.service';
import { ETypeStatisticsVideoMap } from '@fca-app/dashboard/components/statistics/statistics-video/statistics-video-maps/enums/type.statistics-video-map.enum';
import { Observable } from 'rxjs';
import { map, publishReplay, refCount } from 'rxjs/operators';

@Injectable()
export class FcaVideosApiService {
    readonly videosApiUrl: string;
    readonly defaultHeaders = { 'Content-Type': 'application/json' };

    constructor(protected readonly configService: AppMainConfigService, protected readonly http: HttpClient) {
        this.videosApiUrl = configService.getConfig().videosApiUrl;
    }

    public listMetrics(
        params: ListVideosMetricsParams
    ): Observable<{
        total: number;
        data: VideosMetricsListApiResponse[];
    }> {
        const url = [this.videosApiUrl, 'metrics', 'admin', 'list'].join('/');
        return this.http
            .post<{
                total: number;
                data: VideosMetricsListApiResponse[];
            }>(url, params)
            .pipe(publishReplay(1), refCount());
    }

    public realtime(): Observable<VideoRealtimeMetricsApiResponse> {
        const url = [this.videosApiUrl, 'metrics', 'admin', 'realtime'].join('/');
        return this.http.get<VideoRealtimeMetricsApiResponse>(url).pipe(
            publishReplay(1),
            refCount(),
            map(_ => ({
                users: _.users,
                mostPopularCountry: _?.mostPopularCountry ?? '-',
                mostPopularPlatform: _?.mostPopularPlatform ?? '-',
            }))
        );
    }

    public map(type: ETypeStatisticsVideoMap): Observable<VideoMapMetricsApiResponse> {
        const url = [this.videosApiUrl, 'metrics', 'admin', 'map'].join('/');
        return this.http
            .post<VideoMapMetricsApiResponse>(url, { type })
            .pipe(publishReplay(1), refCount());
    }

    public totalSelection(): Observable<VideoTotalSelection> {
        const url = [this.videosApiUrl, 'metrics', 'admin', 'totalSection'].join('/');
        return this.http.get<VideoTotalSelection>(url).pipe(publishReplay(1), refCount());
    }

    public getTop5UsersAndVideos(): Observable<VideoTop5VideosAndUsersResponse> {
        const url = [this.videosApiUrl, 'metrics', 'admin', 'topVideoAndUsers'].join('/');
        return this.http.get<VideoTop5VideosAndUsersResponse>(url).pipe(publishReplay(1), refCount());
    }

    public getLineChart(): Observable<VideoLineChart[]> {
        const url = [this.videosApiUrl, 'metrics', 'admin', 'lineChart'].join('/');
        return this.http.get<VideoLineChart[]>(url).pipe(publishReplay(1), refCount());
    }
}
