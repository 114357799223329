import { AfterContentInit, AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KicksMetricsListApiResponse } from '@fca-app/api/kicks/interfaces/response/kick-api.response';
import { EOrderStatisticsKick } from '@fca-app/dashboard/components/statistics/statistics-kick/statistics-kick-table/enums/order.statistics-kick.enum';
import { IColumnItemStatisticsKickTable } from '@fca-app/dashboard/components/statistics/statistics-kick/statistics-kick-table/interfaces/column-item.statistics-kick-table.interface';
import { IDataItemStatisticsKickTable } from '@fca-app/dashboard/components/statistics/statistics-kick/statistics-kick-table/interfaces/data-item.statistics-kick-table.interface';
import { DataStatisticsKickTableService } from '@fca-app/dashboard/components/statistics/statistics-kick/statistics-kick-table/services/data.statistics-kick-table.service';
import { DescriptionSearchStatisticsKickTableService } from '@fca-app/dashboard/components/statistics/statistics-kick/statistics-kick-table/services/description-search.statistics-kick-table.service';
import { QueriesStatisticsKickTableService } from '@fca-app/dashboard/components/statistics/statistics-kick/statistics-kick-table/services/queries.statistics-kick-table.service';
import { SearchStatisticsKickTableService } from '@fca-app/dashboard/components/statistics/statistics-kick/statistics-kick-table/services/search.statistics-kick-table.service';
import { UserSearchStatisticsKickTableService } from '@fca-app/dashboard/components/statistics/statistics-kick/statistics-kick-table/services/user-search.statistics-kick-table.service';
import { UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'statistics-kick-table',
    templateUrl: './statistics-kick-table.component.html',
    styleUrls: ['./statistics-kick-table.component.scss'],
    providers: [
        QueriesStatisticsKickTableService,
        SearchStatisticsKickTableService,
        UserSearchStatisticsKickTableService,
        DescriptionSearchStatisticsKickTableService,
        DataStatisticsKickTableService,
    ],
})
export class StatisticsKickTableComponent implements AfterContentInit, AfterViewInit {
    public search$ = new BehaviorSubject(undefined);

    @ViewChild('userSearch', { static: true }) userSearch: ElementRef<NzDropdownMenuComponent>;

    listOfData: IDataItemStatisticsKickTable[] = [
        {
            name: 'John Brown',
            age: 32,
            address: 'New York No. 1 Lake Park',
        },
        {
            name: 'Jim Green',
            age: 42,
            address: 'London No. 1 Lake Park',
        },
        {
            name: 'Joe Black',
            age: 32,
            address: 'Sidney No. 1 Lake Park',
        },
        {
            name: 'Jim Red',
            age: 32,
            address: 'London No. 2 Lake Park',
        },
    ];
    listOfDisplayData = [...this.listOfData];
    visible = false;
    searchValue = '';
    loading = true;
    pageSize = 10;
    pageIndex = 1;
    filterGender = [
        { text: 'male', value: 'male' },
        { text: 'female', value: 'female' },
    ];

    public listOfColumns: IColumnItemStatisticsKickTable[] = [];

    onQueryParamsChange(params: NzTableQueryParams): void {
        const { pageSize, pageIndex, sort, filter } = params;
        this.queriesStatisticsKickTableService.changeTablePagination$.next({ limit: pageSize, page: pageIndex });
        const currentSort = sort.find(item => item.value !== null);
        const sortField = (currentSort && currentSort.key) || null;
        const sortOrder = (currentSort && currentSort.value) || null;
    }

    constructor(
        private route: ActivatedRoute,
        public readonly queriesStatisticsKickTableService: QueriesStatisticsKickTableService,
        public readonly userSearchStatisticsKickTableService: UserSearchStatisticsKickTableService,
        public readonly descriptionSearchStatisticsKickTableService: DescriptionSearchStatisticsKickTableService,
        private readonly searchStatisticsKickTableService: SearchStatisticsKickTableService,
        public readonly dataStatisticsKickTableService: DataStatisticsKickTableService
    ) {}

    ngAfterContentInit(): void {
        this.listOfColumns = [
            {
                name: 'Description',
                width: null,
                sortOrderName: EOrderStatisticsKick.DESCRIPTION,
                showSort: true,
                showFilter: false,
                sortOrder: null,
                sortFn: true,
                sortDirections: ['ascend', 'descend', null],
                sortOrderChange: (_, __, ___, ____, _____) => {
                    if (_ === EOrderStatisticsKick.DESCRIPTION && __) {
                        this.pageIndex = 1;
                        this.queriesStatisticsKickTableService.changeOrderParams$.next({
                            type: _,
                            directions: __,
                            table: ___,
                            page: 1,
                            limit: this.pageSize,
                        });
                    }
                },
                filterMultiple: true,
                listOfFilter: [],
                filterFn: null,
                filterTrigger: null,
            },
            {
                name: 'Duration',
                width: null,
                sortOrderName: EOrderStatisticsKick.DURATION,
                showSort: true,
                showFilter: false,
                sortOrder: null,
                sortFn: true,
                sortDirections: ['ascend', 'descend', null],
                sortOrderChange: (_, __, ___, ____, _____) => {
                    if (_ === EOrderStatisticsKick.DURATION && __) {
                        this.pageIndex = 1;
                        this.queriesStatisticsKickTableService.changeOrderParams$.next({
                            type: _,
                            directions: __,
                            table: ___,
                            page: 1,
                            limit: this.pageSize,
                        });
                    }
                },
                filterMultiple: true,
                listOfFilter: [],
                filterFn: null,
                filterTrigger: null,
            },
            {
                name: 'Views',
                width: null,
                sortOrderName: EOrderStatisticsKick.VIEWS,
                showSort: true,
                showFilter: false,
                sortOrder: null,
                sortFn: true,
                sortDirections: ['descend', 'ascend', null],
                sortOrderChange: (_, __, ___, ____, _____) => {
                    if (_ === EOrderStatisticsKick.VIEWS && __) {
                        this.pageIndex = 1;
                        this.queriesStatisticsKickTableService.changeOrderParams$.next({
                            type: _,
                            directions: __,
                            table: ___,
                            page: 1,
                            limit: this.pageSize,
                        });
                    }
                },
                listOfFilter: [],
                filterFn: null,
                filterMultiple: false,
                filterTrigger: null,
            },
            {
                name: 'Viewed',
                width: null,
                sortOrderName: null,
                showSort: false,
                showFilter: false,
                sortOrder: null,
                sortFn: true,
                sortDirections: [],
                sortOrderChange: () => true,
                listOfFilter: [],
                filterFn: null,
                filterMultiple: false,
                filterTrigger: null,
            },
            {
                name: 'Downloaded',
                width: null,
                sortOrderName: null,
                showSort: false,
                showFilter: false,
                sortOrder: null,
                sortFn: true,
                sortDirections: [],
                sortOrderChange: () => true,
                listOfFilter: [],
                filterFn: null,
                filterMultiple: false,
                filterTrigger: null,
            },
            {
                name: 'Unique',
                width: null,
                sortOrderName: EOrderStatisticsKick.UNIQUE,
                showSort: true,
                showFilter: false,
                sortOrder: null,
                sortFn: true,
                sortDirections: ['descend', 'ascend', null],
                sortOrderChange: (_, __, ___, ____, _____) => {
                    if (_ === EOrderStatisticsKick.UNIQUE && __) {
                        this.pageIndex = 1;
                        this.queriesStatisticsKickTableService.changeOrderParams$.next({
                            type: _,
                            directions: __,
                            table: ___,
                            page: 1,
                            limit: this.pageSize,
                        });
                    }
                },
                listOfFilter: [],
                filterFn: null,
                filterMultiple: false,
                filterTrigger: null,
            },
            {
                name: 'Likes',
                width: null,
                sortOrderName: EOrderStatisticsKick.LIKES,
                showSort: true,
                showFilter: false,
                sortOrder: null,
                sortFn: true,
                sortDirections: ['descend', 'ascend', null],
                sortOrderChange: (_, __, ___, ____, _____) => {
                    if (_ === EOrderStatisticsKick.LIKES && __) {
                        this.pageIndex = 1;
                        this.queriesStatisticsKickTableService.changeOrderParams$.next({
                            type: _,
                            directions: __,
                            table: ___,
                            page: 1,
                            limit: this.pageSize,
                        });
                    }
                },
                listOfFilter: [],
                filterFn: null,
                filterMultiple: false,
                filterTrigger: null,
            },
            {
                name: 'Comments',
                width: null,
                sortOrderName: EOrderStatisticsKick.COMMENTS,
                showSort: true,
                showFilter: false,
                sortOrder: null,
                sortFn: true,
                sortDirections: ['descend', 'ascend', null],
                sortOrderChange: (_, __, ___, ____, _____) => {
                    if (_ === EOrderStatisticsKick.COMMENTS && __) {
                        this.pageIndex = 1;
                        this.queriesStatisticsKickTableService.changeOrderParams$.next({
                            type: _,
                            directions: __,
                            table: ___,
                            page: 1,
                            limit: this.pageSize,
                        });
                    }
                },
                listOfFilter: [],
                filterFn: null,
                filterMultiple: false,
                filterTrigger: null,
            },
            {
                name: 'User',
                width: null,
                sortOrderName: EOrderStatisticsKick.USER_ID,
                showSort: true,
                showFilter: false,
                sortOrder: null,
                sortFn: true,
                sortDirections: ['descend', 'ascend', null],
                sortOrderChange: (_, __, ___, ____, _____) => {
                    if (_ === EOrderStatisticsKick.USER_ID && __) {
                        this.pageIndex = 1;
                        this.queriesStatisticsKickTableService.changeOrderParams$.next({
                            type: _,
                            directions: __,
                            table: ___,
                            page: 1,
                            limit: this.pageSize,
                        });
                    }
                },
                listOfFilter: [],
                filterFn: null,
                filterMultiple: false,
                filterTrigger: {
                    isVisible: false,
                    isActive: false,
                    dropDownMenu: this.userSearch,
                },
            },
            {
                name: 'Tags',
                width: null,
                sortOrderName: EOrderStatisticsKick.TAGS,
                showSort: true,
                showFilter: false,
                sortOrder: null,
                sortFn: true,
                sortDirections: ['descend', 'ascend', null],
                sortOrderChange: (_, __, ___, ____, _____) => {
                    if (_ === EOrderStatisticsKick.TAGS && __) {
                        this.pageIndex = 1;
                        this.queriesStatisticsKickTableService.changeOrderParams$.next({
                            type: _,
                            directions: __,
                            table: ___,
                            page: 1,
                            limit: this.pageSize,
                        });
                    }
                },
                listOfFilter: [],
                filterFn: null,
                filterMultiple: false,
                filterTrigger: null,
            },
            {
                name: 'Uploaded',
                width: null,
                sortOrderName: EOrderStatisticsKick.UPLOADED,
                showSort: true,
                showFilter: false,
                sortOrder: null,
                sortFn: true,
                sortDirections: ['descend', 'ascend', null],
                sortOrderChange: (_, __, ___, ____, _____) => {
                    if (_ === EOrderStatisticsKick.UPLOADED && __) {
                        this.pageIndex = 1;
                        this.queriesStatisticsKickTableService.changeOrderParams$.next({
                            type: _,
                            directions: __,
                            table: ___,
                            page: 1,
                            limit: this.pageSize,
                        });
                    }
                },
                listOfFilter: [],
                filterFn: null,
                filterMultiple: false,
                filterTrigger: null,
            },
        ];
    }

    public async ngAfterViewInit(): Promise<void> {
        const params = await this.route.queryParams.toPromise();
    }

    public reset(): void {
        this.searchValue = '';
        this.search();
    }

    public search(): void {
        this.visible = false;
        this.listOfDisplayData = this.listOfData.filter(
            (item: IDataItemStatisticsKickTable) => item.name.indexOf(this.searchValue) !== -1
        );
    }

    public videoLoaded(data: KicksMetricsListApiResponse): void {
        const video = document.getElementById(data.id + '_video');
        const displayed = document.getElementById(data.id + '_displayed');
        const hidden = document.getElementById(data.id + '_hidden');
        displayed ? (displayed!.hidden = true) : undefined;
        hidden ? (hidden!.hidden = true) : undefined;
        video ? (video!.hidden = false) : undefined;
    }

    public videoDontLoaded($event: any, data: KicksMetricsListApiResponse): void {
        const video = document.getElementById(data.id + '_video');
        video ? (video!.hidden = true) : undefined;
    }
}
