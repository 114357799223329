import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CreatePpvPriceData } from '@fca-app/api/fca/fight-events/fca-fight-events-api.service';
import { FightEventModel } from '@fca-app/models/users/arena/fight-event/fight-event.model';
import { FightEventsService } from '@fca-app/services/fight-events.service';
import { untilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { take } from 'rxjs/operators';

@Component({
    selector: 'arena-event-manage-ppv',
    styleUrls: ['./arena-event-manage-ppv.component.scss'],
    templateUrl: './arena-event-manage-ppv.component.html',
})
export class ArenaEventManagePpvComponent implements OnInit {
    @Input() fightEvent: FightEventModel;
    supportedCurrencies = ['USD', 'BRL'];
    selectedCurrency = 'USD';
    ppvValidateForm: FormGroup;
    pricesToSave: CreatePpvPriceData[] = [];
    defaultFormState = {
        price: 50,
        discountPrice: null,
        sale: null,
    };

    constructor(
        private readonly fightEventsService: FightEventsService,
        private readonly nzNotificationService: NzNotificationService
    ) {
        this.ppvValidateForm = new FormGroup({
            price: new FormControl(this.defaultFormState.price, [Validators.required, Validators.min(50)]),
            discountPrice: new FormControl(this.defaultFormState.discountPrice, [Validators.min(50)]),
            sale: new FormControl(this.defaultFormState.sale, [Validators.min(0), Validators.max(100)]),
        });
    }

    get availableCurrencies(): string[] {
        const currencies = (this.fightEvent?.prices || []).map(p => p.currency);
        return this.supportedCurrencies.filter(c => !currencies.some(cc => c === cc));
    }

    get canAddToList(): boolean {
        return !this.pricesToSave.some(p => p.currency === this.selectedCurrency);
    }

    ngOnInit(): void {
        // console.log(this.fightEvent);
    }

    onDeletePreSavePrice(price: CreatePpvPriceData): void {
        this.pricesToSave = this.pricesToSave.filter(p => p.currency !== price.currency);
    }

    onAddToList(): void {
        if (this.ppvValidateForm.valid) {
            this.pricesToSave.push({
                currency: this.selectedCurrency,
                price: this.ppvValidateForm.value.price,
                discountPrice: this.ppvValidateForm.value.discountPrice,
                sale: this.ppvValidateForm.value.sale,
            });
            this.ppvValidateForm.reset(this.defaultFormState, { onlySelf: true, emitEvent: false });
        }
    }

    get canConvertToPpv(): boolean {
        return this.pricesToSave.length > 0;
    }
    onConvertClick(): void {
        if (this.canConvertToPpv) {
            this.fightEventsService
                .updatePpv(this.fightEvent.id, this.pricesToSave)
                .pipe(untilDestroy(this), take(1))
                .subscribe(() => {
                    this.nzNotificationService
                        .success('Success', '', { nzDuration: 1500 })
                        .onClick.pipe(take(1), untilDestroy(this))
                        .subscribe();
                });
        }
    }

    get canAddMorePrices(): boolean {
        return [...new Set(this.pricesToSave.map(p => p.currency))].length !== this.supportedCurrencies.length;
    }
}
