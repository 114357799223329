import { FighterSearchDistance } from '@fca-app/api/fca/users/interfaces/response/fighter/fighter-api.response';
import { FightStyle, Stand } from '@fca-app/api/fca/users/interfaces/response/fighter/fighter-details-api.response';
import { EIsFighterActive } from '@fca-app/enums/fighter-status.enum';
import { UserImageModel } from '@fca-app/models/images/user-image/user-image.model';
import { LocationAddressModel } from '@fca-app/models/location-address/location-address.model';
import { UserAccountInfoModel } from '@fca-app/models/users/user/account-info/user-account-info.model';
import { UserModel, UserModelData } from '@fca-app/models/users/user/user.model';
import { EPenalty } from '@fca-app/shared/enums/penalty.enum';
import * as moment from 'moment-timezone';
import { EFighterExperience } from '../../../enums/fighter-experience.enum';
import { EFighterWeight } from '../../../enums/fighter-weight.enum';
import { EUserGender } from '../../../enums/gender.enum';
import { EUnitSystem } from '../../../enums/unit-system.enum';

export interface FighterModelData extends UserModelData {
    firstName: string;
    lastName: string;
    nickname: string;
    residence?: LocationAddressModel;
    images: UserImageModel[];
    fullFaceAvatar?: UserImageModel;
    halfLengthAvatar?: UserImageModel;
    email: string;
    createDate: Date;
    verified: boolean;
    details: {
        birthdate: Date;
        club?: string;
        coaches?: string;
        draw: number;
        experience: EFighterExperience;
        gender: EUserGender;
        fightStyle: FightStyle;
        handsSize?: number;
        height: number;
        weight: EFighterWeight;
        isActive: boolean;
        loss: number;
        ranking: number;
        searchDistance: FighterSearchDistance;
        sigStrikes: number;
        stand: Stand;
        takedowns: number;
        unitSystem: EUnitSystem;
        win: number;
        winsByKo: number;
        winsBySub: number;
    };
    phone: string;
    penalties: {
        id: number;
        reason: EPenalty;
        date: moment.Moment;
        comment: string;
    }[];
    penaltyInfo?: { banned: boolean; unbanDate: moment.Moment | null };
    profileFillingInfo: {
        hasSignedContractWithOrganization: boolean;
        hasFullAddress: boolean;
        hasPhone: boolean;
        hasBillingInfo: boolean;
    };
    socials: { href: string; name: string }[];
}

export interface FighterModel extends FighterModelData {}

export class FighterModel extends UserModel {
    get fullName(): string {
        return `${this.lastName} ${this.firstName}`;
    }

    get isActiveToString() {
        return this.details.isActive ? EIsFighterActive.YES : EIsFighterActive.NO;
    }
}
