import { Component, Input, OnInit } from '@angular/core';
import { UserImageModel } from '@fca-app/models/images/user-image/user-image.model';
import { MatchmakingRequestModel } from '@fca-app/models/matchmaking/matchmaking-request.model';
import { MatchmakingService } from '@fca-app/services/matchmaking.service';
import { EImageType } from '@fca-app/shared/enums/image-type.enum';
import { untilDestroy, UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { NgStyleInterface } from 'ng-zorro-antd/core/types';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'app-matchmaking-list',
    templateUrl: './list-matchmaking.component.html',
    styleUrls: ['./list-matchmaking.component.scss'],
})
export class ListMatchmakingComponent implements OnInit {
    public readonly bodyStyle: NgStyleInterface = {
        padding: '0',
    };

    @Input() isLoading$: Observable<boolean>;
    @Input() searchResultList$: Observable<MatchmakingRequestModel[]>;
    list: MatchmakingRequestModel[] = [];

    constructor(private readonly matchmakingService: MatchmakingService) {}

    ngOnInit(): void {
        this.searchResultList$.pipe(untilDestroy(this)).subscribe(val => (this.list = val));
    }

    onCancelClick(requestModel: MatchmakingRequestModel): void {
        this.matchmakingService
            .cancel(requestModel.fighterId, requestModel.eventId, requestModel.status)
            .pipe(untilDestroy(this), take(1))
            .subscribe(() => {
                this.list = this.list.filter(v => v.id !== requestModel.id);
            });
    }

    onMatchClick(requestModel: MatchmakingRequestModel): void {
        this.matchmakingService
            .confirmMatch(requestModel.fighterId, requestModel.eventId)
            .pipe(untilDestroy(this), take(1))
            .subscribe(() => {
                this.list = this.list.filter(v => v.id !== requestModel.id);
            });
    }

    age(date: Date): number {
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const dob = new Date(date);
        const dobnow = new Date(today.getFullYear(), dob.getMonth(), dob.getDate());
        let age;
        age = today.getFullYear() - dob.getFullYear();
        if (today < dobnow) {
            age = age - 1;
        }
        return age;
    }

    fighterImage(images: UserImageModel[]): string {
        const filtered = images
            .filter(_ => !_.original)
            .filter(_ => _.imageType === EImageType.FULL_FACE_AVATAR)
            .pop();
        return filtered?.href ? filtered.href : '/assets/images/placeholder-image.png';
    }

    instagramExists(data: any[]): boolean {
        return Boolean(data.filter(_ => _.name === 'Instagram').length);
    }

    twitterExists(data: any[]): boolean {
        return Boolean(data.filter(_ => _.name === 'Twitter').length);
    }

    public facebookExists(data: any[]): boolean {
        return Boolean(data.filter(_ => _.name === 'Facebook').length);
    }

    public sherdogExists(data: any[]): boolean {
        return Boolean(data.filter(_ => _.name === 'Sherdog').length);
    }

    public getInstagramLink(data: any[]): string {
        return (
            'https://instagram.com/' +
            data
                .filter(_ => _.name === 'Instagram')
                .pop()
                .href.replace('@', '')
        );
    }

    public getTwitterLink(data: any[]): string {
        return data.filter(_ => _.name === 'Twitter').pop().href;
    }

    public getFacebookLink(data: any[]): string {
        return data.filter(_ => _.name === 'Facebook').pop().href;
    }

    public getSherdogLink(data: any[]): string {
        return data.filter(_ => _.name === 'Sherdog').pop().href;
    }
}
