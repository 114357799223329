import {
    MatchmakingRequestModel,
    MatchmakingRequestModelData,
} from '@fca-app/models/matchmaking/matchmaking-request.model';
import { ModelFactory } from '@fca-app/shared/abstract/model-factory.abstract';

export class MatchmakingRequestFactory extends ModelFactory<MatchmakingRequestModelData, MatchmakingRequestModel> {
    protected getInstance(): MatchmakingRequestModel {
        return new MatchmakingRequestModel();
    }
}
